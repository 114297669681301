<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1" class="mt-10"></v-col>
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text">MENTIONS LÉGALES</h1>
                <p class="text-font-small mt-10">
                    K'DANSE est une association municipale régie par la loi du 1er juillet 1901.<br><br>

                    Date de création : 04/09/2001<br><br>

                    SIRET : 43966427700019<br>
                    Numéro RNA : W781000300<br><br>

                    SIEGE SOCIAL : Mairie de Maule 78580 Maule<br><br>

                    Le site internet <a href="https://kdanse-maule.fr">www.kdanse-maule.fr</a>, site de l'association,
                    est géré par les membres du bureau de l'association.<br><br>

                    Pour toute suggestion, information et remarque sur le site n’hésitez pas à nous contacter à
                    l’adresse suivante : contact@kdanse-maule.fr<br><br>

                    <span class="bold">Confidentialités et protection des données personnelles :</span><br><br>

                    Ce site n’enregistre aucune information sur les visiteurs.<br><br>

                    Seules les informations saisies par les adhérents lors de la création de leur espace personnel et
                    lors de leurs inscriptions sont collectées. Ces informations sont exclusivement utilisées par
                    K'danse dans le cadre l'organisation des cours et de la vie de l'association et ne sont communiquées
                    à aucun tiers.<br><br>

                    <span class="bold">Hébergement</span><br>
                    Le site <span class="ulined">www.kdanse-maule.fr</span> est hébergé par l’entreprise <a
                        href="https://www.ionos.fr/">https://www.ionos.fr/</a>.<br><br>

                    <span class="bold">Conception et réalisation</span><br>
                    Le site a été réalisé par @Miirraa et @eliseall.<br><br>

                    <span class="bold">Droit de l’image</span><br>
                    L’Association K'danse est propriétaire des droits de propriété intellectuelle ou détient les droits
                    d’usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes,
                    logo, icônes, sons, logiciels.<br><br>

                    Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des
                    éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation
                    écrite préalable de K'danse.<br><br>

                    Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera
                    considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des
                    articles L.235-2 et suivants du Code de Propriété Intellectuelle.<br><br>
                </p>
            </v-col>
            <v-col md="1" class="mt-10"></v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        data() {
            return {}
        },
        methods: {},
    }
</script>

<style>
</style>
