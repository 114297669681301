<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12" class="mt-10 mb-5">
                <h1 class="text-center text-font-h0 mpink-text mb-5">TENUES DES COURS {{saison}}</h1>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
        <v-row>
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12" class="mb-5">
                <!--      tittle-->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title-start bg3 rounded-tl-lg"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                Cours
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title bg3 rounded-tr-lg"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                Tenues
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      initiation -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Initiation à la danse
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Justaucorps rose (+ cache-cœur rose) / legging noir / guêtres roses / pieds nus
                                (chaussettes en hiver)
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      jazz enfant -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Jazz enfant (6 à 8 ans)
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Justaucorps blanc (+ cache-cœur blanc) / legging noir / guêtres noires / pieds nus
                                (chaussettes en hiver)
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      jazz enfant2 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Jazz enfant (9 à 11 ans)
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Tee-shirt de danse / legging noir / guêtres noires / chaussettes noires
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      jazz ados intermédiaire -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Jazz ados Intermédiaire
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Tee-shirt de danse / legging noir / guêtres noires / chaussettes noires
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      jazz ados avancé -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Jazz ados avancé / adultes
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Tee-shirt de danse / bas noir(short/legging ou pantalon jazz) / guêtres noires /
                                chaussettes noires
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      classique adulte -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Danse classique
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Collant rose / justaucorps + jupette assortie (couleur au choix) / demi-pointes saumon
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      maintien classique -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                              Danse classique adulte débutant
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Legging noir / haut près du corps / chaussettes noires
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
        <v-row class="mb-0">
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12">
                <p class="text-center text-font-h3b">Les élèves doivent se présenter coiffées en chignon pour la danse
                    classique et cheveux attachés pour les autres disciplines.</p>
                <p class="text-center text-font-h3">La tenue est à la charge des familles et doit être portée à chaque
                    cours.<br>
                    En cas de non respect de la tenue exigée par le professeur, l'élève se verra dans l'obligation
                    d'assister au cours en tant que spectateur.</p>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SaisonService from "@/Services/SaisonService";

    export default {
        name: "tenues",
        data: () => ({
          saison: '',
        }),
        beforeMount() {
          this.getSaison();
        },
        methods: {
          async getSaison() {
            const saison = await SaisonService.getSaison();
            this.saison = saison.data[0].nom;
          },
        },
    }
</script>

<style scoped>

</style>
