import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from "./router";
import './assets/css/style.css'
import JsonExcel from "vue-json-excel";
import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

Vue.component("downloadExcel", JsonExcel);
Vue.config.productionTip = true;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
