<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">FACTURES À VALIDER</h1>
            <v-card-title>
                <v-text-field
                        v-model="search"
                        label="Recherche"
                        append-icon="mdi-magnify"
                >
                </v-text-field>
            </v-card-title>
            <v-data-table
                    :headers="headers"
                    :items="allFacture"
                    :search="search"
                    :items-per-page="-1"
                    hide-default-footer
            >
                <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.nomFamille }}</td>
                        <td>{{ props.item.nomEleve }}</td>
                        <td>{{ props.item.prenomEleve }}</td>
                        <td><p v-for="cours in props.item.coursInscrit" :key="cours">{{ cours }}</p></td>
                        <td>{{ props.item.totalCotisation }} €</td>
                        <td>{{ props.item.totalCours }} €</td>
                        <td>{{ props.item.totalCostume }} €</td>
                        <td v-if=" props.item.isPayed === 1">Oui</td>
                        <td v-else>Non</td>
                        <td>{{ props.item.commentaires }}</td>
                        <td>{{ props.item.commentairePartage }}</td>
                        <td>
                            <v-btn
                                    @click="editFacture(props.item)"
                                    color="#AD1F61"
                                    text
                            >
                                Modifier
                            </v-btn>
                            <v-btn
                                    @click="validate(props.item.id, props.item.eleve_id)"
                                    color="#AD1F61"
                                    text
                            >
                                Valider
                            </v-btn>
                        </td>
                    </tr>
                </template>

                <template v-slot:top>
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >La facture n'a pas pu être modifiée.
                    </v-alert>

                    <v-dialog
                            v-model="dialogFacture"
                            max-width="50%"
                    >
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Modifier la facture</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedFacture.totalCotisation"
                                                    label="Cotisation"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedFacture.totalCours"
                                                    label="Cours"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedFacture.totalCostume"
                                                    label="Costume"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                class="d-flex"
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-select
                                                    v-model="editedFacture.isPayed"
                                                    :items="isPayed"
                                                    item-text="text"
                                                    item-value="value"
                                                    label="Payée"
                                            ></v-select>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedFacture.commentaires"
                                                    label="Commentaires"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedFacture.commentairePartage"
                                                    label="Commentaire partagé"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="#AD1F61"
                                        text
                                        @click="closeFacture"
                                >
                                    Annuler
                                </v-btn>
                                <v-btn
                                        color="#AD1F61"
                                        text
                                        @click="saveFacture"
                                >
                                    Sauvegarder
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-slot:no-data>
                    <h3>Pas de facture(s) en attente.</h3>
                </template>
            </v-data-table>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import FactureService from "@/Services/factureService";
    import EleveService from "../../../../Services/eleveService";
    import UtilisateurService from "../../../../Services/utilisateurService";
    import MailService from "@/Services/mailService.";

    export default {
        data: () => ({
            currentUser: {},
            search: '',
            allFacture: [],
            dialogFacture: false,
            role: localStorage.getItem("role"),
            alertValue: false,
            editedIndexFacture: -1,
            isPayed: [{
                value: 0,
                text: 'Non'
            },
                {
                    value: 1,
                    text: 'Oui'
                }],
            editedFacture: {
                totalCotisation: 0,
                totalCours: 0,
                totalCostume: 0,
                isPayed: 0,
                commentaires: '',
                commentairePartage: '',
            },
            defaultItemFacture: {
                totalCotisation: 0,
                totalCours: 0,
                totalCostume: 0,
                isPayed: 0,
                commentaires: '',
                commentairePartage: '',
            },
            headers: [
                {
                    text: 'Nom de la famille',
                    align: 'start',
                    value: 'nomFamille',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Nom de l\'élève', value: 'nomEleve', class: "text-font-h2 mpink-text"},
                {text: 'Prénom de l\'élève', value: 'prenomEleve', class: "text-font-h2 mpink-text"},
                {text: 'Cours inscrits', value: 'coursInscrit', class: "text-font-h2 mpink-text"},
                {text: 'Cotisation', value: 'totalCotisation', class: "text-font-h2 mpink-text"},
                {text: 'Cours', value: 'totalCours', class: "text-font-h2 mpink-text"},
                {text: 'Costume', value: 'totalCostume', class: "text-font-h2 mpink-text"},
                {text: 'Payée', value: 'isPayed', class: "text-font-h2 mpink-text"},
                {text: 'Commentaires', value: 'commentaires', class: "text-font-h2 mpink-text"},
                {text: 'Commentaire partagé', value: 'commentairePartage', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false}
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllEleve();
        },
        watch: {
            dialogFacture(val) {
                val || this.closeFacture()
            },
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllEleve() {
              const tmp = await FactureService.getAllNotValidateFactureEnriched(localStorage.getItem("token"));
              for (let i = 0; i < tmp.data.length; i++) {
                tmp.data[i].nomFamille = tmp.data[i].utilisateur_Facture.nom;
                tmp.data[i].nomEleve = tmp.data[i].facture_eleve.nom;
                tmp.data[i].prenomEleve = tmp.data[i].facture_eleve.prenom;
                tmp.data[i].coursInscrit = [];
                for(let cours of tmp.data[i].facture_eleve.inscription_eleve) {
                  tmp.data[i].coursInscrit.push(cours.inscription_cours.nom);
                }
              }
              this.allFacture = tmp.data;
            },
            async validate(id, idEleve) {
                let body = {
                    facture_id: id
                };
                try {
                    await FactureService.validateFacture(body, localStorage.getItem("token"));
                    this.allFacture = [];
                    await this.getAllEleve();
                    let eleve = await EleveService.getEleve(idEleve, localStorage.getItem("token"));
                    let mail = await UtilisateurService.getFamille(eleve.data.utilisateur_id, localStorage.getItem("token"))
                    let body2 = {
                        mail: mail.data.email
                    };
                    await MailService.sendFacturedispo(body2, localStorage.getItem("token"));
                } catch (e) {
                    console.log(e);
                }
            },
            editFacture(item) {
                this.editedIndexFacture = this.allFacture.indexOf(item);
                this.editedFacture = Object.assign({}, item);
                this.dialogFacture = true;
            },
            closeFacture() {
                this.dialogFacture = false;
                this.$nextTick(() => {
                    this.editedFacture = Object.assign({}, this.defaultItemFacture);
                    this.editedIndexFacture = -1;
                })
            },
            async saveFacture() {
                if (this.editedIndexFacture > -1) {

                    try {

                        let body = {
                            id: this.editedFacture.id,
                            updates: {
                                totalCotisation: this.editedFacture.totalCotisation,
                                totalCours: this.editedFacture.totalCours,
                                totalCostume: this.editedFacture.totalCostume,
                                isPayed: this.editedFacture.isPayed,
                                commentaires: this.editedFacture.commentaires,
                                commentairePartage: this.editedFacture.commentairePartage,
                            }
                        };
                        await FactureService.updateFactureOther(body, localStorage.getItem("token"));

                        Object.assign(this.allFacture[this.editedIndexFacture], this.editedFacture);
                        this.alertValue = false;

                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeFacture()
            },
        },
        name: "listattenteFactures"
    }
</script>

<style scoped>

</style>
