<template>
    <v-container class="contain">
        <v-row justify="space-around">
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text my-5">INSCRIPTION</h1>
                <h1 class="text-font-h1 mpink-text my-5">Informations élève</h1>
                <v-card
                        class="justify-space-around rounded-lg"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="formEleve"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.nom"
                                            :rules="nomRules"
                                            label="Nom de famille"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.prenom"
                                            :rules="prenomRules"
                                            label="Prénom"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.telephone"
                                            :rules="telephoneRules"
                                            label="Téléphone"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <div>
                                        <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        v-model="date"
                                                        label="Date de naissance"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                    v-model="date"
                                                    :first-day-of-week="1"
                                                    locale="fr-FR"
                                                    :active-picker.sync="activePicker"
                                                    :max="new Date().toISOString().substr(0, 10)"
                                                    min="1950-01-01"
                                                    @change="save"
                                            ></v-date-picker>
                                        </v-menu>
                                    </div>
                                </v-col>
                                <v-col md="12">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.adresse"
                                            :rules="adresseRules"
                                            label="Adresse (numéro de voie et rue)"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.ville"
                                            :rules="villeRules"
                                            label="Ville"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="formEleve.cp"
                                            :rules="cpRules"
                                            label="Code postal"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="12">
                                    <v-radio-group
                                            v-model="formEleve.droitImage"
                                            label="Autorisez-vous K'danse à publier sur nos supports médiatiques (Facebook et site internet) les photos et vidéos prises dans le cadre de votre activité (ou celle de votre enfant) ?"
                                    >
                                        <v-radio
                                                color="#AD1F61"
                                                label="Oui"
                                                value="1"
                                        ></v-radio>
                                        <v-radio
                                                color="#AD1F61"
                                                label="Non"
                                                value="0"
                                        ></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-right">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="createEleve"
                >
                    Suivant
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            activePicker: null,
            date: null,
            menu: false,
            valid: true,
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
            adresseRules: [
                v => !!v || 'Veuillez saisir l\'adresse',
            ],
            villeRules: [
                v => !!v || 'Veuillez saisir la ville',
            ],
            cpRules: [
                v => !!v || 'Veuillez saisir le code postal',
            ],
            telephoneRules: [
                v => !!v || 'Veuillez saisir le téléphone',
            ],
            dateNaissanceRules: [
                v => !!v || 'Veuillez saisir la date de naissance',
            ],
            formEleve: {
                nom: '',
                prenom: '',
                adresse: '',
                ville: '',
                cp: '',
                telephone: '',
                dateNaissance: '',
                droitImage: '1',
                observations: ' ',
            },
            token: '',
        }),
        watch: {
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
        },
        beforeMount() {
            this.token = localStorage.getItem("token");
        },
        methods: {
            async createEleve() {
                const body = {
                    nom: this.formEleve.nom,
                    prenom: this.formEleve.prenom,
                    adresse: this.formEleve.adresse,
                    ville: this.formEleve.ville,
                    cp: this.formEleve.cp,
                    telephone: this.formEleve.telephone,
                    droitImage: this.formEleve.droitImage,
                    dateNaissance: this.formEleve.dateNaissance,
                    observations: this.formEleve.observations
                };
                const config = {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                };

                let eleveId = await EleveService.createEleve(body, config);
                localStorage.setItem("currentEleveId", eleveId.data.id);
                await this.$router.push('/monespace/Inscription2');
            },
            validate() {
                this.$refs.formEleve.validate();
            },
            save(date) {
                this.formEleve.dateNaissance = date;
            },
        },
        name: "Inscription"
    }
</script>

<style scoped>

</style>
