<template>
    <div>
        <v-btn
                color="#AD1F61"
                dark
                class="mb-n2 mt-5"
                @click="dialogMails = true"
        >
            Récupérer les mails utilisateurs
        </v-btn>
        <v-data-table
                :headers="headers"
                :items="eleves"
                :search="search"
                class="elevation-3"
                :items-per-page="-1"
                hide-default-footer
        >
            <v-spacer></v-spacer>

            <template v-slot:top>
                <v-toolbar
                        flat
                        class="my-10"
                >
                    <v-toolbar-title class="mpink-text text-font-h2">Elèves</v-toolbar-title>

                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>

                    <v-text-field
                            v-model="search"
                            label="Recherche"
                            append-icon="mdi-magnify"
                            class="mt-6"
                    >
                    </v-text-field>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-btn v-if="alleleveforexcel.length > 0"
                           color="#AD1F61"
                           dark
                    >
                        <download-excel
                                :data="alleleveforexcel"
                                :name="nameFile + convertDate(Date.now())"
                                worksheet="Liste des élèves"
                        >
                            Télécharger la liste des élèves (Excel)

                        </download-excel>
                    </v-btn>
                    <v-dialog
                            v-model="dialogMails"
                            max-width="50%"
                    >
                        <v-card>
                            <v-card-title class="text-h5">Listes des mails
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-textarea
                                            class="mt-8"
                                            outlined
                                            name="input-7-4"
                                            :value="tousLesMails"
                                    ></v-textarea>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="#AD1F61"
                                        dark
                                        @click="dialogMails = false"
                                >
                                    Fermer
                                </v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>

                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item="props">
                <tr>
                    <td class="text-uppercase">{{ props.item.nom }}</td>
                    <td class="text-uppercase">{{ props.item.prenom }}</td>
                    <td>{{ convertDate(props.item.dateNaissance) }}</td>
                    <td class="text-uppercase">
                      <div v-for="(p, i) in props.item.observations"
                           :key="i">{{p}}
                      </div>
                    </td>
                    <td class="text-right">
                        <v-btn
                                :to="link + props.item.id"
                                color="#AD1F61"
                                text
                        >
                            Voir plus
                        </v-btn>
<!--                        <v-btn-->
<!--                            color="#AD1F61"-->
<!--                            text-->
<!--                            @click="miseAttenteEleve"-->
<!--                        >-->
<!--                          Mettre en attente-->
<!--                        </v-btn>-->
                    </td>
                </tr>
            </template>
            <template v-slot:no-data>
                <h3>Vous n'avez pas encore d'élèves inscrits pour ce cours.</h3>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import CoursService from "../../../../Services/coursService";
    import UtilisateurService from "../../../../Services/utilisateurService";
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            eleves: [],
            search: '',
            tousLesMails: '',
            dialogMails: false,
            alleleveforexcel: [],
            nameFile: 'export_liste_eleve_',
            link: "/monespace/Admin/infoeleve/",
            headers: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: 'Date de naissance', value: 'dateNaissance', class: "text-font-h2 mpink-text"},
                {text: 'Personnes autorisée', value: 'personnesAutorisee', class: "text-font-h2 mpink-text"},
                {text: '', value: 'action', sortable: false}
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getEleves();
            this.getAllEleveForExcel();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getEleves() {
                const tmp = await CoursService.getEleveCours(this.$route.params.id, localStorage.getItem("token"));

                let tab = [];
                for (let i = 0; i < tmp.data.length; i++) {
                    let pa = await EleveService.getEleveAutorisee(tmp.data[i].inscription_eleve.id, localStorage.getItem("token"))
                    let paTab = [];
                    for (let u = 0; u < pa.data.length; u++){
                      paTab.push(pa.data[u].nom + " " + pa.data[u].prenom);
                    }
                    tmp.data[i].inscription_eleve.observations = paTab;
                    this.eleves.push(tmp.data[i].inscription_eleve);
                    const tmp2 = await UtilisateurService.getFamille(tmp.data[i].inscription_eleve.utilisateur_id, localStorage.getItem("token"));
                    if (!tab.includes(tmp2.data.email)) {
                        tab.push(tmp2.data.email);
                    }

                }
                this.tousLesMails = tab.join(';');

            },
            async getAllEleveForExcel() {
                const tmp = await CoursService.getEleveCours(this.$route.params.id, localStorage.getItem("token"));
                var tab = [];

                for (var i = 0; i < tmp.data.length; i++) {
                    var drIm = '';
                    if (tmp.data[i].inscription_eleve.droitImage == 1) {
                        drIm = 'Oui';
                    } else if (tmp.data[i].inscription_eleve.droitImage == 0) {
                        drIm = 'Non';
                    } else {
                        drIm = '';
                    }
                    tab = {
                        'Nom': tmp.data[i].inscription_eleve.nom,
                        'Prenom': tmp.data[i].inscription_eleve.prenom,
                        'Adresse': tmp.data[i].inscription_eleve.adresse,
                        'Ville': tmp.data[i].inscription_eleve.ville,
                        'Code Postal': tmp.data[i].inscription_eleve.cp,
                        'Telephone': '.' + tmp.data[i].inscription_eleve.telephone,
                        'Date de naissance': this.convertDate(tmp.data[i].inscription_eleve.dateNaissance),
                        'Droit à l\'image': drIm,
                        'Observations': tmp.data[i].inscription_eleve.observations,
                    };
                    this.alleleveforexcel.push(tab);
                }
            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            // miseAttenteEleve(nbAPratique, prat, obs, acc, cert, idEleve, idCour, attId){
            //     const body = {
            //       nbAnneePratique: nbAPratique,
            //       pratique: prat,
            //       observations: obs,
            //       acceptTerme: acc,
            //       certificat: cert,
            //       eleve_id: idEleve,
            //       cours_id: idCour,
            //     };
            //
            //     try {
            //       await InscriptionService.createListeAttente(body, localStorage.getItem("token"));
            //       await InscriptionService.deleteAttenteInscription(attId, localStorage.getItem("token"));
            //     } catch (e) {
            //       console.log(e)
            //     }
            //   },
        },
        name: "infoCoursEleve"
    }
</script>

<style scoped>

</style>
