<template>
    <v-app>
        <v-container class="contain">
            <v-row>
                <v-col md="12" class="my-10">
                    <h1 class="text-center text-font-h0 mpink-text">RÈGLEMENT INTÉRIEUR</h1>
                    <br>
                    <h3 class="text-center text-font-h3">L’association K’DANSE a pour vocation d'accueillir des enfants,
                        adolescents et adultes. Ce règlement est applicable à tous.</h3>
                    <br>
                    <h2 class="text-font-small ulined bold mb-4">Article 1 - MODALITÉS D’INSCRIPTION</h2>
                    <p class="text-font-small">L’inscription devient effective après :</p>
                    <ul>
                        <li class="text-font-small text-justify">La remise de la fiche d’inscription dûment remplie et
                            signée par le responsable légal ou par l’élève majeur.
                        </li>
                        <li class="text-font-small text-justify">Le paiement de la cotisation annuelle : due pour chaque
                            famille inscrite, elle comprend les frais liés au fonctionnement et l’assurance. Payable par
                            un chèque distinct du paiement des cours à l’ordre de K’DANSE, elle est encaissée dès le
                            premier cours. Elle est non remboursable.
                        </li>
                        <li class="text-font-small text-justify">Le paiement des cours de danse par virement bancaire ou
                            par chèque(s) à l’ordre de K’DANSE en 1, 2 ou 3 fois.
                        </li>
                        <li class="text-font-small text-justify">Tout trimestre commencé est dû. Seules des raisons
                            majeures (déménagement ou maladie avec certificat médical à l’appui fourni sous 10 jours)
                            donneront lieu au remboursement du(des) trimestre(s) non effectué(s). Dans le cas d’un
                            paiement en partie ou en totalité par les CE ou autres organismes, le montant leur sera
                            restitué directement par K’DANSE. En cas d’annulation de cours de plus de 15 jours
                            (catastrophe naturelle) et si l’association ne peut pas proposer leur report celle-ci
                            s'engage à les rembourser.<br>
                            Pour les bacheliers ou les étudiants dont le planning scolaire serait finalement
                            incompatible avec leurs cours, l'association s'engage à rembourser leur inscription. Pour
                            cela, l'élève devra en informer l'association dès la prise de connaissance de son emploi du
                            temps, début novembre au plus tard.
                        </li>
                    </ul>
                    <ul>
                        <li class="text-font-small text-justify">La participation financière pour la(les) tenue(s) : les
                            années de spectacle : 12 € par costume/cours d’1h ou 24 €/cours d’1h30 (non cousu), payable
                            par chèque à l’ordre de K’DANSE. Le remboursement des costumes ne sera possible que sur
                            raisons majeures (voir plus haut) et avant le dépôt des chèques pour l’achat du tissu et des
                            accessoires.
                        </li>
                        <li class="text-font-small text-justify">L’attestation sur l’honneur – Certificat de non
                            contre-indication à la pratique de la danse modern jazz, danse classique et/ou claquettes.
                            OBLIGATOIRE dès le premier cours. Dans le cas contraire, l’élève se verra refuser l’accès au
                            cours.
                        </li>
                        <li class="text-font-small text-justify">L'acceptation du présent règlement intérieur qui doit
                            être daté et signé par le responsable légal ou l’élève majeur et le danseur.
                        </li>
                    </ul>
                    <br>
                    <h2 class="text-font-small ulined bold mb-4">Article 2 - CALENDRIER DES COURS</h2>
                    <p class="text-font-small text-justify">Les cours suivent le calendrier scolaire. L’association
                        n’étant pas locataire des locaux qu’elle utilise, elle dégage sa responsabilité en cas de
                        fermeture des salles ordonnée par le prêteur.</p>
                    <h2 class="text-font-small ulined bold mb-4">Article 3 - CONSTITUTION DES GROUPES</h2>
                    <p class="text-font-small text-justify">L’élève sera dirigé(e) par le professeur vers le cours
                        correspondant à son niveau et/ou son âge. Le professeur se réserve la possibilité d’effectuer
                        des changements si le niveau ne correspond pas aux capacités de l’élève.</p>
                    <h2 class="text-font-small ulined bold mb-4">Article 4 - DÉROULEMENT DES COURS</h2>
                    <ul>
                        <li class="text-font-small text-justify"><span class="bold ulined">Horaires</span> : Arrêtés en
                            fonction de la disponibilité des salles, K’DANSE se réserve le droit de procéder à tout
                            changement au cours de l’année.
                        </li>
                        <li class="text-font-small text-justify"><span class="bold ulined">Tenues exigées</span> :
                            Chaque élève se devra d'avoir une tenue correcte et adaptée à la danse. La tenue est à la
                            charge des familles et doit être portée à chaque cours. Merci de vous référer à l'annexe
                            concernant la tenue spécifique de chaque groupe/discipline. Les élèves doivent se présenter
                            coiffées en chignon pour la danse classique et cheveux attachés pour les autres disciplines.
                            En cas de non-respect de la tenue exigée par le professeur, l'élève se verra dans
                            l'obligation d'assister au cours en tant que spectateur.
                        </li>
                        <li class="text-font-small text-justify"><span class="bold ulined">Nombre d’élèves</span> : une
                            moyenne de 12 élèves minimum par cours est indispensable pour la bonne marche de
                            l’association. Cette dernière se réserve le droit de supprimer ou déplacer un cours avec un
                            nombre d’élèves insuffisant. <span
                                    class="bold">Le maximum d'élèves par cours est de 15.</span></li>
                    </ul>
                    <br>
                    <h2 class="text-font-small ulined bold mb-4">Article 5 - SÉCURITÉ</h2>
                    <p class="text-font-small text-justify">L’association décline toute responsabilité en cas de perte
                        ou de vol dans les vestiaires. Les cours se déroulent sans public.<br>
                        Pour les élèves mineurs, <span class="bold">il est demandé aux parents de vérifier la présence du professeur, d’accompagner leur enfant dans les vestiaires et de venir le rechercher à la fin du cours</span>
                        au même endroit en respectant les horaires. La responsabilité du professeur et de l’association
                        se limite à l’intérieur de la salle de danse. En cas d’accident durant l’heure de cours, le
                        professeur prendra les décisions qui lui semblent nécessaires et préviendra immédiatement la
                        personne à contacter en cas d’urgence.<br>
                        <span class="bold">En cas d’absence de l’élève au cours, prévenir impérativement le professeur au : 06.07.95.81.13 ou K’DANSE : <a>kdanse.maule@gmail.com</a></span>
                    </p>
                    <h2 class="text-font-small ulined bold mb-4">Article 6 - DISCIPLINE</h2>
                    <p class="text-font-small text-justify">Il est demandé aux élèves de respecter les horaires, d’avoir
                        un bon esprit de groupe, un respect mutuel et une présence régulière, contrôlée à chaque cours
                        par le professeur. <span class="bold"> Au bout de 3 absences non justifiées, l’association contactera les parents ou l’élève majeur.</span><br>
                        Le professeur est seul juge quant au déroulement de son cours. Il lui appartient de sanctionner
                        tout manquement à ses directives et d’en informer le bureau de l’association, qui agira, le cas
                        échéant, dans le cadre de ses statuts.</p>
                    <h2 class="text-font-small ulined bold mb-4">Article 7 - MANIFESTATION & SPECTACLE</h2>
                    <p class="text-font-small text-justify">Les élèves participant à une manifestation organisée par
                        l’association s’engagent à suivre les indications données pour les costumes, à être présents aux
                        répétitions, à assurer les 4 spectacles. Les places gratuites pour le spectacle seront offertes
                        aux familles uniquement si le danseur participe au spectacle.</p>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    export default {
        name: "reglementinterieur"
    }
</script>

<style scoped>

</style>
