<template>
    <v-container class="contain">
        <v-row>
            <v-col md="12" sm="12" class="my-10 align-self-center">
                <h1 class="text-center text-font-h0 mpink-text my-5">LA PROFESSEURE</h1>
            </v-col>
            <v-col md="4" sm="12">
                <v-img
                        :src="img"
                        class="rounded-lg ml-md-n10"
                >
                </v-img>
            </v-col>
            <v-col md="8" sm="12" class="align-self-center">

                <p class="text-justify text-font-h3 alinea">Ludivine Brousse, professeur diplômée d’état, enseigne la
                    danse avec passion depuis 2001 dans association DRJM et USM et depuis 2013 dans l’association
                    K’danse.</p>

                <h1 class="text-font-h1 mpink-text my-5">Son parcours :</h1>
                <p class="text-justify text-font-h3 alinea italic">“ Passionnée par la danse depuis toute petite, j’ai
                    commencé la danse en prenant des cours d’expression corporelle puis de danse classique dans un
                    conservatoire. Je suis entrée dans une école de danse vers 12 ans où j’ai suivi des cours de danse
                    classique, de danse Jazz et de Claquettes.
                    À 14-15 ans, j’ai commencé à me présenter dans des concours de danse en solo puis en groupe. Je suis
                    entrée dans un lycée à horaires aménagés sur Paris, ce qui m’a permis de m’investir davantage dans
                    la danse.
                    J’ai également été initiée à la danse contemporaine en faisant partie de L’Atelier de l’Envol,
                    dirigée par Claudie Jacquelin, puis la Compagnie Sisson’jazz, dirigée par Veronique Montoya. J’y ai
                    travaillé avec quelques chorégraphes tels que Bernard Estrabaut, Stéphane Ripon, Serge Ricci,
                    Thierry Malandain…
                    En parallèle, j’ai suivi mes formations pour obtenir le Diplôme d’Etat de Professeur de danse, en
                    danse classique à l’école Danse Beaux Arts et en danse Jazz à l'Institut de Formation Rick odums.
                    Depuis, j'enseigne et transmets ma passion à mes élèves. Je chorégraphie et mets en scène les
                    spectacles mais avec les élèves, nous leur donnons vie.”</p>

                <h1 class="text-font-h1 mpink-text my-5">Sa méthode :</h1>
                <p class="text-justify text-font-h3 alinea italic">“ Ma façon de travailler pourrait se résumer ainsi
                    :</p>
                <ul>
                    <li class="text-justify text-font-h3 italic">Communication : un point fondamental pour apprendre à
                        se connaître.
                    </li>
                    <li class="text-justify text-font-h3 italic">Investissement : j’attends de mes élèves qu’ils
                        s’investissent et prennent autant de plaisir à danser que j’en prends à leur enseigner la danse.
                    </li>
                    <li class="text-justify text-font-h3 italic">Imagination : je suis adepte des bruitages,
                        onomatopées, anecdotes et des images pour aider les élèves à progresser.
                    </li>
                    <li class="text-justify text-font-h3 italic">Bonne humeur : je ne suis pas la dernière à faire le
                        clown tant que nous travaillons. On rigole, on danse, on avance !
                    </li>
                    <li class="text-justify text-font-h3 italic">Persévérance : on ne devient pas danseur du jour au
                        lendemain ! Il faut travailler et avoir de la volonté. Se donner la peine d'y arriver, peut-être
                        pas aujourd'hui ni demain, mais en travaillant on finit toujours par obtenir des résultats.
                    </li>
                    <li class="text-justify text-font-h3 italic">Lâcher-prise : s’abandonner pour ressentir le mouvement
                        et se libérer. Se regarder dans le miroir est essentiel pour se corriger mais une fois les
                        mouvements intégrés, il faut les ressentir.
                    </li>
                </ul>
                <br>
                <p class="text-justify text-font-h3 alinea italic">En général, beaucoup d’élèves veulent très vite
                    passer aux chorégraphies et faire des choses difficiles mais oublient le travail nécessaire en aval
                    pour danser sur scène, notre objectif de fin d’année pour tous.
                    J’accorde beaucoup d’importance aux exercices pour préparer et échauffer le corps et acquérir la
                    propreté de mouvements, l’équilibre, la force, la souplesse… Tous ces éléments sont nécessaires pour
                    pouvoir évoluer dans des chorégraphies de plus en plus élaborées aussi bien techniquement qu’au
                    niveau de l’interprétation.
                    La beauté d’une chorégraphie est mise en valeur par l’interprétation des danseurs, la technique et
                    une réalisation propre des mouvements.
                    Souvent, les élèves me disent : “Oui, mais quand tu le fais, c’est joli…” Je leur rappelle que je ne
                    compte plus le nombre d’heures de cours que j’ai pris, combien d’exercices j’ai faits et refaits.”
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "professeur.vue",
        data: () => ({
            img: require("../../assets/img/professeur.jpg"),
        })
    }
</script>

<style scoped>

</style>
