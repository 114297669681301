import { render, staticRenderFns } from "./AdhFooter.vue?vue&type=template&id=973c6874&"
import script from "./AdhFooter.vue?vue&type=script&lang=js&"
export * from "./AdhFooter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VCard,VCardText,VFooter})
