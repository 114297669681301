<template>
    <v-container class="contain">
        <v-row>
            <v-col md="6" class="align-self-center">
                <v-img
                        :src="img2"
                        class="ma-10 rounded-lg"
                >
                </v-img>
            </v-col>
            <v-col md="6" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text my-5" id="initiation">Initiation à la danse</h1>
                <p class="text-justify text-font-h3 alinea">Dès 4 ans, l'association propose à vos tout-petits des cours
                    d’une heure par semaine afin d'apprendre à connaître leur corps et le faire évoluer dans l'espace
                    pour découvrir les bases fondamentales de la danse. A l'aide d'accessoires (rubans, cerceaux, ...),
                    ils apprendront à canaliser leur énergie et à reproduire des mouvements et petites
                    chorégraphies.</p>
                <p class="text-justify text-font-h3 alinea">En début d’année, les cours commencent par un travail de
                    ronde afin de rassurer les enfants et de communiquer avec eux. Ensuite, une place est attribuée à
                    chacun dans la salle. Les exercices se font sur des comptines, traditionnelles ou remixées, ou des
                    musiques actuelles que les enfants connaissent. Cela permet aux élèves d'assimiler un pas à une
                    parole et un rythme connu et d’apprendre les pas et les positions de danse par imitation ou par
                    image donnée. Les cours se composent également d’un moment d’expression libre dans la salle. Au
                    cours de l’année, ils apprennent à appréhender et utiliser l’espace (ligne face au miroir,
                    diagonale, déplacement…).En fin d’année, les élèves sont capables de faire un enchaînement de pas
                    donnés et en musique.</p>
                <p class="text-center text-font-h3 italic">Motricité, écoute musicale, mimétisme</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text my-5" id="modern">Danse modern Jazz</h1>
                <p class="text-justify text-font-h3 alinea">La danse Modern’jazz est une danse technique, rythmée et
                    dynamique qui allie les bases de la danse classique et les codages des danses afro-américaines.
                    C’est une danse près du sol qui demande une mobilité de toutes les parties du corps, une liberté de
                    mouvement et de déplacement. Sur des musiques actuelles et entraînantes, vous apprendrez des
                    chorégraphies à la fois techniques et artistiques pour travailler l'émotion corporelle.</p>
                <p class="text-justify text-font-h3 alinea">De nombreux cours de Modern’jazz vous sont proposés tout au
                    long de la semaine et s’adressent aux enfants à partir de 6 ans jusqu’aux adultes. Ils sont répartis
                    par niveau et durent 1h pour les enfants et 1h30 à partir du collège. Ils se composent de différents
                    exercices d’échauffements, d’étirements, d’un travail de diagonales pour finir sur l’apprentissage
                    d’une chorégraphie. À partir d’un certain niveau, les cours intègrent également des exercices de
                    renforcement musculaire, des étirements plus poussés et un travail de pirouettes, de sauts ou de
                    diagonales pour améliorer sa technique.</p>
                <p class="text-center text-font-h3 italic">Rythme, énergie, expression, mouvement</p>
            </v-col>
            <v-col md="6" class="align-self-center">
                <v-img
                        class="ma-10 rounded-lg"
                        :src="img3">
                </v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" class="align-self-center">
                <v-img
                        class="ma-10 rounded-lg"
                        :src="img4"
                >
                </v-img>
            </v-col>
            <v-col md="6" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text my-5" id="classique">Danse classique</h1>
                <p class="text-justify text-font-h3 alinea">La danse classique est une discipline technique qui demande
                    aux élèves rigueur, calme et précision. C’est une danse aérienne qui demande une très bonne tenue de
                    corps. Lors des cours, vous apprendrez les pas de bases de cette danse et vous travaillerez des
                    enchaînements de mouvements précis sur des musiques classiques.</p>
                <p class="text-justify text-font-h3 alinea">Les cours de danse classique sont proposés aux élèves à
                    partir de 6 ans, de l’initiation à un niveau inter/avancé. Les cours durent 1h ou 1h30 en fonction
                    du niveau. Ils se composent d’un travail à la barre, en enchaînant les exercices progressifs à la
                    préparation du corps, des exercices d’étirements puis un travail au milieu pour des exercices divers
                    et spécifiques à la danse classique selon le niveau.</p>
                <p class="text-justify text-font-h3 alinea">La pratique des pointes ne se fera que sous la validation du
                    professeur, et cela après seulement plusieurs années de pratique pour consolider le maintien des
                    chevilles.</p>
                <p class="text-center text-font-h3 italic">Concentration, précision, calme et écoute.</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text my-5" id="maintien">Cours de danse classique adulte débutant</h1>
                <p class="text-justify text-font-h3 alinea">Sur des musiques classiques et douces, ce cours vous
                    permettra de découvrir les bases de la danse classique, de redécouvrir son corps, de travailler sa
                    musculature profonde et ses étirements.</p>
                <p class="text-justify text-font-h3 alinea">Ce cours est destiné aux adultes et jeunes adultes et dure
                    1h30. Il se compose d’un travail à la barre avec des exercices basiques et simples afin d’analyser le
                    mouvement, ressentir ce qu’il se passe dans le corps et comment fonctionnent les articulations pour
                    avoir les bonnes positions et aller au bout du geste. Attention, les places pour ce cours sont
                    limitées.</p>
                <p class="text-center text-font-h3 italic">Posture, respect de son corps, écoute de soi.</p>
            </v-col>
            <v-col md="6" class="align-self-center">
                <v-img
                        :src="img1"
                        class="ma-10 rounded-lg"
                >
                </v-img>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        name: "cours",
        data: () => ({
            img1: require("../assets/img/maintien.jpg"),
            img2: require("../assets/img/eveil.jpg"),
            img3: require("../assets/img/modernjazz.jpg"),
            img4: require("../assets/img/temp.jpg"),
        })
    }
</script>

<style scoped>

</style>
