import jsPDF from "jspdf";
<template>
    <div v-if="eleveInscrit.length > 0">
        <v-data-table
                :headers="headersCours"
                :items="eleveInscrit"
                class="elevation-3"
                :items-per-page="-1"
                hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                        class="my-10"
                >
                    <v-toolbar-title class="mpink-text text-font-h2">Cours au(x)quel(s) l'élève est inscrit
                    </v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-btn
                            color="#AD1F61"
                            dark
                            class="mb-2"
                            @click="prepareDownload()"
                    >
                        <v-icon>mdi-download</v-icon>
                        Télécharger le certificat
                    </v-btn>
                </v-toolbar>
            </template>

            <template v-slot:item="props">
                <tr>
                    <td>{{ props.item.inscription_cours.nom }}</td>
                    <td>{{ props.item.inscription_cours.jour }}</td>
                    <td>{{ props.item.inscription_cours.horaire }}</td>
                    <td>{{ props.item.nbAnneePratique }}</td>
                    <td>{{ props.item.pratique }}</td>
                    <td>{{ props.item.observations }}</td>
                    <td v-if="props.item.acceptTerme == 1"> Oui</td>
                    <td v-else-if="props.item.acceptTerme == 0"> Non</td>
                    <td v-else></td>
                </tr>
            </template>

            <template v-slot:no-data>
                <h3>L'élève n'est inscrit à aucun cours</h3>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import EleveService from "../../../../Services/eleveService";
    import jsPDF from 'jspdf'

    export default {
        data: () => ({
            currentUser: {},
            eleveInscrit: [],
            img: require("../../../../assets/img/logo-K-Danse-HD.jpg"),
            headersCours: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Jour', value: 'jour', class: "text-font-h2 mpink-text"},
                {text: 'Horaire', value: 'horaire', class: "text-font-h2 mpink-text"},
                {
                    text: 'Nombre(s) d\'année(s) de pratique(s)',
                    value: 'nbAnneePratique',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Pratique', value: 'pratique', class: "text-font-h2 mpink-text"},
                {text: 'Observations', value: 'observations', class: "text-font-h2 mpink-text"},
                {text: 'Acceptation des termes', value: 'acceptTerme', class: "text-font-h2 mpink-text"},
            ],

        }),
        beforeMount() {
            this.getCurrentUser();
            this.getCoursInscrit();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getCoursInscrit() {

                const tmp = await EleveService.getEleveInscritById(this.$route.params.id, localStorage.getItem("token"));
                this.eleveInscrit = tmp.data;
            },
            getAge(date) {
                let d = new Date(date);
                var diff_ms = Date.now() - d.getTime();
                var age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            },
            convertDate(date) {

                let d = new Date(date);
                return d.toLocaleDateString()
            },
            parseDate(date) {

                if (!date) return null;
                const [month, day, year] = date.split('/');
                return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
            },
            async prepareDownload() {
                const age = this.getAge(this.parseDate(this.convertDate(this.eleveInscrit[0].inscription_eleve.dateNaissance)));

                if (age >= 18) {
                    this.downloadPlus18();
                } else if (age < 18) {
                    let responsable = [];
                    try {
                        const tmp = await EleveService.getEleveResponsable(this.$route.params.id, localStorage.getItem("token"));

                        responsable = tmp.data[0];
                    } catch (e) {
                        console.log(e);
                    }

                    this.downloadMoins18(responsable);
                } else {
                    console.log("error");
                }
            },
            downloadPlus18() {
                let img = new Image();

                img.src = this.img;
                let pdfName = 'Certificat';
                let unit = 8;
                let date = this.convertDate(this.eleveInscrit[0].inscription_cours.createdAt);
                let doc = new jsPDF();
                doc.setFont("helvetica");
                doc.setFontSize(13);
                let compt = 17;
                doc.addImage(img, 'jpeg', 0, 12, 55, 45);
                doc.setFont("helvetica", "bold");
                doc.text("__________________________________________________________", 125, compt, {align: "center"});
                compt += 13;
                doc.text("ATTESTATION SUR L'HONNEUR", 125, compt, {align: "center"});
                compt += 7;
                doc.text("Certificat de non contre-indication à la pratique d’une activité de", 125, compt, {align: "center"});
                compt += 7;
                doc.text("Danse modern jazz, danse classique, maintien classique", 125, compt, {align: "center"});
                compt += unit;
                doc.text("__________________________________________________________", 125, compt, {align: "center"});
                compt += 25;

                doc.setFont("helvetica", "normal");
                doc.text("Je, soussigné(e), " + this.eleveInscrit[0].inscription_eleve.nom + " " + this.eleveInscrit[0].inscription_eleve.prenom, 15, compt);
                compt += unit + 1;
                doc.text("demeurant au " + this.eleveInscrit[0].inscription_eleve.adresse + ", " + this.eleveInscrit[0].inscription_eleve.cp + ", " + this.eleveInscrit[0].inscription_eleve.ville + ".", 15, compt);
                compt += unit + 1;
                doc.text("certifie, à ce jour, ne pas vouloir fournir de certificat médical de son médecin traitant et", 15, compt);
                compt += unit;
                doc.text("atteste sur l’honneur que ne pas avoir de contre-indication et être apte à pratiquer", 15, compt);
                compt += unit;
                doc.text("l’activité de la Danse.", 15, compt);
                compt += unit + 3;
                doc.text("Je m’engage à informer l’association K’DANSE et le professeur de danse Ludivine", 15, compt);
                compt += unit;
                doc.text("BROUSSE de tout changement concernant mon état de santé.", 15, compt);
                compt += unit + 4;
                doc.text("Par la présente, je décharge le professeur de danse Ludivine BROUSSE  et les", 15, compt);
                compt += unit;
                doc.text("membres du bureau K’DANSE de toute responsabilité en cas d’incident qui", 15, compt);
                compt += unit;
                doc.text("résulterait d’une incapacité à pratiquer l’activité indiquée ci-dessus.", 15, compt);
                compt += unit + 3;
                doc.text("En cas d’urgence, j’autorise l’association à contacter les pompiers ou les services", 15, compt);
                compt += unit;
                doc.text("d’urgence pendant les cours.", 15, compt);
                compt += unit + 20;
                doc.text("---------------------------------------------------", 15, compt);
                compt += unit;
                doc.text("Signé éléctroniquement le " + date, 15, compt);
                compt += unit;
                doc.text("---------------------------------------------------", 15, compt);

                doc.save(pdfName + '.pdf');
            },
            downloadMoins18(responsable) {
                let img = new Image();

                img.src = this.img;
                let pdfName = 'Certificat';
                let unit = 8;
                let date = this.convertDate(this.eleveInscrit[0].inscription_cours.createdAt);
                let doc = new jsPDF();
                doc.setFont("helvetica");
                doc.setFontSize(13);
                let compt = 17;
                doc.addImage(img, 'jpeg', 0, 12, 55, 45);
                doc.setFont("helvetica", "bold");
                doc.text("__________________________________________________________", 125, compt, {align: "center"});
                compt += 13;
                doc.text("ATTESTATION SUR L'HONNEUR", 125, compt, {align: "center"});
                compt += 7;
                doc.text("Certificat de non contre-indication à la pratique d’une activité de", 125, compt, {align: "center"});
                compt += 7;
                doc.text("Danse modern jazz, danse classique, maintien classique", 125, compt, {align: "center"});
                compt += unit;
                doc.text("__________________________________________________________", 125, compt, {align: "center"});
                compt += 25;

                doc.setFont("helvetica", "normal");
                doc.text("Je, soussigné(e), " + responsable.nom + " " + responsable.prenom, 15, compt, {align: "justify"});
                compt += unit + 1;
                doc.text("demeurant au " + responsable.adresse + ", " + responsable.cp + ", " + responsable.ville, 15, compt, {align: "justify"});
                compt += unit + 1;
                doc.text("responsable du mineur : " + this.eleveInscrit[0].inscription_eleve.nom + " " + this.eleveInscrit[0].inscription_eleve.prenom, 15, compt);
                compt += unit + 1;
                doc.text("certifie, à ce jour, ne pas vouloir fournir de certificat médical de son médecin traitant et", 15, compt);
                compt += unit;
                doc.text("atteste sur l’honneur que le mineur n’a pas de contre-indication et qu’il est apte à", 15, compt);
                compt += unit;
                doc.text("pratiquer l’activité de la Danse.", 15, compt);
                compt += unit + 3;
                doc.text("Je m’engage à informer l’association K’DANSE et le professeur de danse Ludivine", 15, compt);
                compt += unit;
                doc.text("BROUSSE de tout changement concernant mon état de santé.", 15, compt);
                compt += unit + 4;
                doc.text("Par la présente, je décharge le professeur de danse Ludivine BROUSSE et les", 15, compt);
                compt += unit;
                doc.text("membres du bureau K’DANSE de toute responsabilité en cas d’incident qui", 15, compt);
                compt += unit;
                doc.text("résulterait d’une incapacité à pratiquer l’activité indiquée ci-dessus.", 15, compt);
                compt += unit + 3;
                doc.text("En cas d’urgence, j’autorise l’association à contacter les pompiers ou les services", 15, compt);
                compt += unit;
                doc.text("d’urgence pendant les cours.", 15, compt);
                compt += unit + 20;
                doc.text("---------------------------------------------------", 15, compt);
                compt += unit;
                doc.text("Signé éléctroniquement le " + date, 15, compt);
                compt += unit;
                doc.text("---------------------------------------------------", 15, compt);

                doc.save(pdfName + '.pdf');
            },
        },
        name: "infoEleveCoursInscrit"
    }
</script>

<style scoped>

</style>
