<template>
    <v-container class="contain">
        <v-row class="mt-10">
            <v-col md="12">
                <v-card-text>
                    <h1 class="text-center text-font-h0 mpink-text my-5">{{titre}}</h1>
                </v-card-text>
            </v-col>
            <v-col md="12">
                <v-card-text>
                    <h2 class="text-center text-font-h2 mpink-text">{{sousTitre}}</h2>
                </v-card-text>
            </v-col>
            <v-col md="12">
                <div class="ql-editor" v-html="contenu"></div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ArticleService from "@/Services/articleService";

    export default {
        data: () => ({
            titre: '',
            sousTitre: '',
            enavant: 0,
            contenu: "",
        }),
        beforeMount() {
            this.getArticle();
        },
        methods: {
            async getArticle() {
                try {
                    const tmp = await ArticleService.getArticleEnAvant(this.$route.params.id);
                    this.titre = tmp.data.titre;
                    this.sousTitre = tmp.data.sousTitre;
                    this.enavant = tmp.data.enavant;
                    this.contenu = tmp.data.contenu;
                } catch (e) {
                    this.titre = "Cet article n'existe pas."
                }
            },
        },
        name: "unarticle"
    }
</script>

<style scoped>

</style>
