<template>
    <v-container class="contain">
        <v-row>
            <v-col md="3" sm="1"></v-col>
            <v-col md="6" sm="10" class="mt-10">
                <v-card-text>
                    <h1 class="text-center text-font-h0 mpink-text mb-10">SE CONNECTER</h1>
                </v-card-text>
                <v-card
                        class="mx-auto rounded-lg"
                        elevation="5"
                >
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >Email ou mot de passe incorrect.
                    </v-alert>
                    <v-card-text class="text-center">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.email"
                                        :rules="emailRules"
                                        label="Votre e-mail"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.mdp"
                                        :rules="mdpRules"
                                        :type="'password'"
                                        label="Votre Mot de passe"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-btn
                                        :disabled="!valid"
                                        class="Bar-color-invert text-sb rounded-lg"
                                        @click="authentication"
                                >
                                    Se connecter
                                </v-btn>
                            </v-col>
                        </v-form>
                      <h3 class="text-font-h4 text-center my-3">
                        Mot de passe oublié ?
                        <router-link to="/forgivenpwd" class="mpink-text"> Réinitialiser mon mot de passe </router-link>
                      </h3>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3" sm="1"></v-col>
        </v-row>
        <v-row class="justify-center">
            <v-col md="4" sm="1"></v-col>
            <v-col md="4" class="mt-10" sm="10">
                <v-card-text>
                    <h3 class="text-font-h3 text-center">
                        Pas encore de compte ?
                        <router-link to="/register" class="mpink-text">Créer mon compte</router-link>
                    </h3>
                </v-card-text>
            </v-col>
            <v-col md="4" sm="1"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AuthService from "@/Services/authService";

    export default {
        data: () => ({
            valid: true,
            alertValue: false,
            emailRules: [
                v => !!v || 'Votre e-mail ne doit pas etre vide',
                v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
            ],
            mdpRules: [
                v => !!v || 'Veuillez saisir votre mot de passe',
            ],
            form: {
                email: '',
                mdp: '',
            },
            token: ""

        }),
        methods: {
            async authentication() {
                const body = {
                    email: this.form.email,
                    mdp: this.form.mdp
                };
                try {
                    const user = await AuthService.authentication(body);
                    if (user !== false) {
                        this.alertValue = false;
                        if (localStorage.getItem("role") === "utilisateur") {
                            let routeData = this.$router.resolve('/monespace');
                            window.open(routeData.href, '_blank');
                        } else if (localStorage.getItem("role") === "prof" || localStorage.getItem("role") === "admin" || localStorage.getItem("role") === "super") {
                            let routeData = this.$router.resolve('/monespace/Admin');
                            window.open(routeData.href, '_blank');
                        }
                    } else {
                        this.alertValue = true;
                    }

                } catch (err) {
                    alert('Adresse mail ou mot de passe invalide');
                }
            },
            validate() {
                this.$refs.form.validate()
            },
        },
        name: "Login"
    }
</script>

<style scoped>

</style>
