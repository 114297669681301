import ApiService from "@/Services/apiService";

export default class ArticleService {

    static async createArticle(body, token) {
        await ApiService.apiCall("post", "article/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateArticle(body, token) {
        await ApiService.apiCall("put", "article/update", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllArticle(token) {
        return await ApiService.apiCall("get", "article/getAll", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllArticleEnAvant() {
        return await ApiService.apiCall("get", "article/getallenavant");
    }

    static async getArticle(id, token) {
        return await ApiService.apiCall("get", "article/getone/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getArticleEnAvant(id) {
        return await ApiService.apiCall("get", "article/getoneenavant/" + id);
    }

    static async deleteArticle(id, token) {
        return await ApiService.apiCall("delete", "article/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});

    }
}
