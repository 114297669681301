import axios from "axios";

export default class ApiService {
    static async apiCall(Type, url, body = {}, config = {}) {
        const baseUrl = "https://api.kdanse-maule.fr/";
        if(Type === 'post'){
            return await axios.post(baseUrl + url, body, config);
        }
        else if(Type === 'get'){
            return await axios.get(baseUrl + url, config);
        }
        else if(Type === 'put'){
            return await axios.put(baseUrl + url, body, config);
        }
        else if(Type === 'delete'){
            return await axios.delete(baseUrl + url, config);
        }
    }
}
