<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text mb-5">TARIFS {{saison}}</h1>
                <h3 class="text-center text-font-h1">Cours de modern jazz, classique, maintien classique</h3>
                <h4 class="text-center text-font-h3">pour les enfants à partir de 4 ans et les adultes</h4>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
        <v-row>
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12" class="mb-5">
                <!--      tittle-->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title-start bg3 rounded-tl-lg"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                NIVEAUX
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title bg3"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                1 COURS
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title bg3"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                2 COURS
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-title bg3 rounded-tr-lg"
                        >
                            <v-card-title
                                    class="white--text text-font-h3 justify-center text-center"
                            >
                                3 COURS
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      l1 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Maternelle / Primaire
                            </v-card-title>
                            <v-card-text
                                    class="text-center text-font-small mpink-text">
                                1H de cours
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                150 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                50 € + 50 € + 50 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                280 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                95 € + 95 € + 90 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                405 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small ">
                                135 € + 135 € + 135 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      l2 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Collège / Lycée
                            </v-card-title>
                            <v-card-text
                                    class="text-center text-font-small mpink-text">
                                1H30 de cours
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                220 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                80 € + 70 € + 70 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                420 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                140 € + 140 € + 140 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                600 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                200 € + 200 € + 200 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      l3 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Adultes
                            </v-card-title>
                            <v-card-text
                                    class="text-center text-font-small mpink-text">
                                1H de cours <br>(1h30 pour les 2ème et 3ème cours)
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                220 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                80 € + 70 € + 70 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                450 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                150 € + 150 € + 150 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                645 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small pb-0">
                                ou en 3X :
                            </v-card-subtitle>
                            <v-card-text
                                    class="text-center text-font-small">
                                215 € + 215 € + 215 €
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      l4 -->
                <v-row>
                  <v-col md="3" class="pa-0">
                    <v-card
                        outlined
                        tile
                        elevation="2"
                        class="border-start"
                    >
                      <v-card-title
                          class="justify-center text-font-h2 text-center"
                      >
                        Adultes
                      </v-card-title>
                      <v-card-text
                          class="text-center text-font-small mpink-text">
                        1H30 de cours
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="3" class="pa-0">
                    <v-card
                        outlined
                        tile
                        elevation="2"
                        class="border"
                    >
                      <v-card-title
                          class="justify-center text-font-h2 text-center"
                      >
                        250 €
                      </v-card-title>
                      <v-card-subtitle
                          class="text-center text-font-small pb-0">
                        ou en 3X :
                      </v-card-subtitle>
                      <v-card-text
                          class="text-center text-font-small">
                        90 € + 80 € + 80 €
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="3" class="pa-0">
                    <v-card
                        outlined
                        tile
                        elevation="2"
                        class="border"
                    >
                      <v-card-title
                          class="justify-center text-font-h2 text-center"
                      >
                        480 €
                      </v-card-title>
                      <v-card-subtitle
                          class="text-center text-font-small pb-0">
                        ou en 3X :
                      </v-card-subtitle>
                      <v-card-text
                          class="text-center text-font-small">
                        160 € + 160 € + 160 €
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col md="3" class="pa-0">
                    <v-card
                        outlined
                        tile
                        elevation="2"
                        class="border"
                    >
                      <v-card-title
                          class="justify-center text-font-h2 text-center"
                      >
                        675 €
                      </v-card-title>
                      <v-card-subtitle
                          class="text-center text-font-small pb-0">
                        ou en 3X :
                      </v-card-subtitle>
                      <v-card-text
                          class="text-center text-font-small">
                        225 € + 225 € + 225 €
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <!--      l5 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Cotisation Annuelle
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small">
                                (par famille)
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Maulois : 15 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small mblack-color">
                                Extra-muros : 20 €
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <!--      l6 -->
                <v-row>
                    <v-col md="3" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border-start rounded-bl-lg"
                        >
                            <v-card-title
                                    class="justify-center text-font-h2 text-center"
                            >
                                Participation constume(s)
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center text-font-small">
                                (uniquement les années à spectacle)
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                    <v-col md="9" class="pa-0">
                        <v-card
                                outlined
                                tile
                                elevation="2"
                                class="border rounded-br-lg"
                        >
                            <v-card-title
                                    class="justify-center text-font-small text-center"
                            >
                                Cours 1h : 25 €
                            </v-card-title>
                            <v-card-subtitle
                                    class="text-center mblack-color text-font-small">
                                Cours 1h30 : 50 € (sauf classique adulte débutant)
                            </v-card-subtitle>
                        </v-card>
                    </v-col>
                </v-row>
                <v-col md="1" sm="1" class="pa-0"></v-col>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
        <v-row>
            <v-col md="1" sm="0"></v-col>
            <v-col md="10" sm="12">
                <p class="text-center text-font-h3b">Cette année les costumes seront fournis déjà confectionnés.</p>
                <p class="text-center text-font-h3">Les tarifs des cours sont établis pour un élève. Les élèves du cours classique adulte débutant ne participent pas au
                    spectacle.</p>
                <p class="text-center text-font-h3b">Réduction annuelle à partir du 2<sup>ème</sup> d'une même
                    famille (même adresse) : 20 € (une seule réduction par famille)</p>
                <p class="text-center text-font-h3 mb-0"> Paiement par chèque ou par virement bancaire.</p>
            </v-col>
            <v-col md="1" sm="0"></v-col>
        </v-row>
    </v-container>
</template>

<script>
import SaisonService from "@/Services/SaisonService";

export default {
    name: "tarif",
    data: () => ({
        saison: '',
    }),
    beforeMount() {
        this.getSaison();
    },
    methods: {
        async getSaison() {
            const saison = await SaisonService.getSaison();
            this.saison = saison.data[0].nom;
        },
    },
}
</script>

<style scoped>

</style>
