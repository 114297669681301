import ApiService from "@/Services/apiService";

export default class MailService {
    static async sendPreInscription(body, token) {
        return await ApiService.apiCall("post", "mail/preinscription/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async sendValidinscription(body, token) {
        return await ApiService.apiCall("post", "mail/validinscription/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async sendAttenteinscription(body, token) {
        return await ApiService.apiCall("post", "mail/attenteinscription/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async sendFacturedispo(body, token) {
        return await ApiService.apiCall("post", "mail/facturedispo/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async sendEmailContact(body) {
        return await ApiService.apiCall("post", "mail/mailcontact/", body, {});
    }

}
