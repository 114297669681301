<template>
  <v-container class="contain">
    <v-row>
      <v-col md="3" sm="1"></v-col>
      <v-col md="6" sm="10" class="mt-10">
        <v-card-text>
          <h1 class="text-center text-font-h0 mpink-text mb-10">Réinitialiser mon mot de passe</h1>
        </v-card-text>
        <v-card
            class="mx-auto rounded-lg"
            elevation="5"
        >
          <v-alert
              :value="alertValue"
              dense
              text
              type="success"
          >
            <v-row align="center">
              <v-col class="grow">
                Votre mot de passe a bien été réinitialisé, merci de consulter votre boite mail.
              </v-col>
            </v-row>
          </v-alert>
          <v-card-text class="text-center">
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-col md="12">
                <v-text-field
                    v-model="form.email"
                    :rules="emailRules"
                    label="Votre e-mail"
                    required
                    outlined
                ></v-text-field>
              </v-col>
              <v-col md="12">
                <v-btn
                    :disabled="!valid"
                    class="Bar-color-invert text-sb rounded-lg"
                    @click="resetPwd"
                >
                  Réinitialiser mon mot de passe
                </v-btn>
              </v-col>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" sm="1"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import UtilisateurService from "@/Services/utilisateurService";

export default {
  data: () => ({
    valid: true,
    alertValue: false,
    emailRules: [
      v => !!v || 'Votre e-mail ne doit pas etre vide',
      v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
    ],
    mdpRules: [
      v => !!v || 'Veuillez saisir votre mot de passe',
    ],
    form: {
      email: '',
    },
    token: ""

  }),
  methods: {
    async resetPwd() {
      const body = {
        email: this.form.email,
      };

      try {
        await UtilisateurService.resetPassword(body);
      } catch (err) {
        alert('Adresse mail ou mot de passe invalide');
      }
      this.alertValue = true;
      setTimeout(this.redirect, 3000);

    },
    validate() {
      this.$refs.form.validate()
    },
    redirect(){
      this.$router.push('/login')
    }
  },
  name: "forgivenpwd.vue"
}
</script>

<style scoped>

</style>
