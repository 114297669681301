<template>
    <v-container class="contain">
        <v-row justify="space-around">
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text my-5">INSCRIPTION</h1>
                <h1 class="text-font-h1 mpink-text my-5">Personne autorisée à venir chercher l'élève</h1>
                <h4 class="pa-5"><span class="italic">À remplir pour les élèves mineurs uniquement.</span></h4>
                <v-card
                        v-for="index in nb" :key="index"
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="nom[index]"
                                            :rules="nomRules"
                                            label="Nom de famille"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="prenom[index]"
                                            :rules="prenomRules"
                                            label="Prénom"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-left">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addPersonne"
                >
                    Ajouter une personne supplémentaire
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-right">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addPersonneAutorisee"
                >
                    Suivant
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            valid: true,
            nb: 1,
            nomRules: [
                v => !!v || 'Le nom ne doit pas etre vide',
            ],
            prenomRules: [
                v => !!v || 'Le prenom ne doit pas etre vide',
            ],
            nom: [],
            prenom: [],
            token: [],
        }),
        beforeMount() {
            this.token = localStorage.getItem("token");
        },
        methods: {
            async addPersonneAutorisee() {
                let i = 1;
                if (this.nom.length > 0) {
                    for (i = 1; i <= this.nb; i++) {
                        const body = {
                            nom: this.nom[i],
                            prenom: this.prenom[i],
                            eleve_id: localStorage.getItem("currentEleveId"),
                        };

                        await EleveService.addPersonneAutorisee(body, this.token);
                    }
                }
                await this.$router.push('/monespace/Inscription5');
            },
            validate() {
                this.$refs.form.validate()
            },
            addPersonne() {
                this.nb += 1;
            }
        },
        name: "Inscription4"
    }
</script>

<style scoped>

</style>
