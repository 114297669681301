<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">LISTE DES ÉLÈVES EN ATTENTE D'INSCRIPTION</h1>

            <v-simple-table class="mt-8">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left text-font-h2 mpink-text">
                            Cours
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Nom
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Prénom
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Date de naissance
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Années de danse
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Expériences
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Observations
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Règlement intérieur
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Non contre indication
                        </th>
                        <th class="text-left text-font-h2 mpink-text">
                            Date de l'inscription
                        </th>
                        <th></th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                            v-for="item in alleleve"
                            :key="item.id"
                    >
                        <td>{{ item.attenteinscription_cours.nom }} {{ item.attenteinscription_cours.jour }}
                            {{ item.attenteinscription_cours.horaire }}
                        </td>
                        <td>{{ item.attenteinscription_eleve.nom }}</td>
                        <td>{{ item.attenteinscription_eleve.prenom }}</td>
                        <td>{{ convertDate(item.attenteinscription_eleve.dateNaissance)}}</td>
                        <td>{{ item.nbAnneePratique }}</td>
                        <td>{{ item.pratique }}</td>
                        <td>{{ item.observations }}</td>
                        <td>{{ reglement(item.acceptTerme) }}</td>
                        <td>{{ certificat(item.certificat) }}</td>
                        <td> {{ convertDate(item.createdAt) }}</td>
                        <td>
                            <v-btn
                                    :to="link + item.attenteinscription_eleve.id"
                                    color="#AD1F61"
                                    text
                            >
                                Infos
                            </v-btn>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    v-on:click="inscrireEleveListeAttente(item.nbAnneePratique, item.pratique, item.observations, item.acceptTerme, item.certificat, item.eleve_id, item.cours_id, item.id)"
                            >
                                Liste d'attente
                            </v-btn>
                        </td>
                        <td>
                            <v-dialog
                                    v-model="dialog"
                                    persistent
                                    max-width="600px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            v-bind="attrs"
                                            v-on="on"
                                            @click="dataPopupInscription(item.id)"
                                    >
                                        Modifier
                                    </v-btn>
                                </template>
                                <v-card>
                                    <v-alert
                                            type="error"
                                            :value="alertValue"
                                    >Le cours n'a pas pu être modifié.
                                    </v-alert>
                                    <v-card-title>
                                        <span class="text-h5">Modifier le cours</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <v-select
                                                    :items="cours"
                                                    :item-text="item =>`${item.nom} ${item.jour} ${item.horaire}`"
                                                    v-model="selectedCours"
                                                    item-value="id"
                                                    label="Cours :"
                                                    outlined
                                            ></v-select>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                                color="#AD1F61"
                                                text
                                                @click="dialog = false"
                                        >
                                            Fermer
                                        </v-btn>
                                        <v-btn
                                                color="#AD1F61"
                                                text
                                                @click="updateInscription(selectedCours)"
                                        >
                                            Sauvegarder
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>

                            <v-btn
                                    color="#AD1F61"
                                    text
                                    v-on:click="inscrireEleve(item.nbAnneePratique, item.pratique, item.observations, item.acceptTerme, item.certificat, item.eleve_id, item.cours_id, item.id)"
                            >
                                Valider
                            </v-btn>
                        </td>
                        <td>
                          <v-btn
                              color="#AD1F61"
                              text
                              v-on:click="deleteAttenteInscription(item.id)"
                          >
                            <v-icon
                                alt="user"
                                size="30"
                            >
                              mdi-trash-can
                            </v-icon>
                          </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
                <template v-slot:no-data>
                    <h3>Pas d'élève en attente d'inscription</h3>
                </template>
            </v-simple-table>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import EleveService from "@/Services/eleveService";
    import InscriptionService from "@/Services/inscriptionService";
    import CoursService from "@/Services/coursService";
    import FactureService from "@/Services/factureService";
    import MailService from "@/Services/mailService.";
    import UtilisateurService from "@/Services/utilisateurService";

    export default {
        data: () => ({
            dialog: false,
            link: "/monespace/Admin/infoeleve/",
            currentUser: {},
            alleleve: {},
            cours: [],
            selectedCours: [],
            role: localStorage.getItem("role"),
            insId: "",
            coursId: "",
            alertValue: false,
        }),
        beforeMount() {
            this.token = localStorage.getItem("token");
            this.getCurrentUser();
            this.getAllEleve();
            this.getCours();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllEleve() {
                const tmp = await EleveService.getEleveAttente(localStorage.getItem("token"));
                this.alleleve = tmp.data;
            },
            async deleteAttenteInscription(attId) {
              try {
                await InscriptionService.deleteAttenteInscription(attId, localStorage.getItem("token"));
                await this.getAllEleve();
              } catch (e) {
                console.log(e)
              }
            },
            async inscrireEleve(nbAPratique, prat, obs, acc, cert, idEleve, idCour, attId) {
                const body = {
                    nbAnneePratique: nbAPratique,
                    pratique: prat,
                    observations: obs,
                    acceptTerme: acc,
                    certificat: cert,
                    eleve_id: idEleve,
                    cours_id: idCour,
                };

                try {
                    await InscriptionService.createInscription(body, localStorage.getItem("token"));
                    await InscriptionService.deleteAttenteInscription(attId, localStorage.getItem("token"));
                    await this.getAllEleve();
                    let eleve = await EleveService.getEleve(idEleve, this.token);
                    let cours = await CoursService.getCours(idCour, this.token);
                    let mail = await UtilisateurService.getFamille(eleve.data.utilisateur_id, this.token);
                    let body2 = {
                        nom: eleve.data.nom,
                        prenom: eleve.data.prenom,
                        cours: cours.data.nom,
                        mail: mail.data.email
                    };
                    await MailService.sendValidinscription(body2, this.token);
                } catch (e) {
                    console.log(e)
                }
                await this.Facturation(idEleve);

            },
            async inscrireEleveListeAttente(nbAPratique, prat, obs, acc, cert, idEleve, idCour, attId) {
                const body = {
                    nbAnneePratique: nbAPratique,
                    pratique: prat,
                    observations: obs,
                    acceptTerme: acc,
                    certificat: cert,
                    eleve_id: idEleve,
                    cours_id: idCour,
                };

                try {
                    await InscriptionService.createListeAttente(body, localStorage.getItem("token"));
                    await InscriptionService.deleteAttenteInscription(attId, localStorage.getItem("token"));
                    await this.getAllEleve();
                    let eleve = await EleveService.getEleve(idEleve, this.token);
                    let cours = await CoursService.getCours(idCour, this.token);
                    let mail = await UtilisateurService.getFamille(eleve.data.utilisateur_id, this.token)
                    let body2 = {
                        cours: cours.data.nom,
                        mail: mail.data.email
                    };
                    await MailService.sendAttenteinscription(body2, this.token);
                } catch (e) {
                    console.log(e)
                }

            },
            async Facturation(idEleve) {
                let haveFacture = await FactureService.getAllFactureByIdEleve(idEleve, localStorage.getItem("token"));
                let user = await EleveService.getEleve(idEleve, localStorage.getItem("token"));
                if (haveFacture.data.length === 0) {
                    const body2 = {
                        commentaires: "",
                        eleve_id: idEleve,
                        utilisateur_id: user.data.utilisateur_id
                    };
                    try {
                        await FactureService.createFacture(body2, localStorage.getItem("token"))
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    const body2 = {
                        facture_id: haveFacture.data[0].id,
                        commentaires: "",
                        eleve_id: idEleve,
                        utilisateur_id: user.data.utilisateur_id
                    };
                    try {
                        await FactureService.updateFacture(body2, localStorage.getItem("token"))
                    } catch (e) {
                        console.log(e)
                    }
                }
            },
            async updateInscription(id) {
                const body = {
                    id: this.insId,
                    updates: {
                        cours_id: id,
                    }
                };

                try {
                    await InscriptionService.updateAttenteInscription(body, localStorage.getItem("token"));
                    this.$router.go(0);
                } catch (e) {
                    this.alertValue = true;
                }
            },
            dataPopupInscription(id) {
                this.insId = id;
            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            async getCours() {
                const c = await CoursService.getAllCours(this.token);
                this.cours = c.data;
            },
            certificat(val) {
                if (val === 1) {
                    return "Validé"
                } else {
                    return "Non validé"
                }
            },
            reglement(val) {
                if (val === 1) {
                    return "Signé"
                } else {
                    return "Non signé"
                }
            },
        },
        name: "listEleveAttente"
    }
</script>

<style scoped>

</style>
