import ApiService from "@/Services/apiService";

export default class CoursService {

    static async getAllCours(token) {
        return await ApiService.apiCall("get", "cours/getall", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllProfesseurs(token) {
        return await ApiService.apiCall("get", "professeur/getall", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllSaisons(token) {
        return await ApiService.apiCall("get", "saison/getall", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getProfesseurCours(id, token) {
        return await ApiService.apiCall("get", "cours/getprof/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveCours(id, token) {
        return await ApiService.apiCall("get", "cours/geteleve/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getCours(id, token) {
        return await ApiService.apiCall("get", "cours/getone/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllInscriptionsGroupByCours(token) {
        return await ApiService.apiCall("get", "cours/getallinscription/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateCours(body, token) {
        return await ApiService.apiCall("put", "cours/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async addCours(body, token) {
        return await ApiService.apiCall("post", "cours/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateProf(body, token) {
        return await ApiService.apiCall("put", "professeur/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateSaison(body, token) {
        return await ApiService.apiCall("put", "saison/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteCours(id, token) {
        return await ApiService.apiCall("delete", "cours/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }
}
