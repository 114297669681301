<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1" sm="0" class="my-10 align-self-center"></v-col>
            <v-col md="10" sm="12" class="my-10 align-self-center">
                <h1 class="text-center text-font-h0 mpink-text my-5">L’ASSOCIATION</h1>
                <p class="text-justify text-font-h3 alinea">Créée en 2013 suite à la fusion des associations DRJM et
                    USM, l’association K’danse propose des cours de danse Modern’jazz et classique pour les enfants à
                    partir de 4 ans et les adultes. Les cours suivent le calendrier scolaire et se déroulent dans la
                    salle de danse du gymnase Saint-Vincent à Maule.</p>
                <p class="text-justify text-font-h3 alinea">Tous les deux ans, les élèves travaillent tout au long de
                    l’année pour préparer un spectacle qu’ils présenteront sur la scène de la salle des fêtes au cours
                    de 4 représentations, qui ont lieu généralement au mois de juin. </p>
                <p class="text-justify text-font-h3 alinea">L’association propose également, de façon ponctuelle, des
                    stages de danse thématiques lors des vacances scolaires.</p>
                <p class="text-justify text-font-h3 alinea">La gestion de l’association est faite par un bureau et un
                    conseil d’administration composé de bénévoles investis qui se réunissent régulièrement pour
                    organiser au mieux les cours et les évènements proposés par l’association.</p>
                <p class="text-justify text-font-h3 alinea">Si vous aussi vous souhaitez vous investir dans
                    l’association en tant que bénévole, que ce soit pour rejoindre le conseil d’administration ou bien
                    de façon ponctuelle pour les spectacles, n’hésitez pas à nous envoyer un message via le formulaire
                    de contact.</p>
            </v-col>
            <v-col md="1" sm="0" class="my-10 align-self-center"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "quisommesnous.vue"
    }
</script>

<style scoped>

</style>
