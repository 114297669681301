<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">STATISTIQUES</h1>
            <v-row class="justify-space-around">
                <v-col cols="4">
                    <v-card width="470" class="my-5">
                        <v-card-title class="black--text mt-8">
                            <p class="ma-lg-auto text-font-h2 mpink-text">
                                Nombre de familles :  <span class="grey--text">{{nbFamilles}}</span>
                            </p>
                        </v-card-title>
                        <v-card-title class="black--text ">
                            <p class="ma-lg-auto text-font-h2 mpink-text">
                                Nombre d'élèves :  <span class="grey--text">{{nbEleves}}</span>
                            </p>
                        </v-card-title>
                        <v-card-title class="black--text ">
                            <p class="ma-lg-auto text-font-h2 mpink-text">
                                Nombre d'inscriptions :  <span class="grey--text">{{nbInscriptions}}</span>
                            </p>
                        </v-card-title>
                        <v-card-title class="black--text ">
                            <p class="ma-lg-auto text-font-h2 mpink-text">
                                Moyenne d'élèves par cours :
                              <span class="grey--text">{{Math.round((nbInscriptions/nbCours) * 10) / 10}}</span>
                            </p>
                        </v-card-title>
                      <v-card-title class="black--text ">
                        <p class="ma-lg-auto text-font-h2 mpink-text">
                          Nombre d'élèves en multicours :
                          <span class="grey--text"> {{ nbEleveInscrit[1].value + nbEleveInscrit[2].value }}</span>
                        </p>
                      </v-card-title>
                      <v-card-title class="black--text ">
                        <p class="ma-lg-auto text-font-h2 mpink-text">
                          Nombre d'élèves en liste d'attente :
                          <span class="grey--text"> {{ statsEleve[1].value }}</span>
                        </p>
                      </v-card-title>
                    </v-card>
                </v-col>
              <v-col cols="8">
                <v-card class="my-5">
                  <v-card-title class="black--text mt-8">
                    <p class="ma-lg-auto text-font-h1 mpink-text">
                      Nombre d'élèves par cours
                    </p>
                  </v-card-title>
                  <D3BarChart v-if="nbInscriptionsParCours.length > 1"
                              :config="hor_chart_config"
                              :datum="nbInscriptionsParCours"
                  ></D3BarChart>
                </v-card>
              </v-col>
                <v-col cols="4">
                    <v-card width="460" class="my-5">
                        <v-card-title class="black--text mt-8">
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Répartition par villes
                            </p>
                        </v-card-title>
                        <D3BarChart v-if="nbEleveParVille.length > 1"
                                    :config="ver_chart_config"
                                    :datum="nbEleveParVille"
                        ></D3BarChart>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card width="460" class="my-5">
                        <v-card-title class="black--text mt-8">
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                              Répartition par tranche d'ages
                            </p>
                        </v-card-title>
                        <D3BarChart v-if="nbEleveParTrancheAge.length > 1"
                                    :config="ver_chart_config"
                                    :datum="nbEleveParTrancheAge"
                        ></D3BarChart>
                    </v-card>
                </v-col>
                <v-col cols="4">
                    <v-card width="460" class="my-5">
                        <v-card-title class="black--text mt-8">
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Multicours
                            </p>
                        </v-card-title>
                        <D3BarChart v-if="nbEleveInscrit.length > 1"
                                    :config="ver_chart_config"
                                    :datum="nbEleveInscrit"
                        ></D3BarChart>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>

    import {D3BarChart} from 'vue-d3-charts';
    import EleveService from "../../../../Services/eleveService";
    import CoursService from "../../../../Services/coursService";

    export default {
        components: {
            D3BarChart,
        },
        data: () => ({
            role: localStorage.getItem("role"),
            statsEleve: [],
            nbEleveParVille: [],
            nbInscriptionsParCours: [],
            nbEleveInscrit: [],
            nbEleveParTrancheAge: [],
            hor_chart_config: {
                key: 'label',
                values: ['value'],
                color: {
                    key: false,
                    keys: false,
                    scheme: "schemePastel1",
                    default: '#AAA',
                    axis: '#000',
                    current: '#AD1F61'
                },
                orientation: "horizontal",
                tooltip: {label: "displayName"},
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 280,
                },
            },
            ver_chart_config: {
                key: 'label',
                values: ['value'],
                orientation: "vertical",
                color: {
                    key: false,
                    keys: false,
                    scheme: "schemePastel1",
                    default: '#AAA',
                    axis: '#000',
                    current: '#AD1F61'
                },
                tooltip: {label: "displayName"},
                margin: {
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                },
            },
            nbFamilles: 0,
            nbEleves: 0,
            nbInscriptions: 0,
            nbCours: 0,
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllEleve();
            this.getAllEleveInscrit();
            this.getAllEleveAttenteInscription();
            this.getAllEleveListeAttente();
            this.getAllFamilles();
            this.getAllInscriptions();
            this.getAllInscriptionsGroupByCours();
            this.getAllEleveParTrancheAgeEtParVille();
            this.getInscriptionGroupByEleve();
            this.getCours();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllEleve() {
              const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
              let tab = [];
              for (let i = 0; i < tmp.data.length; i++) {

                if (!tab.includes(tmp.data[i].eleve_id)) {
                  tab.push(tmp.data[i].eleve_id);
                }
              }
              this.nbEleves = tab.length;
            },
            async getAllEleveInscrit() {
                const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
                let tab = [];
                for (let i = 0; i < tmp.data.length; i++) {

                    if (!tab.includes(tmp.data[i].eleve_id)) {
                        tab.push(tmp.data[i].eleve_id);
                    }
                }
                this.statsEleve.push({label: 'Inscrits à un cours', value: tab.length});
            },
            async getAllEleveAttenteInscription() {
                const tmp = await EleveService.getEleveAttente(localStorage.getItem("token"));
                let tab = [];
                for (let i = 0; i < tmp.data.length; i++) {

                    if (!tab.includes(tmp.data[i].eleve_id)) {
                        tab.push(tmp.data[i].eleve_id);
                    }
                }
                this.statsEleve.push({label: 'En attente d\'inscription', value: tab.length});
            },
            async getAllEleveListeAttente() {
                const tmp = await EleveService.getEleveListeAttente(localStorage.getItem("token"));
                let tab = [];
                for (let i = 0; i < tmp.data.length; i++) {

                    if (!tab.includes(tmp.data[i].eleve_id)) {
                        tab.push(tmp.data[i].eleve_id);
                    }
                }
                this.statsEleve.push({label: 'Sur liste d\'attente', value: tab.length},);
            },
            async getAllFamilles() {
              const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
              let tab = [];
              for (let i = 0; i < tmp.data.length; i++) {
                if (!tab.includes(tmp.data[i].inscription_eleve.utilisateur_id)) {
                  tab.push(tmp.data[i].inscription_eleve.utilisateur_id);
                }
              }
              this.nbFamilles = tab.length;
            },
            async getAllInscriptions() {
                const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
                this.nbInscriptions = tmp.data.length;
            },
            async getAllInscriptionsGroupByCours() {
                const tmp = await CoursService.getAllInscriptionsGroupByCours(localStorage.getItem("token"));
                let tab = [];
                for (let i = 0; i < tmp.data.count.length; i++) {
                    tab.push({
                        label: tmp.data.rows[i].inscription_cours.jour + " - " + tmp.data.rows[i].inscription_cours.nom,
                        value: tmp.data.count[i].count
                    });
                }

                this.nbInscriptionsParCours = tab;
            },
            async getAllEleveParTrancheAgeEtParVille() {
                const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
                this.nbEleveParTrancheAge.push({label: 'Moins de 10 ans', value: 0});
                this.nbEleveParTrancheAge.push({label: 'Entre 11 et 19 ans inclus', value: 0});
                this.nbEleveParTrancheAge.push({label: 'Plus de 20 ans', value: 0});

                let tabEleve = [],
                    tabVille = [];

                tabVille.push({label: 'Maule', value: 0});
                tabVille.push({label: "Autres villes", value: 0});

                for (let i = 0; i < tmp.data.length; i++) {
                  if (!tabEleve.includes(tmp.data[i].inscription_eleve.id)) {
                    tabEleve.push(tmp.data[i].inscription_eleve.id);

                    if (tmp.data[i].inscription_eleve.ville === 'Maule' || tmp.data[i].inscription_eleve.ville === 'maule' || tmp.data[i].inscription_eleve.ville === '78580') {
                      tabVille[0].value += 1;
                    } else {
                      tabVille[1].value += 1;
                    }

                    let age = this.getAge(this.parseDate(this.convertDate(tmp.data[i].inscription_eleve.dateNaissance)));
                    if (age < 11) {
                      this.nbEleveParTrancheAge[0].value += 1;
                    } else if (age >= 11 && age < 20) {
                      this.nbEleveParTrancheAge[1].value += 1;
                    } else if (age >= 20) {
                      this.nbEleveParTrancheAge[2].value += 1;
                    }
                  }
                }
                this.nbEleveParVille = tabVille;
            },
            getAge(date) {
                let d = new Date(date);
                let diff_ms = Date.now() - d.getTime();
                let age_dt = new Date(diff_ms);

                return Math.abs(age_dt.getUTCFullYear() - 1970);
            },
            convertDate(date) {

                let d = new Date(date);
                return d.toLocaleDateString()
            },
            parseDate(date) {

                if (!date) return null;
                const [month, day, year] = date.split('/');
                return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
            },
            async getInscriptionGroupByEleve() {
                const tmp = await EleveService.getInscriptionGroupByEleve(localStorage.getItem("token"));

                this.nbEleveInscrit.push({label: '1 seul cours', value: 0});
                this.nbEleveInscrit.push({label: '2 cours', value: 0});
                this.nbEleveInscrit.push({label: '3 cours et plus', value: 0});

                for (let i = 0; i < tmp.data.count.length; i++) {

                    if (tmp.data.count[i].count === 1) {
                        this.nbEleveInscrit[0].value += 1;
                    } else if (tmp.data.count[i].count === 2) {
                        this.nbEleveInscrit[1].value += 1;
                    } else if (tmp.data.count[i].count >= 3) {
                        this.nbEleveInscrit[2].value += 1;
                    }

                }
            },
            async getCours(){
              const tmp = await CoursService.getAllCours(localStorage.getItem("token"));
              this.nbCours = tmp.data.length
            },
        },
        name: "dashboard"
    }
</script>

<style scoped>

</style>
