<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <v-data-table
                    :headers="headersCours"
                    :items="cours"
                    class="elevation-3"
                    :items-per-page="-1"
                    hide-default-footer
            >
                <template v-slot:top>
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >Le cours n'a pas pu être modifié.
                    </v-alert>
                    <v-toolbar
                            flat
                            class="my-10"
                    >
                        <v-toolbar-title class="mpink-text text-font-h2">{{ titleCours }}</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialogCours"
                                max-width="50%"
                        >
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Informations</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.nom"
                                                        label="Nom"
                                                        :rules="nomRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.jour"
                                                        label="Jour"
                                                        :rules="jourRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.horaire"
                                                        label="Horaire"
                                                        :rules="horaireRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.nbplace"
                                                        label="Nombre de places"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif1"
                                                        label="Tarif 1"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif2"
                                                        label="Tarif 2"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif3"
                                                        label="Tarif 3"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarifSpectacle"
                                                        label="Tarif spectacle"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="closeCours"
                                    >
                                        Annuler
                                    </v-btn>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="saveCours"
                                    >
                                        Sauvegarder
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.nbplace }}</td>
                        <td>{{ countElevesInscrits }}</td>
                        <td>{{ props.item.nbplace - countElevesInscrits }}</td>
                        <td>{{ props.item.tarif1 + " €" }}</td>
                        <td>{{ props.item.tarif2 + " €" }}</td>
                        <td>{{ props.item.tarif3 + " €" }}</td>
                        <td>{{ props.item.tarifSpectacle + " €" }}</td>
                        <td>
                            <v-btn
                                    @click="editCours(props.item)"
                                    color="#AD1F61"
                                    text
                            >
                                Modifier
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-row class=" mt-4">

                <v-col cols="12">
                    <info-cours-prof></info-cours-prof>
                </v-col>
                <v-col cols="12">
                    <info-cours-eleve></info-cours-eleve>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>

    import CoursService from "../../../../Services/coursService";
    import InfoCoursProf from "./infoCoursProf";
    import InfoCoursEleve from "./infoCoursEleve";

    export default {
        components: {
            InfoCoursEleve,
            InfoCoursProf
        },
        data: () => ({
            titleCours: '',
            link: "/monespace/Admin/infoeleve/",
            currentUser: {},
            role: localStorage.getItem("role"),
            cours: [],
            alertValue: false,
            dialogCours: false,
            typecours: {},
            eleves: {},
            countElevesInscrits: 0,
            headersCours: [
                {
                    text: 'Nombre de places totales',
                    align: 'start',
                    value: 'nbplace',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Nombre d\'élève(s) inscrit(s)', value: 'nbeleveinscrit', class: "text-font-h2 mpink-text"},
                {text: 'Nombre de place(s) restante(s)', value: 'nbplacerestantes', class: "text-font-h2 mpink-text"},
                {text: 'Tarif 1', value: 'tarif1', class: "text-font-h2 mpink-text"},
                {text: 'Tarif 2', value: 'tarif2', class: "text-font-h2 mpink-text"},
                {text: 'Tarif 3', value: 'tarif3', class: "text-font-h2 mpink-text"},
                {text: 'Tarif spectacle', value: 'tarifSpectacle', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexCours: -1,
            editedCours: {
                nom: '',
                jour: '',
                horaire: '',
                nbplace: 0,
                tarif1: 0,
                tarif2: 0,
                tarif3: 0,
                tarifSpectacle: 0,
            },
            defaultItemCours: {
                nom: '',
                jour: '',
                horaire: '',
                nbplace: 0,
                tarif1: 0,
                tarif2: 0,
                tarif3: 0,
                tarifSpectacle: 0,
            },
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            jourRules: [
                v => !!v || 'Le jour ne doit pas être vide',
            ],
            horaireRules: [
                v => !!v || 'L\'horaire ne doit pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getCours();
            this.getEleves();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getCours() {

                const tmp = await CoursService.getCours(this.$route.params.id, localStorage.getItem("token"));
                this.cours = [tmp.data];
                this.titleCours = this.cours[0].nom + " | " + this.cours[0].jour + " " + this.cours[0].horaire;
            },
            async getEleves() {
                const tmp = await CoursService.getEleveCours(this.$route.params.id, localStorage.getItem("token"));
                this.countElevesInscrits = tmp.data.length;
                this.eleves = tmp.data;
            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            editCours(item) {
                this.editedIndexCours = this.cours.indexOf(item);
                this.editedCours = Object.assign({}, item);
                this.dialogCours = true;
            },
            closeCours() {
                this.dialogCours = false;
                this.$nextTick(() => {
                    this.editedCours = Object.assign({}, this.defaultItemCours);
                    this.editedIndexCours = -1;
                })
            },
            async saveCours() {
                if (this.editedIndexCours > -1) {
                    try {
                        if (this.editedCours.nom.trim() != '' && this.editedCours.jour.trim() != '' && this.editedCours.horaire.trim() != ''
                            && this.editedCours.nbplace != '' && this.editedCours.tarif1 != '' && this.editedCours.tarif2 != ''
                            && this.editedCours.tarif3 != '' && this.editedCours.tarifSpectacle != '') {

                            let body = {
                                id: this.editedCours.id,
                                updates: {
                                    nom: this.editedCours.nom,
                                    jour: this.editedCours.jour,
                                    horaire: this.editedCours.horaire,
                                    nbplace: this.editedCours.nbplace,
                                    tarif1: this.editedCours.tarif1,
                                    tarif2: this.editedCours.tarif2,
                                    tarif3: this.editedCours.tarif3,
                                    tarifSpectacle: this.editedCours.tarifSpectacle,
                                }
                            };
                            await CoursService.updateCours(body, localStorage.getItem("token"));
                            this.titleCours = this.editedCours.nom + " " + this.editedCours.jour + " " + this.editedCours.horaire;
                            Object.assign(this.cours[this.editedIndexCours], this.editedCours);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeCours()
            },
        },
        name: "infoCours"
    }
</script>

<style scoped>

</style>
