<template>
    <v-container class="contain">
        <v-row class="justify-space-around">
            <v-col md="12" sm="12" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text mb-5">LA SALLE DE DANSE</h1>
            </v-col>
        </v-row>
        <v-row class="justify-space-around">
            <v-col md="12" sm="12" class="mb-5">
                <p class="text-font-h3 text-center">Adresse :</p>
                <p class="text-font-small text-center">Salle de danse Stade Saint Vincent</p>
                <p class="text-font-small text-center">Chaussée Saint Vincent</p>
                <p class="text-font-small text-center">78580 Maule</p>
                <p class="text-font-small text-center">(à droite des sapeurs pompier de maule)</p>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="3" sm="12" class="align-content-center mb-5"></v-col>
            <v-col md="4" sm="12" class="align-content-center mb-5">
                <iframe class="align-center"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d655.5420394037187!2d1.8508903292492085!3d48.91218838009991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6912391a79b4d%3A0x66f3d2e44442c4f5!2sPlanete%20Jeunes!5e0!3m2!1sfr!2sfr!4v1625226232607!5m2!1sfr!2sfr"
                        width="750" height="500" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
            </v-col>
            <v-col md="4" sm="12" class="align-content-center mb-5"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "salle"
    }
</script>

<style scoped>

</style>
