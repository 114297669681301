import Vue from "vue";
import Router from "vue-router";
import Index from "./components/Index";
import MainNavbar from "./Layouts/MainNavbar.vue";
import MainFooter from "./Layouts/MainFooter.vue";
import Contact from "@/components/contact";
import wip from "./components/wip";
import tarifs from "./components/informationsutiles/tarifs";
import planning from "@/components/informationsutiles/planning";
import reglementinterieur from "@/components/association/reglementinterieur";
import tenues from "@/components/informationsutiles/tenues";
import salle from "@/components/informationsutiles/salle";
import cours from "@/components/cours";
import unarticle from "./components/unarticle";
import touslesarticles from "./components/touslesarticles";
import Login from "@/components/espaceAdherent/login";
import AdhNavbar from "@/components/espaceAdherent/Layouts/AdhNavbar";
import AdhFooter from "@/components/espaceAdherent/Layouts/AdhFooter";
import indexAdherent from "@/components/espaceAdherent/indexAdherent";
import ajoutArticle from "@/components/espaceAdherent/espaceAdmin/Articles/ajoutArticle";
import indexArticle from "@/components/espaceAdherent/espaceAdmin/Articles/indexArticle";
import infoArticle from "@/components/espaceAdherent/espaceAdmin/Articles/infoArticle";
import indexAdmin from "@/components/espaceAdherent/espaceAdmin/indexAdmin";
import listEleve from "./components/espaceAdherent/espaceAdmin/Eleve/listEleve";
import infoEleve from "./components/espaceAdherent/espaceAdmin/Eleve/infoEleve";
import listCours from "./components/espaceAdherent/espaceAdmin/Cours/listCours";
import infoCours from "./components/espaceAdherent/espaceAdmin/Cours/infoCours";
import listEleveAttente from "./components/espaceAdherent/espaceAdmin/Eleve/listEleveAttente";
import listEleveListeAttente from "./components/espaceAdherent/espaceAdmin/Eleve/listEleveListeAttente";
import Inscription from "@/components/espaceAdherent/Inscription/Inscription";
import Inscription2 from "@/components/espaceAdherent/Inscription/Inscription2";
import Inscription3 from "@/components/espaceAdherent/Inscription/Inscription3";
import Inscription4 from "@/components/espaceAdherent/Inscription/Inscription4";
import Inscription5 from "@/components/espaceAdherent/Inscription/Inscription5";
import Register from "@/components/espaceAdherent/register";
import InfoEleveAdh from "@/components/espaceAdherent/Accueil/infoEleveAdh";
import InfoCompte from "@/components/espaceAdherent/Compte/infoCompte";
import Professeur from "@/components/association/professeur";
import Quisommesnous from "@/components/association/quisommesnous";
import InscriptionCours from "./components/espaceAdherent/Accueil/inscriptionCours";
import Assembleegenerale from "@/components/association/assembleegenerale";
import ListValidateFacture from "@/components/espaceAdherent/espaceAdmin/Facture/listValidateFacture";
import ListWaitingFacture from "@/components/espaceAdherent/espaceAdmin/Facture/listWaitingFacture";
import Dashboard from "@/components/espaceAdherent/espaceAdmin/Dashboard/dashboard";
import MentionsLegaless from "@/components/mentionlegale";
import Forgivenpwd from "@/components/espaceAdherent/forgivenpwd";
import Galerie from "./components/galerie";

Vue.use(Router);

export default new Router({
    routes: [
        {
            path: "/",
            name: "Index.vue",
            components: { default: Index, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/contact",
            name: "contact.vue",
            components: { default: Contact, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/login",
            name: "login.vue",
            components: { default: Login, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/wip",
            name: "wip.vue",
            components: { default: wip, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/tarifs",
            name: "tafis.vue",
            components: { default: tarifs, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/planning",
            name: "planning.vue",
            components: { default: planning, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/reglementinterieur",
            name: "reglementinterieur.vue",
            components: { default: reglementinterieur, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/tenues",
            name: "tenues.vue",
            components: { default: tenues, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/salle",
            name: "salle.vue",
            components: { default: salle, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/cours",
            name: "cours.vue",
            components: { default: cours, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/professeur",
            name: "professeur.vue",
            components: { default: Professeur, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/quisommesnous",
            name: "quisommesnous.vue",
            components: { default: Quisommesnous, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/assembleegenerale",
            name: "assembleegenerale.vue",
            components: { default: Assembleegenerale, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/unarticle/:id",
            name: "unarticle.vue",
            components: { default: unarticle, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/touslesarticles",
            name: "touslesarticles.vue",
            components: { default: touslesarticles, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace",
            name: "indexAdherent.vue",
            components: { default: indexAdherent, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Article",
            name: "ajoutArticle.vue",
            components: { default: ajoutArticle, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/indexarticle",
            name: "indexArticle.vue",
            components: { default: indexArticle, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/voirarticle/:id",
            name: "infoArticle.vue",
            components: { default: infoArticle, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin",
            name: "indexAdmin.vue",
            components: { default: indexAdmin, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Inscription",
            name: "Inscription.vue",
            components: { default: Inscription, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/listeleve",
            name: "listEleve.vue",
            components: { default: listEleve, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Inscription2",
            name: "Inscription2.vue",
            components: { default: Inscription2, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/infoeleve/:id",
            name: "infoEleve.vue",
            components: { default: infoEleve, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/listcours",
            name: "listCours.vue",
            components: { default: listCours, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/infocours/:id",
            name: "infoCours.vue",
            components: { default: infoCours, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/listeleveattente",
            name: "listEleveAttente.vue",
            components: { default: listEleveAttente, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/listelevelisteattente",
            name: "listEleveAttente.vue",
            components: { default: listEleveListeAttente, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Inscription3",
            name: "Inscription3.vue",
            components: { default: Inscription3, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Inscription4",
            name: "Inscription4.vue",
            components: { default: Inscription4, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Inscription5",
            name: "Inscription5.vue",
            components: { default: Inscription5, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/register",
            name: "register.vue",
            components: { default: Register, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/infoeleve/:id",
            name: "infoEleveAdh.vue",
            components: { default: InfoEleveAdh, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/moncompte",
            name: "infoCompte.vue",
            components: { default: InfoCompte, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
         },
        {
            path: "/monespace/inscriptioncours/:id",
            name: "inscriptionCours.vue",
            components: { default: InscriptionCours, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }

        },
        {
            path: "/monespace/Admin/listfactures",
            name: "listValidateFacture.vue",
            components: { default: ListValidateFacture, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/Admin/listattentefactures",
            name: "listWaitingFacture.vue",
            components: { default: ListWaitingFacture, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/monespace/dashboard",
            name: "dashboard.vue",
            components: { default: Dashboard, header: AdhNavbar, footer: AdhFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/mentionslegales",
            name: "mentionslegales.vue",
            components: { default: MentionsLegaless, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/forgivenpwd",
            name: "forgivenpwd.vue",
            components: { default: Forgivenpwd, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
        {
            path: "/galerie",
            name: "galerie.vue",
            components: { default: Galerie, header: MainNavbar, footer: MainFooter },
            props: {
                header: { colorOnScroll: 400 },
                footer: { backgroundColor: "black" }
            }
        },
    ],

    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});
