import ApiService from "@/Services/apiService";

export default class SaisonService {

    static async updateSaison(body, token) {
        await ApiService.apiCall("put", "saison/update", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getSaison() {
        return await ApiService.apiCall("get", "saison/getAll", {}, {});
    }

}
