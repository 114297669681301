<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="justify-center">
    <v-img :src="ban"></v-img>
    <v-toolbar class="Bar-color bg2" elevation="1">
      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer class="hidden-sm-and-down"></v-spacer>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn class="Bar-color bg2 text-sb" to="/" elevation="0">
          Accueil
        </v-btn>
        <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn flat v-bind="attrs" v-on="on" class="Bar-color bg2 text-sb mx-2" elevation="0">
              Cours
            </v-btn>
          </template>
          <v-list dense class="pa-0">
            <v-list-item class="tile" v-for="(item, index) in cours" :key="index" router :to="item.link" >
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn flat v-bind="attrs" v-on="on" class="Bar-color bg2 text-sb mx-2" elevation="0">
              Informations utiles
            </v-btn>
          </template>
          <v-list dense class="pa-0">
            <v-list-item class="tile" v-for="(item, index) in informationsUtiles" :key="index" router :to="item.link">
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu open-on-hover offset-y transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn flat v-bind="attrs" v-on="on" class="Bar-color bg2 text-sb mx-2" elevation="0">
              L'association
            </v-btn>
          </template>
          <v-list dense class="pa-0">
            <v-list-item class="tile" v-for="(item, index) in association" :key="index" router :to="item.link">
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn class="Bar-color bg2 text-sb mx-2" to="/galerie" elevation="0">
          Galerie
        </v-btn>
        <v-btn class="Bar-color bg2 text-sb mx-2" to="./Contact" elevation="0">
          Contact
        </v-btn>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="Bar-color bg2 text-sb mr-n16 ml-5"
                elevation="0"
                href="https://www.facebook.com/kdanse.maule"
                target="_blank"
            >
              <v-icon
                  dark
                  large
                  color="white"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-facebook
              </v-icon>
            </v-btn>
          </template>
          <span>Rejoignez-nous sur facebook</span>
        </v-tooltip>
        <v-spacer class="Bar-color-invert text-sb mx-16 rounded-b-lg hidden-md-and-down"></v-spacer>
        <v-btn to="/login" flat rounded class="Bar-color-invert text-sb py-12 ml-16 rounded-b-lg" elevation="5">Espace Adhérent</v-btn>
      </v-toolbar-items>
      <v-spacer class="hidden-md-and-down"></v-spacer>
    </v-toolbar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        bottom
        temporary
        style="top:0;"
    >
      <v-list
          nav
          dense
      >
        <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title to="/">Accueil</v-list-item-title>
          </v-list-item>
          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Cours</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item class="tile" v-for="(item, index) in cours" :key="index" router :to="item.link" >
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Informations utiles</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item  class="tile" v-for="(item, index) in informationsUtiles" :key="index" router :to="item.link">
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>

          <v-list-group :value="true" no-action sub-group>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>L'association</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item  class="tile" v-for="(item, index) in association" :key="index" router :to="item.link">
              <v-list-item-action>
                <v-list-item-title class="text-sb">{{ item.title }}</v-list-item-title>
              </v-list-item-action>
            </v-list-item>
          </v-list-group>

          <v-list-item>
            <v-list-item-title to="/galerie">Galerie</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title to="./Contact">
              Contact
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-btn
                class="Bar-color bg2 text-sb mr-n16 ml-5"
                elevation="0"
                href="https://www.facebook.com/kdanse.maule"
                target="_blank"
            >
              <v-icon
                  dark
                  large
                  color="white"
                  v-bind="attrs"
                  v-on="on"
              >
                mdi-facebook
              </v-icon>
              Rejoignez-nous sur facebook
            </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn to="/login" flat rounded class="Bar-color-invert text-sb mx-auto py-8 rounded-b-lg" elevation="5">Espace Adhérent</v-btn>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    ban : require("../assets/img/Ban.png"),
    cours: [
      {
        title: "Initiation à la danse",
        link: "/cours#initiation"
      },
      {
        title: "Danse Modern Jazz",
        link: "/cours#modern"
      },
      {
        title: "Danse Classique",
        link: "/cours#classique"
      },
      {
        title: "Maintien Classique",
        link: "/cours#maintien"
      }
    ],
    informationsUtiles: [
      {
        title: "Planning des cours",
        link: "/planning"
      },
      {
        title: "Tarifs",
        link: "/tarifs"
      },
      {
        title: "Salle de danse",
        link: "/salle"
      },
      {
        title: "Tenues",
        link: "/tenues"
      }
    ],
    association:[
      {
        title: "Qui sommes-nous ?",
        link: '/quisommesnous'
      },
      {
        title: "Notre professeur",
        link: '/professeur'
      },
      {
        title: "Règlement intérieur",
        link: '/reglementinterieur'
      },
      {
        title: "Assemblées Générales",
        link: '/assembleegenerale'
      },
    ],
  }),
  watch: {
    group () {
      this.drawer = false
    },
  },
};
</script>
<style>
</style>
