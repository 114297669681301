<template>
    <v-container class="contain">
        <v-row justify="space-around">
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text my-5">INSCRIPTION</h1>
                <h1 class="text-font-h1 mpink-text my-5">Personne à contacter en cas d'urgence</h1>
                <v-card
                        v-for="index in nb" :key="index"
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="nom[index]"
                                            :rules="nomRules"
                                            label="Nom de famille"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="prenom[index]"
                                            :rules="prenomRules"
                                            label="Prénom"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="telephone[index]"
                                            :rules="telephoneRules"
                                            label="Téléphone"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="lien[index]"
                                            :rules="lienRules"
                                            label="Lien avec l'élève"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-left">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addPersonne"
                >
                    Ajouter une personne supplémentaire
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-right">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addUrgence"
                >
                    Suivant
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            valid: true,
            nb: 1,
            nomRules: [
                v => !!v || 'Le nom ne doit pas etre vide',
            ],
            prenomRules: [
                v => !!v || 'Le prenom ne doit pas etre vide',
            ],
            telephoneRules: [
                v => !!v || 'Veuillez saisir le telephone',
            ],
            lienRules: [
                v => !!v || 'Veuillez saisir le lien',
            ],
            nom: [],
            prenom: [],
            telephone: [],
            lien: [],
            token: [],
        }),
        beforeMount() {
            this.token = localStorage.getItem("token");
        },
        methods: {
            async addUrgence() {
                let i = 1;
                for (i = 1; i <= this.nb; i++) {
                    const body = {
                        nom: this.nom[i],
                        prenom: this.prenom[i],
                        telephone: this.telephone[i],
                        lien: this.lien[i],
                        eleve_id: localStorage.getItem("currentEleveId"),
                    };

                    await EleveService.addUrgence(body, this.token);
                }
                await this.$router.push('/monespace/Inscription4');
            },
            validate() {
                this.$refs.form.validate()
            },
            addPersonne() {
                this.nb += 1;
            }
        },
        name: "Inscription3"
    }
</script>

<style scoped>

</style>
