import ApiService from "@/Services/apiService";

export default class UtilisateurService {

    static async getMyEleves(id, token) {
        return await ApiService.apiCall("get", "utilisateur/getUtilisateurEleves/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getFamille(id, token) {
        return await ApiService.apiCall("get", "utilisateur/getone/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllUtilisateur(token) {
        return await ApiService.apiCall("get", "utilisateur/getall/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateUtilisateur(body, token) {
        return await ApiService.apiCall("put", "utilisateur/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async resetPassword(body) {
        return await ApiService.apiCall("put", "utilisateur/resetpwd/", body, {});
    }
}
