<template>
    <v-container class="contain">
        <v-row>
            <v-col md="7" class="mt-10">
                <v-carousel
                        class="rounded-lg"
                        cycle
                        height="500"
                        hide-delimiter-background
                        show-arrows-on-hover
                >
                    <v-carousel-item
                            v-for="(slide, i) in slides"
                            :key="i"
                            :src="slide.src"
                    >
                    </v-carousel-item>
                </v-carousel>
            </v-col>
            <v-col md="5" class="mt-10">
                <v-card
                        class="mx-auto"
                        style="margin-top: 25%"
                        max-width="500"
                        elevation="0"
                >
                    <v-card-text>
                        <h3 class="text-font text-justify">
                            Située à Maule dans les Yvelines, l'association K'danse vous propose des cours de danse
                            Modern'jazz, de danse classique pour tous les âges et tous les
                            niveaux.
                        </h3>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                                class="mx-auto my-5 text-font-link bg1 pa-6 rounded-lg"
                                elevation="5"
                                to="/cours"
                        >
                            Découvrir nos cours de danse
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
          <v-col md="2"></v-col>
          <v-col md="8">
            <v-alert
                :value="alertValue"
                shaped
                class="mt-7 Bar-color-invert"
            >
              <v-row>
                  <v-row>
                    <v-col class="text-center text-font-h0">INSCRIPTION 2024-2025</v-col>
                  </v-row>
                  <v-row>
                    <v-col class="text-justify text-font-h2 mb-5">
                      Pour les anciens élèves, réinscriptions du <span class="ulined">24 juin au 8 juillet</span> en vous connectant directement sur votre <a class="white--text" href="https://kdanse-maule.fr/#/login">espace adhérent.</a><br>
                      Pour les nouveaux élèves, inscriptions en ligne à partir du <span class="ulined">26 août.</span><br><br>
                      L'inscription doit être réalisée depuis un ordinateur.
                    </v-col>
                  </v-row>
              </v-row>
            </v-alert>
          </v-col>
          <v-col md="2"></v-col>
        </v-row>
        <h1 class="text-center mt-16 text-font-pink">ACTUALITÉS</h1>
        <v-divider class="mt-5 mb-10 divider"></v-divider>
        <v-row>
            <v-col md="4" v-for="(item, index) in articles.slice(0,3)" :key="index">
                <v-card
                        class="mx-auto"
                        max-width="400"
                >
                    <v-img
                            class="align-end text-font-link"
                            height="200px"
                            :src="item.imageTitre"
                    >

                    </v-img>
                    <v-card-title class="text-font-h2 mpink-text justify-center">{{item.titre}}</v-card-title>

                    <v-card-actions>
                        <v-btn
                                :to="link + item.id"
                                color="#AD1F61"
                                text
                        >
                            Voir plus
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col md="12">
                <v-btn
                        to="/touslesarticles/"
                        color="#AD1F61"
                        text
                >
                    Voir tous les articles
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ArticleService from "@/Services/articleService";

    export default {
      components: {},

      data() {
            return {
                link: "/unarticle/",
                slides: [
                    {
                        src: require("../assets/img/carroussel1.jpg")
                    },
                    {
                        src: require("../assets/img/carroussel5.jpg")
                    },
                    {
                        src: require("../assets/img/carroussel4.jpg")
                    },
                    {
                        src: require("../assets/img/carroussel2.jpg")
                    },
                    {
                        src: require("../assets/img/carroussel3.jpg")
                    }
                ],
                src: "../assets/",
                articles: [],
                alertValue: true,
            }
        },
        methods: {
            async getArticles() {
                const arts = await ArticleService.getAllArticleEnAvant();

                for (let i = 0; i < this.articles.length; i++) {
                    arts.data[i].imageTitre = require("../assets" + arts.data[i].imageTitre);
                }
                this.articles = arts.data;
            },
        },
        beforeMount() {
            this.getArticles();
        }
    }
</script>
<style>
</style>
