<template>
  <v-container class="contain">
    <v-row class="mt-5">
      <v-col
          v-for="img in photos"
          :key="img"
          class="d-flex child-flex"
          cols="3"
      >
        <v-img
            :src="img.pathLong"
            :lazy-src="img.pathLong"
            aspect-ratio="1"
            class="grey lighten-2"
        >
          <template v-slot:placeholder>
            <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
            >
              <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "galerie.vue",
  data() {
    return {
      photos: [],
    }
  },
  mounted() {
    this.importAll(require.context('../assets/img/Galerie/', true, /\.jpg$/));
  },
  methods: {
    importAll(r) {
      r.keys().forEach(key => (this.photos.push({ pathLong: r(key), pathShort: key })));
    }
  },
}
</script>

<style scoped>

</style>
