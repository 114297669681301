<template>
    <v-container class="contain">
        <v-row>
            <v-col md="4"></v-col>
            <v-col md="4" class="mt-10">
                <v-card-text>
                    <h1 class="text-center">Création de mon compte</h1>
                </v-card-text>
                <h4 class="text-center mb-2"><span class="italic">Merci de créer un seul compte par famille.</span></h4>
                <v-card
                        class="mx-auto rounded-lg"
                        elevation="5"
                >
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >Le mot de passe et la confirmation du mot de passe ne correspondent pas.
                    </v-alert>
                    <v-alert
                            type="error"
                            :value="alertValue3"
                    >Il existe déjà un compte utilisateur avec cette adresse mail.
                    </v-alert>
                    <v-alert
                            :value="alertValue2"
                            dense
                            text
                            type="success"
                    >
                        <v-row align="center">
                            <v-col class="grow">
                                Votre compte a bien été créé.
                            </v-col>
                            <v-col class="shrink">
                                <v-btn
                                        @click="redirect"
                                >
                                    Se connecter
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                    <v-card-text class="text-center">
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.nom"
                                        :rules="nomRules"
                                        label="Nom de famille"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.email"
                                        :rules="emailRules"
                                        label="E-mail"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.mdp"
                                        :rules="mdpRules"
                                        :type="'password'"
                                        label="Mot de passe"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.mdpConf"
                                        :rules="mdpConfRules"
                                        :type="'password'"
                                        label="Confirmez le mot de passe"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-btn
                                        :disabled="!valid"
                                        class="Bar-color-invert text-sb rounded-lg"
                                        @click="createAccount"
                                >
                                    Créer
                                </v-btn>
                            </v-col>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="4"></v-col>
        </v-row>
    </v-container>
</template>

<script>
    import AuthService from "@/Services/authService";

    export default {
        data: () => ({
            valid: false,
            nomRules: [
                v => !!v || 'Votre nom ne doit pas etre vide',
            ],
            emailRules: [
                v => !!v || 'Votre e-mail ne doit pas etre vide',
                v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
            ],
            mdpRules: [
                v => !!v || 'Veuillez saisir votre mot de passe',
            ],
            mdpConfRules: [
                v => !!v || 'Veuillez confirmer votre mot de passe',
            ],
            form: {
                nom: '',
                email: '',
                mdp: '',
                mdpConf: '',
            },
            alertValue: false,
            alertValue2: false,
            alertValue3: false,
            message: ""
        }),
        methods: {
            async createAccount() {
                this.alertValue = this.alertValue2 = this.alertValue3 = false;
                if (this.form.mdp === "" || this.form.mdpConf === "") {
                    this.valid = false;
                } else {
                    if (this.form.mdp !== this.form.mdpConf) {
                        this.alertValue = true;
                    } else {
                        const body = {
                            nom: this.form.nom,
                            email: this.form.email,
                            mdp: this.form.mdp,
                            mdpConf: this.form.mdpConf,
                        };
                        try {
                            await AuthService.register(body);
                            this.alertValue2 = true;
                        } catch (e) {
                            this.alertValue3 = true;
                        }
                    }
                }
            },
            async redirect() {
                await this.$router.push('/login');
            },
            validate() {
                this.$refs.form.validate()
            },
        },
        name: "Register"
    }
</script>

<style scoped>

</style>
