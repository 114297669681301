<template>
    <v-container class="contain">
        <h1 class="text-center text-font-h0 mpink-text my-5">LISTE DE TOUS LES ARTICLES</h1>
        <v-row class="justify-space-around mt-8">
            <v-col cols="5">
                <v-data-table
                        :headers="headers"
                        :items="allarticle"
                        hide-default-footer
                        class="mt-8"
                        :items-per-page="-1"
                >
                    <template v-slot:item="props">
                        <tr>
                            <td>{{ props.item.titre }}</td>
                            <td>{{ props.item.sousTitre }}</td>
                            <td>
                                <v-btn
                                        :to="link + props.item.id"
                                        color="#AD1F61"
                                        text
                                >
                                    Voir plus
                                </v-btn>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>


    import ArticleService from "../Services/articleService";

    export default {
        data: () => ({
            allarticle: [],
            headers: [
                {
                    text: 'Titre',
                    align: 'start',
                    value: 'titre',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Sous Titre', value: 'sousTitre', class: "text-font-h2 mpink-text"},
                {text: ' ', value: '', sortable: false}
            ],
            link: "/unarticle/",
        }),
        beforeMount() {
            this.getAllArticle();
        },
        methods: {
            async getAllArticle() {
                const tmp = await ArticleService.getAllArticleEnAvant();

                this.allarticle = tmp.data;
            }
        },
        name: "touslesarticles"
    }
</script>

<style scoped>

</style>
