import ApiService from "@/Services/apiService";

export default class EleveService {

    static async createEleve(body, config = {}) {
        return await ApiService.apiCall("post", "eleve/", body, config);
    }

    static async addPersonneAutorisee(body, token) {
        return await ApiService.apiCall("post", "personneautorisee/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async addResponsable(body, token) {
        return await ApiService.apiCall("post", "responsable/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async addUrgence(body, token) {
        return await ApiService.apiCall("post", "urgence/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllEleve(token) {
        return await ApiService.apiCall("get", "eleve/getall", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleve(id, token) {
        return await ApiService.apiCall("get", "eleve/getone/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveUrgence(id, token) {
        return await ApiService.apiCall("get", "eleve/geturgence/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveResponsable(id, token) {
        return await ApiService.apiCall("get", "eleve/getresponsable/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveAutorisee(id, token) {
        return await ApiService.apiCall("get", "eleve/getpersonneautorisee/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveAttente(token) {
        return await ApiService.apiCall("get", "eleve/geteleveattente/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveListeAttente(token) {
        return await ApiService.apiCall("get", "eleve/getelevelisteattente/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllEleveInscrit(token) {
        return await ApiService.apiCall("get", "eleve/geteleveinscrit/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveGroupByVille(token) {
        return await ApiService.apiCall("get", "eleve/getelevegroupbyville/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveAttenteById(id, token) {
        return await ApiService.apiCall("get", "eleve/geteleveattentebyid/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveInscritById(id, token) {
        return await ApiService.apiCall("get", "eleve/geteleveinscritbyid/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getEleveListeAttenteById(id, token) {
        return await ApiService.apiCall("get", "eleve/getelevelisteattentebyid/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getInscriptionGroupByEleve(token) {
        return await ApiService.apiCall("get", "eleve/getinscriptiongroupbyeleve/", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAbsenceByEleve(id, token) {
        return await ApiService.apiCall("get", "absence/getbyeleve/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateResponsable(body, token) {
        return await ApiService.apiCall("put", "responsable/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteResponsable(id, token) {
        return await ApiService.apiCall("delete", "responsable/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateUrgence(body, token) {
        return await ApiService.apiCall("put", "urgence/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteUrgence(id, token) {
        return await ApiService.apiCall("delete", "urgence/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updatePersonneAutorisee(body, token) {
        return await ApiService.apiCall("put", "personneautorisee/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deletePersonneAutorisee(id, token) {
        return await ApiService.apiCall("delete", "personneautorisee/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateEleve(body, token) {
        return await ApiService.apiCall("put", "eleve/update/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }
}
