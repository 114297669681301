<template>
    <v-app>
        <v-container class="contain"
                     v-if="role === 'utilisateur' || role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">RÉCAPITULATIF ADHÉRENT</h1>
            <v-row>
                <v-col md="12">
                    <v-btn v-if="saison == true"
                           class="Bar-color-invert text-sb rounded-lg"
                           @click="inscription"
                    >
                        Inscrire un élève
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col
                        v-for="(item, index) in myeleves" :key="index"
                        cols="4"
                >
                    <v-card width="460" class="my-5">
                        <v-card-title class="black--text">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-account
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                {{item.prenom}} {{item.nom}}
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Date de naissance : </span> {{convertDate(item.dateNaissance)}}
                        </p>
                        <p class="ml-10">
                            <span class="bold">Statut inscription :</span>
                            <v-chip
                                    v-if="item.inscrit"
                                    class="ma-2"
                                    color="green"
                                    outlined
                            >
                                Inscription validée
                            </v-chip>
                            <v-chip
                                    v-if="item.listeAttente"
                                    class="ma-2"
                                    color="blue"
                                    outlined
                            >
                                Sur liste d'attente
                            </v-chip>
                            <v-chip
                                    v-if="item.attenteInscription"
                                    class="ma-2"
                                    color="deep-purple accent-4"
                                    outlined
                            >
                                Demande envoyée
                            </v-chip>
                            <v-chip
                                    v-if="item.nonInscrit"
                                    class="ma-2"
                                    color="red"
                                    outlined
                            >
                                Non inscrit
                            </v-chip>
                        </p>


                        <table
                                class="ml-10"
                                v-if="item.inscritTab.length > 0"
                        >
                            <tr>
                                <span class="bold">Cours inscrits : </span>
                            </tr>
                            <tbody
                                    v-for="itemI in item.inscritTab"
                                    :key="itemI"
                            >
                            <td>
                                {{ itemI }}
                            </td>
                            </tbody>
                        </table>
                        <v-card-actions>
                          <v-col
                              class="text-left ml-n5"
                          >
                            <v-btn
                                color="#AD1F61"
                                text
                                class="ml-5"
                                :to="link + item.id"
                            >
                              Voir plus
                            </v-btn>
                          </v-col>
                          <v-col
                              class="text-right"
                          >
                            <v-btn
                                v-if="saison == true"
                                color="#AD1F61"
                                class="white--text end"
                                :to="linkReinscription + item.id"
                            >
                              Réinscrire
                            </v-btn>
                          </v-col>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <list-facture></list-facture>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import UtilisateurService from "@/Services/utilisateurService";
    import EleveService from "../../Services/eleveService";
    import ListFacture from "./Facture/listFacture";
    import CoursService from "../../Services/coursService";

    export default {
        components: {ListFacture},
        data: () => ({
            currentUser: {},
            role: localStorage.getItem("role"),
            myeleves: {},
            link: "/monespace/infoeleve/",
            linkReinscription: "/monespace/inscriptioncours/",
            linkFacture: "/monespace/mesfactures/",
            saison: 0,
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getMyeleves();
            this.getSaisons();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getSaisons() {
                const tmp = await CoursService.getAllSaisons(localStorage.getItem("token"));
                this.saison = tmp.data[0].ouvert;
            },
            async getMyeleves() {

                const tmp = await UtilisateurService.getMyEleves(localStorage.getItem("id"), localStorage.getItem("token"));

                for (var i = 0; i < tmp.data.length; i++) {
                    tmp.data[i].inscrit = false;
                    tmp.data[i].listeAttente = false;
                    tmp.data[i].attenteInscription = false;
                    tmp.data[i].nonInscrit = false;
                    tmp.data[i].inscritTab = [];
                    try {
                        const inscrit = await EleveService.getEleveInscritById(tmp.data[i].id, localStorage.getItem("token"));

                        if (inscrit.data.length > 0) {

                            tmp.data[i].inscrit = true;

                            for (var k = 0; k < inscrit.data.length; k++) {
                                tmp.data[i].inscritTab.push(inscrit.data[k].inscription_cours.nom + " " + inscrit.data[k].inscription_cours.jour + " " + inscrit.data[k].inscription_cours.horaire);
                            }

                        }
                        const listeAttente = await EleveService.getEleveListeAttenteById(tmp.data[i].id, localStorage.getItem("token"));

                        if (listeAttente.data.length > 0) {

                            tmp.data[i].listeAttente = true;
                        }
                        const attenteInscription = await EleveService.getEleveAttenteById(tmp.data[i].id, localStorage.getItem("token"));

                        if (attenteInscription.data.length > 0) {

                            tmp.data[i].attenteInscription = true;
                        }
                        if (inscrit.data.length === 0 && listeAttente.data.length === 0 && attenteInscription.data.length === 0) {

                            tmp.data[i].nonInscrit = true;
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                this.myeleves = tmp.data;

            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            async inscription() {
                await this.$router.push('/monespace/Inscription');
            },
            async inscrireEleve() {
              await this.$router.push('/monespace/inscriptioncours/' + this.$route.params.id);
            },
        },
        name: "indexAdherent"
    }
</script>

<style scoped>

</style>
