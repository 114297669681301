<template>

    <v-data-table
            :headers="headersFacture"
            :items="factures"
            class="elevation-3"
            :items-per-page="-1"
            hide-default-footer
    >
        <template v-slot:item="props">
            <tr>
                <td v-if="props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">{{ props.item.nomEleve }}</td>
                <td v-else>{{ props.item.nomEleve }}</td>

                <td>{{ props.item.prenomEleve }}</td>

                <td><p v-for="cours in props.item.coursInscrit" :key="cours.id">{{ cours.nom }}</p></td>

                <td v-if="props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">{{ props.item.totalCotisation }} €</td>
                <td v-else>{{ props.item.totalCotisation }} €</td>

                <td v-if="props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">{{ props.item.totalCours }} €</td>
                <td v-else>{{ props.item.totalCours }} €</td>

                <td v-if="props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">{{ props.item.totalCostume }} €</td>
                <td v-else>{{ props.item.totalCostume }} €</td>

                <td v-if=" props.item.isPayed === 1 && props.item.nomEleve != 'Total'">Oui</td>
                <td v-else-if=" props.item.isPayed === 0 && props.item.nomEleve != 'Total'">Non</td>
                <td v-else-if=" props.item.isPayed === 2 && props.item.nomEleve != 'Total'">Partiellement</td>

                <td v-else-if=" props.item.isPayed === 1 && props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">Oui
                </td>
                <td v-else-if=" props.item.isPayed === 0 && props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text">Non
                </td>
                <td v-else></td>

                <td v-if="props.item.nomEleve == 'Total'" class="text-font-h2 mpink-text"> {{ props.item.totalCotisation +
                    props.item.totalCours + props.item.totalCostume }} €
                </td>
                <td v-else-if="props.item.nomEleve != 'Total'"> {{ props.item.totalCotisation +
                    props.item.totalCours + props.item.totalCostume }} €
                </td>
                <td> {{ props.item.commentairePartage }}</td>
                <td>
                    <v-btn v-if="props.item.nomEleve != 'Total' && (props.item.isPayed === 1 || props.item.isPayed === 2)"
                           class="Bar-color-invert text-sb rounded-lg"
                           text
                           @click="download(props.item.prenomEleve, props.item.nomEleve, props.item.coursInscrit, props.item.totalCours, saison)"
                    >
                        <v-icon>mdi-download</v-icon>
                        <h5 class="ml-2">Attestation CE</h5>
                    </v-btn>
                </td>
            </tr>
        </template>
        <template v-slot:top>
            <v-toolbar
                    flat
                    class="my-10"
            >
                <v-toolbar-title class="mpink-text text-font-h2">Récapitulatif de paiement</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
              <v-dialog
                  v-model="dialogRib"
                  max-width="50%"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="#AD1F61"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                  >
                    RIB de l'association
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="elevation-3">
                    <span class="text-font-h1 mpink-text">RIB</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col md="12">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Banque :</span><br> CREDIT AGRICOLE PARIS ET ILE DE FRANCE</v-card-text>
                        </v-col>
                        <v-col md="4">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Intitulé du compte :</span><br> ASSOC. KDANSE</v-card-text>
                        </v-col>
                        <v-col md="8">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Adresse :</span><br> MAIRIE DE MAULE 78580 MAULE</v-card-text>
                        </v-col>
                        <v-col md="3">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Code banque :</span><br> 1820</v-card-text>
                        </v-col>
                        <v-col md="3">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Code guichet :</span><br> 00040</v-card-text>
                        </v-col>
                        <v-col md="4">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Numéro de compte :</span><br> 41617252001</v-card-text>
                        </v-col>
                        <v-col md="2">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">Clé :</span><br> 15</v-card-text>
                        </v-col>
                        <v-col md="7">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">IBAN :</span><br> FR76 1820 6000 4041 6172 5200 115</v-card-text>
                        </v-col>
                        <v-col md="5">
                          <v-card-text class="text-font-h3 mb-n5"><span class="bold mpink-text">BIC :</span><br> AGRIFRPP882</v-card-text>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#AD1F61"
                        text
                        @click="closeRib"
                    >
                      Fermer
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:no-data>
            <h3>Vous n'avez pas encore de récapitulatif de paiement disponible.</h3>
        </template>
    </v-data-table>
</template>

<script>
    import FactureService from "../../../Services/factureService";
    import EleveService from "../../../Services/eleveService";
    import SaisonService from "@/Services/SaisonService";
    import jsPDF from 'jspdf';

    export default {
        data: () => ({
            currentUser: {},
            factures: [],
            totalCotisation: 0,
            totalCours: 0,
            totalCostume: 0,
            dialogRib: false,
            alertValue: false,
            editedIndexFacture: -1,
            role: localStorage.getItem("role"),
            img1: require("../../../assets/img/signature.png"),
            img2: require("../../../assets/img/logo-K-Danse-HD.jpg"),
            saison: '',
          headersFacture: [
                {
                    text: 'Nom de l\'élève',
                    align: 'start',
                    value: 'nomEleve',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Prénom de l\'élève', value: 'prenomEleve', class: "text-font-h2 mpink-text"},
                {text: 'Cours inscrits', value: 'coursInscrit', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Cotisation', value: 'totalCotisation', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Cours', value: 'totalCours', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Costume', value: 'totalCostume', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Payé', value: 'isPayed', class: "text-font-h2 mpink-text"},
                {text: 'Montant total', value: 'total', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Commentaires', value: 'commentairePartage', sortable: false, class: "text-font-h2 mpink-text"},
                {text: 'Téléchargement', value: 'actions', sortable: false, class: "text-font-h2 mpink-text"},
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getFactureUtilisateur();
            this.getSaison();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getSaison() {
              const saison = await SaisonService.getSaison();
              this.saison = saison.data[0].nom;
            },
            async getFactureUtilisateur() {

                const tmp = await FactureService.getFactureUtilisateur(localStorage.getItem("id"), localStorage.getItem("token"));
                let paye = 0;

                for (let i = 0; i < tmp.data.length; i++) {
                    const tmp2 = await EleveService.getEleveInscritById(tmp.data[i].eleve_id, localStorage.getItem("token"));
                    tmp.data[i].coursInscrit = [];

                    paye = tmp.data[i].isPayed;

                    for (let k = 0; k < tmp2.data.length; k++) {

                        tmp.data[i].nomEleve = tmp2.data[k].inscription_eleve.nom;
                        tmp.data[i].prenomEleve = tmp2.data[k].inscription_eleve.prenom;
                        tmp.data[i].coursInscrit.push({
                            id: tmp2.data[k].inscription_cours.id,
                            nom: tmp2.data[k].inscription_cours.nom
                        });
                    }
                    this.totalCotisation += tmp.data[i].totalCotisation;
                    this.totalCours += tmp.data[i].totalCours;
                    this.totalCostume += tmp.data[i].totalCostume;
                }
                this.factures = tmp.data;
                if (tmp.data.length > 0) {
                    this.factures.push({
                        nomEleve: 'Total',
                        prenomEleve: '',
                        coursInscrit: [],
                        totalCotisation: this.totalCotisation,
                        totalCours: this.totalCours,
                        totalCostume: this.totalCostume,
                        isPayed: paye,
                        total: '',
                        commentairePartage: '',
                    })
                }
            },
            dateFormater() {
                let currentDate = new Date();
                let day = currentDate.getDate();
                let month = currentDate.getMonth() + 1;
                let year = currentDate.getFullYear();
                return "" + day + "/" + month + "/" + year
            },
            download(prenom, nom, cours, prix, saison) {
                let img2 = new Image();
                let img1 = new Image();
                img2.src = this.img2;
                img1.src = this.img1;
                let pdfName = 'Attestation Kdanse';
                let unit = 8;
                let date = this.dateFormater();
                let doc = new jsPDF();
                doc.setFont("helvetica");
                doc.setFontSize(13);
                let compt = unit;
                doc.text("K'DANSE", 10, compt);
                compt += unit;
                doc.text("Mairie de Maule", 10, compt);
                compt += unit;
                doc.text("78580 MAULE", 10, compt);
                compt += 10;
                doc.addImage(img2, 'jpeg', 150, 0, 55, 45);
                doc.text("Siret N° 43966427700019", 10, compt);
                compt += 30;
                doc.setFont("helvetica", "bold");
                doc.text("ATTESTATION DE PAIEMENT", 105, compt, {align: "center"});
                doc.setFont("helvetica", "normal");
                compt += 20;
                doc.text("Entreprise : ...................................", 105, compt, {align: "center"});
                compt += 20;
                doc.text("Nous certifions que l'élève " + prenom + " " + nom, 10, compt);
                compt += unit;
                doc.text("Est inscrit et suit nos cours hebdomadaires de :", 10, compt);
                compt += 14;
                for (let i in cours) {
                    doc.text("    - " + cours[i].nom, 10, compt);
                    compt += unit;
                }
                compt += unit;
                doc.text("Pour la saison : " + saison + ".", 10, compt);
                compt += 12;
                doc.text("La participation s'élève à : " + prix + " €", 10, compt);
                compt += 20;
                doc.text("Fait à Maule le " + date, 10, compt);
                compt += 20;
                doc.text("M.BOUDROT", 10, compt);
                compt += unit;
                doc.text("Présidente", 10, compt);
                doc.addImage(img1, 'png', 10, 230, 40, 30);

                doc.save(pdfName + '.pdf');
            },
          closeRib() {
            this.dialogRib = false;
          },
        },
        name: "listFacture"
    }
</script>

<style scoped>

</style>
