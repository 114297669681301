import ApiService from "@/Services/apiService";

export default class FactureService {

    static async createFacture(body, token) {
        await ApiService.apiCall("post", "facture/createFacture", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateFacture(body, token) {
        return await ApiService.apiCall("put", "facture/updateFacture", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateFactureOther(body, token) {

        return await ApiService.apiCall("put", "facture/update", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllValidateFacture(token) {
        return await ApiService.apiCall("get", "facture/getallvalidate", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllValidateFactureEnriched(token) {
        return await ApiService.apiCall("get", "facture/getallvalidateenriched", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getFactureUtilisateur(id, token) {
        return await ApiService.apiCall("get", "facture/getfactureutilisateur/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllNotValidateFacture(token) {
        return await ApiService.apiCall("get", "facture/getallnotvalidate", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllNotValidateFactureEnriched(token) {
        return await ApiService.apiCall("get", "facture/getallnotvalidateenriched", {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllValidateFactureByIdUser(token) {
        return await ApiService.apiCall("get", "facture/getallnotvalidate", {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async validateFacture(body, token) {
        return await ApiService.apiCall("put", "facture/valid", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async getAllFactureByIdEleve(id, token) {
        return await ApiService.apiCall("get", "facture/getbyeleve/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteFacture(id, token) {
        return await ApiService.apiCall("delete", "facture/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }


}
