import ApiService from "@/Services/apiService";

export default class AuthService {
    static async register(body) {
        return await ApiService.apiCall("post", "auth/register", body);
    }

    static async authentication(body) {
        try {
            let user = await ApiService.apiCall("get", "utilisateur/getid/" + body.email);
            if(user.data.token){
                await this.logout(user.data);
            }
            await ApiService.apiCall("post", "auth/login", body);
            user = await ApiService.apiCall("get", "utilisateur/getid/" + body.email);
            if(!user.data.token){
                user = await ApiService.apiCall("get", "utilisateur/getid/" + body.email);
            }
            localStorage.setItem("token", user.data.token);
            localStorage.setItem("id", user.data.id);
            localStorage.setItem("nom", user.data.nom);
            localStorage.setItem("email", user.data.email);
            localStorage.setItem("role", user.data.role);
            return user.data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    static async checkMdp(body, token) {
        try {
            const user = await ApiService.apiCall("post", "auth/checkmdp", body, {headers: {'Authorization': 'Bearer ' + token}});
            return user.data;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    static async logout(body) {
        await ApiService.apiCall("post", "auth/logout", body, {headers: {'Authorization': 'Bearer ' + body.token}});
        localStorage.clear();
    }
}
