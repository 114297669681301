<template>
    <div>
        <v-data-table
                :headers="headersProf"
                :items="prof"
                class="elevation-3"
                :items-per-page="-1"
                hide-default-footer
        >
            <template v-slot:top>
                <v-alert
                        type="error"
                        :value="alertValue"
                >Le professeur n'a pas pu être modifié.
                </v-alert>
                <v-toolbar
                        flat
                        class="my-10"
                >
                    <v-toolbar-title class="mpink-text text-font-h2">Professeur</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                    <v-dialog
                            v-model="dialogProf"
                            max-width="50%"
                    >
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Informations</span>
                            </v-card-title>
                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedProf.nom"
                                                    label="Nom"
                                                    :rules="nomRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedProf.prenom"
                                                    label="Prénom"
                                                    :rules="prenomRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedProf.telephone"
                                                    label="Téléphone"
                                                    :rules="telephoneRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                        <v-col
                                                cols="12"
                                                sm="6"
                                                md="4"
                                        >
                                            <v-text-field
                                                    v-model="editedProf.email"
                                                    label="Email"
                                                    :rules="emailRules"
                                                    required
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        color="#AD1F61"
                                        text
                                        @click="closeProf"
                                >
                                    Annuler
                                </v-btn>
                                <v-btn
                                        color="#AD1F61"
                                        text
                                        @click="saveProf"
                                >
                                    Sauvegarder
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                        @click="editProf(item)"
                        color="#AD1F61"
                        text
                >
                    Modifier
                </v-btn>
            </template>
            <template v-slot:no-data>

            </template>
        </v-data-table>
    </div>
</template>

<script>
    import CoursService from "../../../../Services/coursService";

    export default {
        data: () => ({
            currentUser: {},
            prof: [],
            alertValue: false,
            dialogProf: false,
            headersProf: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: 'Téléphone', value: 'telephone', class: "text-font-h2 mpink-text"},
                {text: 'Email', value: 'email', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexProf: -1,
            editedProf: {
                nom: '',
                prenom: '',
                telephone: '',
                email: '',
            },
            defaultItemProf: {
                nom: '',
                prenom: '',
                telephone: '',
                email: '',
            },
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
            telephoneRules: [
                v => !!v || 'Le téléphone ne doit pas être vide',
            ],
            emailRules: [
                v => !!v || 'L\'e-mail ne doit pas être vide',
                v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getProfesseurCours();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getProfesseurCours() {
                const tmp = await CoursService.getProfesseurCours(this.$route.params.id, localStorage.getItem("token"));
                this.prof = [tmp.data];
            },
            editProf(item) {
                this.editedIndexProf = this.prof.indexOf(item);
                this.editedProf = Object.assign({}, item);
                this.dialogProf = true;
            },
            closeProf() {
                this.dialogProf = false;
                this.$nextTick(() => {
                    this.editedProf = Object.assign({}, this.defaultItemProf);
                    this.editedIndexProf = -1;
                })
            },
            async saveProf() {
                if (this.editedIndexProf > -1) {
                    try {
                        if (this.editedProf.nom != '' && this.editedProf.prenom != ''
                            && this.editedProf.telephone != '' && this.editedProf.email != '') {

                            let body = {
                                id: this.editedProf.id,
                                updates: {
                                    nom: this.editedProf.nom,
                                    prenom: this.editedProf.prenom,
                                    telephone: this.editedProf.telephone,
                                    email: this.editedProf.email,
                                }
                            };
                            await CoursService.updateProf(body, localStorage.getItem("token"));
                            Object.assign(this.prof[this.editedIndexProf], this.editedProf);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeProf()
            },
        },
        name: "infoCoursProf"
    }
</script>

<style scoped>

</style>
