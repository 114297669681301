<template>
    <div v-if="eleveAttente.length > 0">
        <v-data-table
                :headers="headersCoursAttente"
                :items="eleveAttente"
                class="elevation-3"
                :items-per-page="-1"
                hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                        class="my-10"
                >
                    <v-toolbar-title class="mpink-text text-font-h2">Cours au(x)quel(s) l'élève est en attente d'inscription</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>

            <template v-slot:item="props">
                <tr>
                    <td>{{ props.item.attenteinscription_cours.nom }}</td>
                    <td>{{ props.item.attenteinscription_cours.jour }}</td>
                    <td>{{ props.item.attenteinscription_cours.horaire }}</td>

                </tr>
            </template>

            <template v-slot:no-data>
                <h3>L'élève n'est en attente d'inscription à aucun(s) cours</h3>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import EleveService from "../../../../Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            eleveAttente: [],
            headersCoursAttente: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Jour', value: 'jour', class: "text-font-h2 mpink-text"},
                {text: 'Horaire', value: 'horaire', class: "text-font-h2 mpink-text"},
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getCoursAttente();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getCoursAttente() {

                const tmp = await EleveService.getEleveAttenteById(this.$route.params.id, localStorage.getItem("token"));
                this.eleveAttente = tmp.data;

            },
        },
        name: "infoEleveCoursAttente"
    }
</script>

<style scoped>

</style>
