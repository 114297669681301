<template>
  <v-container class="contain">
    <v-row>
      <v-col md="8" class="mt-10">
        <v-card
            class="mx-auto"
            elevation="0"
        >
          <v-card-text>
            <h1 class="text-font-h1 text-center">
              Nous contacter
            </h1>
          </v-card-text>
          <v-card-text>
            <h3 class="text-font-h3 text-justify">
              Pour toutes demandes d'informations, vous pouvez nous contacter en remplissant le formulaire
              ci-dessous :
            </h3>
          </v-card-text>
          <v-card-text>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col md="12" class="mb-n5">
                  <v-text-field
                      v-model="name"
                      :rules="nameRules"
                      label="Votre nom"
                      required
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" class="mb-n5">
                  <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      label="Votre e-mail"
                      required
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" class="mb-n5">
                  <v-text-field
                      v-model="telephone"
                      :rules="telephoneRules"
                      label="Votre numéro de téléphone"
                      required
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="mb-n5">
                  <v-textarea
                      v-model="message"
                      :rules="messageRules"
                      label="Votre message"
                      required
                      outlined
                  ></v-textarea>
                  <v-alert
                      type="success"
                      :value="alertValue"
                      class="text-center mx-auto"
                  >Votre message a bien été envoyé. Nous vous remercions et reviendrons vers vous dans les plus brefs délais.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" class="text-center">
                  <v-btn
                      :disabled="!valid"
                      class="Bar-color-invert text-sb rounded-lg"
                      @click="sendMail"
                  >
                    Envoyer
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col md="4" class="mt-10">
        <v-card
            class="mx-auto"
            elevation="0">
          <v-card-text>
            <h1 class="text-font-h1 text-center">
              Contacts
            </h1>
          </v-card-text>
          <v-card-text>
            <h3 class="text-font-h3 text-center">
              Présidente : 06 15 24 82 52
            </h3>
            <h3 class="text-font-h3 text-center">
              Professeur : 06 07 95 81 13
            </h3>
            <h3 class="text-font-h3 text-center">
              Adresse mail : kdanse.maule@gmail.com
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MailService from "@/Services/mailService.";
export default {
  data: () => ({
    alertValue: false,
    valid: true,
    name: '',
    nameRules: [
      v => !!v || 'Votre nom ne doit pas etre vide',
    ],
    email: '',
    emailRules: [
      v => !!v || 'Votre e-mail ne doit pas etre vide',
      v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
    ],
    telephone: '',
    telephoneRules: [
      v => !!v || 'Veuillez saisir le téléphone',
    ],
    message: '',
    messageRules: [
      v => !!v || 'Votre message ne doit pas etre vide',
      v => (v && v.length >= 10) || 'Votre message doit faire plus de 10 caractères',
    ],
  }),

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    async sendMail() {
      try {
        let body = {
          nom: this.name,
          mail: this.email,
          message: this.message,
          telephone: this.telephone
        };
        await MailService.sendEmailContact(body);
        this.alertValue = true;
        this.name = '';
        this.mail = '';
        this.telephone = '';
        this.message = '';
        this.$refs.form.reset()
      } catch (e) {
        console.log(e);
      }
    },
  },
  name: "contact"
}
</script>

<style>
</style>
