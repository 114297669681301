<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <v-data-table
                    :headers="headersEleve"
                    :items="eleve"
                    class="elevation-3"
                    :items-per-page="-1"
                    hide-default-footer
            >
                <template v-slot:top>
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >L'élève n'a pas pu être modifié.
                    </v-alert>
                    <v-toolbar
                            flat
                            class="my-10"
                    >
                        <v-toolbar-title class="mpink-text text-font-h2">Élève : {{titleEleve}}</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialogEleve"
                                max-width="50%"
                        >
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">{{ formTitleEleve }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.nom"
                                                        label="Nom"
                                                        :rules="nomRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.prenom"
                                                        label="Prénom"
                                                        :rules="prenomRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.adresse"
                                                        label="Adresse"
                                                        :rules="adresseRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.ville"
                                                        label="Ville"
                                                        :rules="villeRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.cp"
                                                        label="Code postal"
                                                        :rules="cpRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.telephone"
                                                        label="Téléphone"
                                                        :rules="telephoneRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-menu
                                                        ref="menu"
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                                v-model="editedEleve.dateNaissance"
                                                                label="Date de naissance"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                        ></v-text-field>
                                                    </template>
                                                    <v-date-picker
                                                            v-model="date"
                                                            :first-day-of-week="1"
                                                            locale="fr-FR"
                                                            :active-picker.sync="activePicker"
                                                            :max="new Date().toISOString().substr(0, 10)"
                                                            min="1950-01-01"
                                                            @change="save"
                                                    ></v-date-picker>
                                                </v-menu>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="8"
                                            >
                                                <v-text-field
                                                        v-model="editedEleve.observations"
                                                        label="Observations"
                                                        :rules="observationsRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="12"
                                            >
                                                <v-radio-group
                                                        v-model="editedEleve.droitImage"
                                                        label="Autorisez-vous K'danse à publier sur nos supports médiatiques (Facebook et site internet) les photos et vidéos prises dans le cadre de votre activité (ou celle de votre enfant) ?"
                                                >
                                                    <v-radio
                                                            color="#AD1F61"
                                                            label="Oui"
                                                            value="1"
                                                    ></v-radio>
                                                    <v-radio
                                                            color="#AD1F61"
                                                            label="Non"
                                                            value="0"
                                                    ></v-radio>
                                                </v-radio-group>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="closeEleve"
                                    >
                                        Annuler
                                    </v-btn>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="saveEleve"
                                    >
                                        Sauvegarder
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.dateNaissance="{ item }">
                    {{convertDate(item.dateNaissance)}}
                </template>
                <template v-slot:item.droitImage="{ item }">
                    <td v-if="item.droitImage == 0"> Non</td>
                    <td v-else-if="item.droitImage == 1"> Oui</td>
                    <td v-else> Non renseigné</td>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-btn
                            @click="editEleve(item)"
                            color="#AD1F61"
                            text
                    >
                        Modifier
                    </v-btn>
                </template>
                <template v-slot:no-data>

                </template>
            </v-data-table>
            <v-row class="mt-4"></v-row>
            <v-row>
                <v-col cols="12" v-if="age < 18">
                    <info-eleve-responsable></info-eleve-responsable>
                </v-col>
                <v-col cols="12">
                    <info-eleve-cours-inscrit></info-eleve-cours-inscrit>
                </v-col>
                <v-col cols="6">
                    <info-eleve-urgence></info-eleve-urgence>
                </v-col>
                <v-col cols="6" v-if="age < 18">
                    <info-eleve-autorisee></info-eleve-autorisee>
                </v-col>
                <v-col cols="6">
                    <info-eleve-cours-attente></info-eleve-cours-attente>
                </v-col>
                <v-col cols="6">
                    <info-eleve-cours-liste-attente></info-eleve-cours-liste-attente>
                </v-col>
                <v-col cols="12">
                    <info-eleve-absence></info-eleve-absence>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>

    import EleveService from "../../../../Services/eleveService";
    import InfoEleveResponsable from "./infoEleveResponsable";
    import InfoEleveUrgence from "./infoEleveUrgence";
    import InfoEleveAutorisee from "./infoEleveAutorisee";
    import InfoEleveCoursInscrit from "./infoEleveCoursInscrit";
    import InfoEleveCoursAttente from "./infoEleveCoursAttente";
    import InfoEleveCoursListeAttente from "./infoEleveCoursListeAttente";
    import UtilisateurService from "../../../../Services/utilisateurService";
    import InfoEleveAbsence from "./infoEleveAbsence";

    export default {
        components: {
            InfoEleveAbsence,
            InfoEleveCoursListeAttente,
            InfoEleveCoursInscrit,
            InfoEleveCoursAttente,
            InfoEleveAutorisee,
            InfoEleveUrgence,
            InfoEleveResponsable
        },
        data: () => ({
            age: 0,
            titleEleve: '',
            currentUser: {},
            eleve: [],
            date: null,
            role: localStorage.getItem("role"),
            alertValue: false,
            dialogEleve: false,
            menu: false,
            activePicker: null,
            headersEleve: [
                {
                    text: 'Adresse',
                    align: 'start',
                    value: 'adresse',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Ville', value: 'ville', class: "text-font-h2 mpink-text"},
                {text: 'Code Postal', value: 'cp', class: "text-font-h2 mpink-text"},
                {text: 'Téléphone', value: 'telephone', class: "text-font-h2 mpink-text"},
                {text: 'Date de naissance', value: 'dateNaissance', class: "text-font-h2 mpink-text"},
                {text: 'Email', value: 'email', class: "text-font-h2 mpink-text"},
                {text: 'Droit à l\'image', value: 'droitImage', class: "text-font-h2 mpink-text"},
                {text: 'Observations', value: 'observations', sortable: false, class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexEleve: -1,
            editedEleve: {
                nom: '',
                prenom: '',
                adresse: '',
                ville: '',
                cp: '',
                telephone: '',
                dateNaissance: '',
                droitImage: '',
                observations: '',
            },
            defaultItemEleve: {
                nom: '',
                prenom: '',
                adresse: '',
                ville: '',
                cp: '',
                telephone: '',
                dateNaissance: '',
                droitImage: '',
                observations: '',
            },
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
            adresseRules: [
                v => !!v || 'L\'adresse ne doit pas être vide',
            ],
            villeRules: [
                v => !!v || 'La ville ne doit pas être vide',
            ],
            cpRules: [
                v => !!v || 'Le code postal ne doit pas être vide',
            ],
            telephoneRules: [
                v => !!v || 'Le téléphone ne doit pas être vide',
            ],
            dateNaissanceRules: [
                v => !!v || 'La date de naissance ne doit pas être vide',
            ],
            droitImageRules: [
                v => !!v || 'Le droit à l\'image ne doit pas être vide',
            ],
            observationsRules: [
                v => !!v || 'Les observations ne doivent pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getEleve();
        },
        computed: {
            formTitleEleve() {
                return 'Modifier l\'élève';
            },
        },
        watch: {
            menu(val) {
                val && setTimeout(() => (this.activePicker = 'YEAR'))
            },
            dialogEleve(val) {
                val || this.closeEleve()
            },
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getEleve() {
                try {
                    const tmp = await EleveService.getEleve(this.$route.params.id, localStorage.getItem("token"));

                    this.titleEleve = tmp.data.prenom + ' ' + tmp.data.nom;
                    tmp.data.dateNaissance = this.parseDate(this.convertDate(tmp.data.dateNaissance));
                    const tmp2 = await UtilisateurService.getFamille(tmp.data.utilisateur_id, localStorage.getItem("token"));
                    tmp.data.email = tmp2.data.email;
                    this.getAge(this.parseDate(this.convertDate(tmp.data.dateNaissance)));
                    this.eleve = [tmp.data];

                } catch (e) {
                    console.log(e);
                }
            },
            getAge(date) {
                let d = new Date(date);
                var diff_ms = Date.now() - d.getTime();
                var age_dt = new Date(diff_ms);

                this.age = Math.abs(age_dt.getUTCFullYear() - 1970);
            },
            convertDate(date) {

                let d = new Date(date);
                return d.toLocaleDateString()
            },
            parseDate(date) {

                if (!date) return null;
                const [month, day, year] = date.split('/');
                return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
            },
            editEleve(item) {

                this.editedIndexEleve = this.eleve.indexOf(item);
                this.editedEleve = Object.assign({}, item);
                this.dialogEleve = true;
            },
            closeEleve() {
                this.dialogEleve = false;
                this.$nextTick(() => {
                    this.editedEleve = Object.assign({}, this.defaultItemEleve);
                    this.editedIndexEleve = -1;
                })
            },
            async saveEleve() {
                if (this.editedIndexEleve > -1) {
                    try {
                        if (this.editedEleve.nom.trim() !== '' && this.editedEleve.prenom.trim() !== '' && this.editedEleve.adresse.trim() !== ''
                            && this.editedEleve.ville.trim() !== '' && this.editedEleve.cp.trim() !== '' && this.editedEleve.telephone.trim() !== ''
                            && this.editedEleve.dateNaissance.trim() !== '' && this.editedEleve.droitImage.trim() !== '') {
                            let body = {
                                id: this.editedEleve.id,
                                updates: {
                                    nom: this.editedEleve.nom,
                                    prenom: this.editedEleve.prenom,
                                    adresse: this.editedEleve.adresse,
                                    ville: this.editedEleve.ville,
                                    cp: this.editedEleve.cp,
                                    telephone: this.editedEleve.telephone,
                                    dateNaissance: this.editedEleve.dateNaissance,
                                    droitImage: this.editedEleve.droitImage,
                                    observations: this.editedEleve.observations,
                                }
                            };
                            await EleveService.updateEleve(body, localStorage.getItem("token"));
                            this.titleEleve = this.editedEleve.prenom + " " + this.editedEleve.nom;
                            Object.assign(this.eleve[this.editedIndexEleve], this.editedEleve);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeEleve()
            },
            save(date) {
                this.editedEleve.dateNaissance = date;
            },
        },
        name: "infoEleve"
    }
</script>

<style scoped>

</style>
