<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-img :src="ban"></v-img>
        <v-toolbar class="Bar-color bg2" elevation="1">
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn class="Bar-color bg2 text-sb mr-2" to="/monespace" elevation="0" v-if="role === 'utilisateur'">
                    Accueil
                </v-btn>
                <v-btn class="Bar-color bg2 text-sb mr-2" to="/monespace/indexarticle" elevation="0"
                       v-if="role === 'prof' || role === 'admin' || role === 'super'">
                    Article
                </v-btn>
                <v-btn class="Bar-color bg2 text-sb mr-2" to="/monespace/Admin" elevation="0"
                       v-if="role === 'prof' || role === 'admin' || role === 'super'">
                    Administration
                </v-btn>
                <v-btn class="Bar-color bg2 text-sb mr-2" to="/monespace/dashboard" elevation="0"
                       v-if="role === 'prof' || role === 'admin' || role === 'super'">
                    Statistiques
                </v-btn>
                <v-btn class="Bar-color bg2 text-sb mr-2" to="/monespace/moncompte" elevation="0"
                       v-if="role === 'utilisateur' || role === 'prof' || role === 'admin' || role === 'super'">
                    Mon compte
                </v-btn>
                <v-btn class="Bar-color bg2 text-sb mr-2" @click="logout" elevation="0"
                       v-if="role === 'utilisateur' || role === 'prof' || role === 'admin' || role === 'super'">
                    Déconnexion
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
    </div>
</template>
<script>
    import AuthService from "../../../Services/authService";

    export default {
        data: () => ({
            name: "AdhNavbar",
            ban: require("../../../assets/img/Ban.png"),
            role: localStorage.getItem("role"),
            linkFacture: "/monespace/mesfactures/",
        }),
        beforeMount() {
            this.getCurrentUser();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async logout() {
                try {
                    await AuthService.logout(this.currentUser);
                    localStorage.clear();
                } catch (e) {
                    console.log(e);
                }
                window.open("https://kdanse-maule.fr/", "_self");
            }
        }
    };
</script>
<style>
</style>
