<template>
    <v-container class="contain">
        <v-row justify="space-around">
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text my-5">INSCRIPTION</h1>
                <h1 class="text-font-h1 mpink-text my-5">Choix des cours</h1>
                <v-card
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <h4 class="mt-2 my-4"><span class="italic">Merci de renseigner ci-dessous le(s) cours dans le(s)quel(s) vous souhaitez inscrire l'élève. L'inscription ne sera éffective qu'après validation du professeur.
            <br>Vous recevrez un email de confirmation lorsque l'inscription sera validée.</span></h4>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row
                                    v-for="index in nb" :key="index"
                            >
                                <v-col
                                        class="d-flex"
                                        cols="12"
                                        sm="6"
                                >
                                    <v-select
                                            :items="cours"
                                            :item-text="item =>`${item.nom} ${item.jour} ${item.horaire}`"
                                            v-model="selectedCours[index]"
                                            item-value="id"
                                            label="Cours :"
                                            outlined
                                    ></v-select>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-left">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addPersonne"
                >
                    Ajouter un cours suplémentaire
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="mt-5">
                <h1 class="text-font-h1 mpink-text my-5">Informations sur la pratique de la danse (pour les nouvelles
                    inscriptions uniquement)</h1>
                <v-card
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="formEleve"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="12">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="nbAnnee"
                                            label="Depuis combien d'années pratiquez-vous la danse ?"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col md="12">
                                    <v-textarea
                                            class="mb-n5"
                                            v-model="pratique"
                                            label="Décrivez votre pratique de la danse (style de danse, niveau, fréquence...)"
                                            required
                                            outlined
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10">
                <h1 class="text-font-h1 mpink-text my-5">Informations médicales</h1>
                <v-card
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="formEleve"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="12">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="observations"
                                            label="Informations importantes (Allergies, blessures, asthmes...)"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
          <v-col md="10" class="mt-5">
            <h1 class="pa-5 text-font-h1">Mode de paiement souhaité</h1>
            <v-card
                class="mx-auto rounded-lg mb-5 pt-3"
                elevation="5"
            >
              <v-radio-group
                  v-model="modepaiment"
                  column
              >
                <v-radio
                    class="ml-5 text-font-h3"
                    label="Règlement par chèque"
                    value="Règlement par chèque"
                ></v-radio>
                <v-radio
                    class="ml-5 text-font-h3"
                    label="Règlement par virement"
                    value="Règlement par virement"
                ></v-radio>
              </v-radio-group>
              <v-radio-group
                  v-model="nbpaiement"
                  column
              >
                <v-radio
                    class="ml-5 text-font-h3"
                    label="Paiement en 1 fois"
                    value="Paiement en 1 fois"
                ></v-radio>
                <v-radio
                    class="ml-5 text-font-h3"
                    label="Paiement en 3 fois"
                    value="Paiement en 3 fois"
                ></v-radio>
              </v-radio-group>
            </v-card>
          </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10">
                <h1 class="text-font-h1 mpink-text my-5">Attestation sur l'honneur et règlement intérieur</h1>
                <v-card
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-col md="12">
                        <v-card-text v-if="age >= 18">
                            <p class="text-font-h3">Je, soussigné(e), {{eleve.nom}} {{eleve.prenom}},<br><br>
                                demeurant au {{eleve.adresse}} {{eleve.cp}} {{eleve.ville}},<br><br>
                                certifie, à ce jour, ne pas vouloir fournir de certificat médical de mon médecin
                                traitant et atteste sur l’honneur ne pas
                                avoir de contre-indication et être apte à pratiquer l’activité de la Danse.<br><br>
                                Je m’engage à informer l’association K’DANSE et le professeur de danse Ludivine BROUSSE
                                de tout changement
                                concernant mon état de santé.<br><br>
                                Par la présente, je décharge le professeur de danse Ludivine BROUSSE et les membres du
                                bureau K’DANSE de toute
                                responsabilité en cas d’incident qui résulterait d’une incapacité à pratiquer l’activité
                                indiquée ci-dessus.<br><br>
                                En cas d’urgence, j’autorise l’association à contacter les pompiers ou les services
                                d’urgence pendant les cours.
                            </p>
                        </v-card-text>
                        <v-card-text v-else>
                            <p class="text-font-h3">Je, soussigné(e), {{responsable.nom}} {{responsable.prenom}}
                                <br><br>
                                demeurant au {{responsable.adresse}} {{responsable.cp}} {{responsable.ville}},<br><br>
                                responsable du mineur : {{eleve.nom}} {{eleve.prenom}},<br><br>
                                certifie, à ce jour, ne pas vouloir fournir de certificat médical de son médecin
                                traitant et atteste sur l’honneur que le
                                mineur n’a pas de contre-indication et qu’il est apte à pratiquer l’activité de la
                                Danse.<br><br>
                                Je m’engage à informer l’association K’DANSE et le professeur de danse Ludivine BROUSSE
                                de tout changement
                                concernant mon état de santé.<br>
                                Par la présente, je décharge Le professeur de danse Ludivine BROUSSE et les membres du
                                bureau K’DANSE de toute
                                responsabilité en cas d’incident qui résulterait d’une incapacité à pratiquer l’activité
                                indiquée ci-dessus.<br><br>
                                En cas d’urgence, j’autorise l’association à contacter les pompiers ou les services
                                d’urgence pendant les cours.
                            </p>
                        </v-card-text>
                        <v-card-text>
                            <v-checkbox v-model="certificat" class="my-n5">
                                <template v-slot:label>
                                    <div class="text-font-h3">
                                        Signer l'attestation
                                    </div>
                                </template>
                            </v-checkbox>
                        </v-card-text>
                    </v-col>
                    <v-col md="12" class="my-n5">
                        <v-card-text>
                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-checkbox v-model="reglement" class="my-n5">
                                    <template v-slot:label>
                                        <div class="text-font-h3">
                                            J'ai lu et j'accepte le
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <a
                                                            target="_blank"
                                                            href="https://kdanse-maule.fr/#/reglementinterieur"
                                                            @click.stop
                                                            v-on="on"
                                                    >
                                                        règlement intérieur
                                                    </a>
                                                </template>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                </v-checkbox>
                            </v-form>
                        </v-card-text>
                    </v-col>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-right">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="finalPage"
                >
                    Terminer
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import CoursService from "@/Services/coursService";
    import InscriptionService from "@/Services/inscriptionService";
    import EleveService from "@/Services/eleveService";
    import MailService from "@/Services/mailService.";

    export default {
        data: () => ({
            valid: true,
            nb: 1,
            cours: [],
            selectedCours: [],
            token: [],
            nbAnnee: "",
            pratique: "",
            observations: "",
            reglement: false,
            certificat: false,
            nbpaiement: "non renseigné",
            modepaiment: "non renseigné",
            eleve: [],
            date: null,
            age: 0,
            responsable: []
        }),
        beforeMount() {
            this.token = localStorage.getItem("token");
            this.getCours();
            this.getEleve();
            this.getEleveResponsable();
        },
        methods: {
            async finalPage() {
                let i = 1;
                for (i = 1; i <= this.nb; i++) {
                    try {
                        if (this.selectedCours[i] != null && this.selectedCours[i] !== '') {
                            const body = {
                                nbAnneePratique: this.nbAnnee,
                                pratique: this.pratique,
                                observations: this.nbpaiement + ' / ' + this.modepaiment + ' / ' + this.observations,
                                acceptTerme: this.reglement,
                                certificat: this.certificat,
                                eleve_id: localStorage.getItem("currentEleveId"),
                                cours_id: this.selectedCours[i]
                            };
                            try {
                                let r = await InscriptionService.createAttenteInscription(body, this.token);
                                let eleve = await EleveService.getEleve(r.data.eleve_id, this.token);
                                let body2 = {
                                    nom: eleve.data.nom,
                                    prenom: eleve.data.prenom,
                                    mail: localStorage.getItem("email")
                                };
                                await MailService.sendPreInscription(body2, this.token);
                            } catch (e) {
                                console.log(e);
                            }

                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
                await this.$router.push('/monespace');
            },
            validate() {
                this.$refs.form.validate()
            },
            addPersonne() {
                this.nb += 1;
            },
            async getCours() {
                const c = await CoursService.getAllCours(this.token);
                this.cours = c.data;
            },
            getAge(date) {
                let d = new Date(date);
                let diff_ms = Date.now() - d.getTime();
                let age_dt = new Date(diff_ms);
                this.age = Math.abs(age_dt.getUTCFullYear() - 1970);
            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            parseDate(date) {
                if (!date) return null;
                const [month, day, year] = date.split('/');
                return `${year}-${day.padStart(2, '0')}-${month.padStart(2, '0')}`
            },
            async getEleve() {
                const tmp = await EleveService.getEleve(localStorage.getItem("currentEleveId"), localStorage.getItem("token"));
                this.eleve = tmp.data;
                this.eleve.dateNaissance = this.parseDate(this.convertDate(this.eleve.dateNaissance));
                this.getAge(this.parseDate(this.convertDate(this.eleve.dateNaissance)));
            },
            async getEleveResponsable() {
                const tmp = await EleveService.getEleveResponsable(localStorage.getItem("currentEleveId"), localStorage.getItem("token"));
                this.responsable = tmp.data[0];
            },
        },
        name: "Inscription5"
    }
</script>

<style scoped>

</style>
