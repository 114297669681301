<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1" sm="0" class="my-10 align-self-center"></v-col>
            <v-col md="10" sm="12" class="my-10 align-self-center">
                <h1 class="text-center text-font-h0 mpink-text my-5">ASSEMBLÉS GÉNÉRALE</h1>
                <p class="text-justify text-font-h3">Chaque année, une assemblée générale est organisée avec l’ensemble
                    des adhérents de l’association afin de faire le bilan moral et financier de l’association et
                    d’informer sur les grands évènements qui ponctuent l’année. C’est également l'occasion de se
                    rencontrer et d’échanger sur la vie de l’association.</p>
                <p class="text-justify text-font-h3">Si vous souhaitez consulter les procès verbaux des précédentes
                    assemblées générales, vous pouvez les retrouver ci-dessous :</p>
                <a class="mpink-text" href="./public/CR_AG_21_novembre_2020.pdf" download> Compte-rendu de l'assemblée
                    générale 2020</a><br>
                <a class="mpink-text" href="./public/CR_AG_16_novembre_2019.pdf" download> Compte-rendu de l'assemblée
                    générale 2019</a><br>
                <a class="mpink-text" href="./public/CR_AG_17_novembre_2018.pdf" download> Compte-rendu de l'assemblée
                    générale 2018</a><br><br>
                <p class="text-justify text-font-h3">Si vous souhaitez consulter les statuts de l’association, vous
                    pouvez vous adresser à la préfecture des Yvelines.</p>
            </v-col>
            <v-col md="1" sm="0" class="my-10 align-self-center"></v-col>
        </v-row>
    </v-container>
</template>

<script>

    export default {
        data() {
            return {}
        },
        name: "assembleegenerale.vue"
    }
</script>

<style scoped>

</style>
