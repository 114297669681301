<template>
    <v-data-table
            :headers="headersResponsable"
            :items="responsables"
            class="elevation-3"
            :items-per-page="-1"
            hide-default-footer
    >
        <template v-slot:top>
            <v-alert
                    type="error"
                    :value="alertValue"
            >La personne n'a pas pu être ajoutée ou modifiée.
            </v-alert>
            <v-toolbar
                    flat
                    class="my-10"
            >
                <v-toolbar-title class="mpink-text text-font-h2">Personne(s) responsable(s)</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                        v-model="dialogResponsable"
                        max-width="50%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="#AD1F61"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                            Ajouter
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitleResponsable }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.lien"
                                                label="Lien"
                                                :rules="lienRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.nom"
                                                label="Nom"
                                                :rules="nomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.prenom"
                                                label="Prénom"
                                                :rules="prenomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.adresse"
                                                label="Adresse"
                                                :rules="adresseRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.ville"
                                                label="Ville"
                                                :rules="villeRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.cp"
                                                label="Code postal"
                                                :rules="cpRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.mail"
                                                label="Email"
                                                :rules="emailRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedResponsable.telephone"
                                                label="Téléphone"
                                                :rules="telephoneRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="closeResponsable"
                            >
                                Annuler
                            </v-btn>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="saveResponsable"
                            >
                                Sauvegarder
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogResponsableDelete" max-width="50%">
                    <v-card>
                        <v-card-title class="text-h5">Etes-vous sûr de vouloir supprimer ce responsable ?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#AD1F61" text @click="closeDeleteResponsable">Annuler</v-btn>
                            <v-btn color="#AD1F61" text @click="deleteResponsableConfirm">Supprimer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.nom="{ item }">
            <td class="text-uppercase"> {{item.nom}}</td>
        </template>
        <template v-slot:item.prenom="{ item }">
            <td class="text-uppercase"> {{item.prenom}}</td>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                    @click="editResponsable(item)"
                    color="#AD1F61"
                    text
            >
                Modifier
            </v-btn>
            <v-btn
                    @click="deleteResponsable(item)"
                    color="#AD1F61"
                    text
            >
                Supprimer
            </v-btn>

        </template>
        <template v-slot:no-data>
            <h3>Pas de personnes responsable(s) renseignée(s)</h3>
        </template>
    </v-data-table>
</template>

<script>
    import EleveService from "../../../../Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            role: localStorage.getItem("role"),
            responsables: [],
            eleve: [],
            dialogResponsable: false,
            dialogResponsableDelete: false,
            alertValue: false,
            headersResponsable: [
                {
                    text: 'Lien',
                    align: 'start',
                    value: 'lien',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Nom', value: 'nom', class: "text-font-h2 mpink-text"},
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: 'Adresse', value: 'adresse', class: "text-font-h2 mpink-text"},
                {text: 'Ville', value: 'ville', class: "text-font-h2 mpink-text"},
                {text: 'Code Postal', value: 'cp', class: "text-font-h2 mpink-text"},
                {text: 'Mail', value: 'mail', class: "text-font-h2 mpink-text"},
                {text: 'Telephone', value: 'telephone', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexResponsable: -1,
            editedResponsable: {
                lien: '',
                nom: '',
                prenom: '',
                adresse: '',
                ville: '',
                cp: '',
                mail: '',
                telephone: '',
            },
            defaultItemResponsable: {
                lien: '',
                nom: '',
                prenom: '',
                adresse: '',
                ville: '',
                cp: '',
                mail: '',
                telephone: '',
            },
            lienRules: [
                v => !!v || 'Le lien ne doit pas être vide',
            ],
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
            adresseRules: [
                v => !!v || 'L\'adresse ne doit pas être vide',
            ],
            villeRules: [
                v => !!v || 'La ville ne doit pas être vide',
            ],
            cpRules: [
                v => !!v || 'Le code postal ne doit pas être vide',
            ],
            emailRules: [
                v => !!v || 'L\'e-mail ne doit pas être vide',
                v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
            ],
            telephoneRules: [
                v => !!v || 'Le téléphone ne doit pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getEleveResponsable();
            this.getEleve();
        },
        computed: {
            formTitleResponsable() {
                return this.editedIndexResponsable === -1 ? 'Ajouter un responsable' : 'Modifier le responsable'
            },
        },
        watch: {
            dialogResponsable(val) {
                val || this.closeResponsable()
            },
            dialogResponsableDelete(val) {
                val || this.closeDeleteResponsable()
            },
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getEleve() {

                const tmp = await EleveService.getEleve(this.$route.params.id, localStorage.getItem("token"));
                this.eleve = tmp.data;
            },
            async getEleveResponsable() {

                const tmp = await EleveService.getEleveResponsable(this.$route.params.id, localStorage.getItem("token"));
                this.responsables = tmp.data;
            },
            editResponsable(item) {
                this.editedIndexResponsable = this.responsables.indexOf(item);
                this.editedResponsable = Object.assign({}, item);
                this.dialogResponsable = true;
            },

            deleteResponsable(item) {
                this.editedIndexResponsable = this.responsables.indexOf(item);
                this.editedResponsable = Object.assign({}, item);
                this.dialogResponsableDelete = true;
            },

            async deleteResponsableConfirm() {

                try {
                    await EleveService.deleteResponsable(this.editedResponsable.id, localStorage.getItem("token"));
                    this.responsables.splice(this.editedIndexResponsable, 1);
                } catch (e) {
                    console.log(e);
                }
                this.closeDeleteResponsable();
            },

            closeResponsable() {
                this.dialogResponsable = false;
                this.$nextTick(() => {
                    this.editedResponsable = Object.assign({}, this.defaultItemResponsable);
                    this.editedIndexResponsable = -1;
                })
            },

            closeDeleteResponsable() {
                this.dialogResponsableDelete = false;
                this.$nextTick(() => {
                    this.editedResponsable = Object.assign({}, this.defaultItemResponsable);
                    this.editedIndexResponsable = -1;
                })
            },

            async saveResponsable() {
                if (this.editedIndexResponsable > -1) {
                    try {

                        if (this.editedResponsable.nom.trim() != '' && this.editedResponsable.prenom.trim() != '' && this.editedResponsable.adresse.trim() != ''
                            && this.editedResponsable.ville.trim() != '' && this.editedResponsable.cp.trim() != '' && this.editedResponsable.telephone.trim() != ''
                            && this.editedResponsable.lien.trim() != '' && this.editedResponsable.mail.trim() != '') {
                            let body = {
                                id: this.editedResponsable.id,
                                updates: {
                                    nom: this.editedResponsable.nom,
                                    prenom: this.editedResponsable.prenom,
                                    adresse: this.editedResponsable.adresse,
                                    ville: this.editedResponsable.ville,
                                    cp: this.editedResponsable.cp,
                                    telephone: this.editedResponsable.telephone,
                                    lien: this.editedResponsable.lien,
                                    mail: this.editedResponsable.mail,
                                    eleve_id: this.eleve.id,
                                }
                            };
                            await EleveService.updateResponsable(body, localStorage.getItem("token"));
                            Object.assign(this.responsables[this.editedIndexResponsable], this.editedResponsable);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                } else {
                    try {
                        if (this.editedResponsable.nom.trim() != '' && this.editedResponsable.prenom.trim() != '' && this.editedResponsable.adresse.trim() != ''
                            && this.editedResponsable.ville.trim() != '' && this.editedResponsable.cp.trim() != '' && this.editedResponsable.telephone.trim() != ''
                            && this.editedResponsable.lien.trim() != '' && this.editedResponsable.mail.trim() != '') {

                            let body = {
                                nom: this.editedResponsable.nom,
                                prenom: this.editedResponsable.prenom,
                                adresse: this.editedResponsable.adresse,
                                ville: this.editedResponsable.ville,
                                cp: this.editedResponsable.cp,
                                telephone: this.editedResponsable.telephone,
                                lien: this.editedResponsable.lien,
                                mail: this.editedResponsable.mail,
                                eleve_id: this.eleve.id,
                            };
                            await EleveService.addResponsable(body, localStorage.getItem("token"));
                            this.responsables.push(this.editedResponsable);
                            this.getEleveResponsable();
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeResponsable()
            },
        },
        name: "infoEleveResponsable"
    }
</script>

<style scoped>

</style>
