<template>
    <v-footer
            dark
            padless
            class="bg2 foot"
    >
        <v-card
                flat
                tile
                class="white--text text-center bg2"
                width="100%"
        >
            <v-card-text class="white--text ">
                Ce site vous est proposé par l'association K'danse.
            </v-card-text>
            <v-card-text class="white--text gr pt-2">
              <a href="https://kdanse-maule.fr/#/mentionslegales" class="white--text">Mentions légales</a>
            </v-card-text>
            <v-card-text class="white--text gr pt-2">
                {{ new Date().getFullYear() }} © K'danse Maule
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
    export default {
        name: "AdhFooter",
    }
</script>

<style>
</style>
