<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">LISTE DE TOUS LES COURS</h1>
            <v-row>
                <v-card-text class="mt-4">
                    <v-alert
                            text
                            type="success"
                            v-if="saison == true"
                    >Les inscriptions sont ouvertes
                    </v-alert>
                    <v-alert
                            text
                            type="warning"
                            v-if="saison == false"
                    >Les inscriptions sont fermées
                    </v-alert>
                </v-card-text>
                <v-col>
                    <v-switch
                            v-model="saison"
                            label="Ouverture des inscriptions"
                            color="#AD1F61"
                            @change="saveSaison"
                    ></v-switch>
                </v-col>
            </v-row>
            <v-data-table
                    :headers="headers"
                    :items="allcours"
                    :items-per-page="-1"
                    hide-default-footer
            >
                <template v-slot:top>
                    <v-alert
                            type="error"
                            :value="alertValue"
                    >Le cours n'a pas pu être ajouté.
                    </v-alert>
                    <v-toolbar
                            flat
                            class="my-10"
                    >
                        <v-btn
                                color="#AD1F61"
                                dark
                                class="mb-2"
                                @click="dialogMails = true"
                        >
                            Récupérer les mails utilisateurs
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-dialog
                                v-model="dialogCours"
                                max-width="50%"
                        >
                            <template v-slot:activator="{ on, attrs }">

                                <v-btn
                                        color="#AD1F61"
                                        dark
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                >
                                    Ajouter
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <span class="text-h5">Informations</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-row>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.nom"
                                                        label="Nom"
                                                        :rules="nomRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.jour"
                                                        label="Jour"
                                                        :rules="jourRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.horaire"
                                                        label="Horaire"
                                                        :rules="horaireRules"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.nbplace"
                                                        label="Nombre de places"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif1"
                                                        label="Tarif 1"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif2"
                                                        label="Tarif 2"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarif3"
                                                        label="Tarif 3"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4"
                                            >
                                                <v-text-field
                                                        v-model="editedCours.tarifSpectacle"
                                                        label="Tarif spectacle"
                                                        required
                                                ></v-text-field>
                                            </v-col>
                                            <v-col
                                                    class="d-flex"
                                                    cols="12"
                                                    sm="6"
                                            >
                                                <v-select
                                                        v-model="editedCours.professeur"
                                                        :items="professeurs"
                                                        item-text="nom"
                                                        item-value="id"
                                                        label="Sélectionner le professeur"
                                                        solo
                                                ></v-select>
                                            </v-col>
                                            <v-col
                                                    class="d-flex"
                                                    cols="12"
                                                    sm="6"
                                            >
                                                <v-select
                                                        v-model="editedCours.saison"
                                                        :items="saisons"
                                                        item-text="nom"
                                                        item-value="id"
                                                        label="Sélectionner la saison"
                                                        solo
                                                ></v-select>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="closeCours"
                                    >
                                        Annuler
                                    </v-btn>
                                    <v-btn
                                            color="#AD1F61"
                                            text
                                            @click="saveCours"
                                    >
                                        Sauvegarder
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogCoursDelete" max-width="50%">
                            <v-card>
                                <v-card-title class="text-h5">Etes-vous sûr de vouloir supprimer ce cours ?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="#AD1F61" text @click="closeDeleteCours">Annuler</v-btn>
                                    <v-btn color="#AD1F61" text @click="deleteCoursConfirm">Supprimer</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                                v-model="dialogMails"
                                max-width="50%"
                        >
                            <v-card>
                                <v-card-title class="text-h5">Listes des mails
                                </v-card-title>
                                <v-card-text>
                                    <v-container>
                                        <v-textarea
                                                class="mt-8"
                                                outlined
                                                name="input-7-4"
                                                :value="tousLesMails"
                                        ></v-textarea>
                                    </v-container>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            color="#AD1F61"
                                            dark
                                            @click="dialogMails = false"
                                    >
                                        Fermer
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.nom }}</td>
                        <td>{{ props.item.jour }}</td>
                        <td>{{ props.item.horaire }}</td>
                        <td>{{ props.item.nbInscrits }}</td>
                        <td>{{ props.item.nbPlacesRestantes }}</td>
                        <td>
                            <v-btn
                                    @click="deleteCours(props.item)"
                                    color="#AD1F61"
                                    text
                            >
                                Supprimer
                            </v-btn>
                        </td>
                        <td>
                            <v-btn
                                    :to="link + props.item.id"
                                    color="#AD1F61"
                                    text
                            >
                                Voir plus
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
            <v-btn
                color="black"
                dark
                class="mt-10"
                @click="dialogSaison = true"
            >
              <v-icon
                  left
                  dark
              >
                mdi-alert
              </v-icon>
              Créer nouvelle année
              <v-icon
                  right
                  dark
              >
                mdi-alert
              </v-icon>
            </v-btn>
          <v-dialog
              v-model="dialogSaison"
              max-width="50%"
          >
            <v-card>
              <v-card-title class="text-h5">ATTENTION
              </v-card-title>
              <v-card-text class="text-font-h3">
                  Vous êtes sur le point de créer une nouvelle année. Cela supprimera toutes les inscriptions, les inscriptions en attentes, la liste d'attente et les factures.<br><br>
                  Avant de tout supprimer, pensez à faire une sauvegarde en exportant toutes les données sur excel.<br><br>
                  Êtes-vous sur de vouloir continuer ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="#AD1F61"
                    dark
                    @click="dialogSaison = false"
                >
                  Fermer
                </v-btn>
                <v-btn
                    color="#AD1F61"
                    dark
                    @click="dialogSaison = false; dialogSaison2 = true"
                >
                  Continuer
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="dialogSaison2"
              max-width="50%"
          >
            <v-card>
              <v-card-title class="text-h5">ATTENTION
              </v-card-title>
              <v-card-text class="text-font-h3">
                Cette Action est irréversible, êtes vous sur de vouloir continuer et créer une nouvelle année ?
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="#AD1F61"
                    dark
                    @click="dialogSaison2 = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                    color="#AD1F61"
                    dark
                    @click="newSeason(); dialogSaison2 = false"
                >
                  Confirmer
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>

    import CoursService from "../../../../Services/coursService";
    import UtilisateurService from "../../../../Services/utilisateurService";
    import EleveService from "@/Services/eleveService";
    import SeasonService from "@/Services/seasonService";

    export default {
        data: () => ({
            link: "/monespace/Admin/infocours/",
            role: localStorage.getItem("role"),
            currentUser: {},
            saison: false,
            allcours: [],
            alertValue: false,
            dialogCours: false,
            dialogMails: false,
            dialogCoursDelete: false,
            dialogSaison: false,
            dialogSaison2: false,
            eleves: [],
            tousLesMails: '',
            headers: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Jour', value: 'jour', class: "text-font-h2 mpink-text"},
                {text: 'Horaire', value: 'horaire', class: "text-font-h2 mpink-text"},
                {text: 'Nombre d\'inscrits', value: 'nbInscrits', class: "text-font-h2 mpink-text"},
                {text: 'Nombre de places restantes', value: 'nbPlacesRestantes', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
                {text: '', value: 'actions', sortable: false}
            ],
            professeurs: [],
            saisons: [],
            editedIndexCours: -1,
            editedCours: {
                nom: '',
                jour: '',
                horaire: '',
                nbplace: 10,
                tarif1: 0,
                tarif2: 0,
                tarif3: 0,
                tarifSpectacle: 0,
                professeur: 1,
                saison: 1,
            },
            defaultItemCours: {
                nom: '',
                jour: '',
                horaire: '',
                nbplace: 10,
                tarif1: 0,
                tarif2: 0,
                tarif3: 0,
                tarifSpectacle: 0,
                professeur: 1,
                saison: 1,
            },
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            jourRules: [
                v => !!v || 'Le jour ne doit pas être vide',
            ],
            horaireRules: [
                v => !!v || 'L\'horaire ne doit pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllCours();
            this.getProfesseurs();
            this.getSaisons();
            this.showListMail();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllCours() {

                const tmp = await CoursService.getAllCours(localStorage.getItem("token"));
                for (let i = 0; i < tmp.data.length; i++) {
                    const tmp2 = await this.getEleves(tmp.data[i].id);
                    tmp.data[i].nbInscrits = tmp2;
                    tmp.data[i].nbPlacesRestantes = tmp.data[i].nbplace - tmp2;
                }
                this.allcours = tmp.data;
            },
            async getEleves(id) {
                const tmp = await CoursService.getEleveCours(id, localStorage.getItem("token"));
                return tmp.data.length;
            },
            async getProfesseurs() {
                const tmp = await CoursService.getAllProfesseurs(localStorage.getItem("token"));
                for (let i = 0; i < tmp.data.length; i++) {
                    tmp.data[i].nom = tmp.data[i].nom + ' ' + tmp.data[i].prenom;
                }
                this.professeurs = tmp.data;
            },
            async getSaisons() {
                const tmp = await CoursService.getAllSaisons(localStorage.getItem("token"));
                this.saison = tmp.data[0].ouvert;
                this.saisons = tmp.data;
            },

            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString()
            },
            editCours(item) {
                this.editedIndexCours = this.cours.indexOf(item);
                this.editedCours = Object.assign({}, item);
                this.dialogCours = true;
            },
            closeCours() {
                this.dialogCours = false;
                this.$nextTick(() => {
                    this.editedCours = Object.assign({}, this.defaultItemCours);
                    this.editedIndexCours = -1;
                })
            },

            deleteCours(item) {
                this.editedIndexCours = this.allcours.indexOf(item);
                this.editedCours = Object.assign({}, item);
                this.dialogCoursDelete = true;
            },

            async deleteCoursConfirm() {
                try {
                    await CoursService.deleteCours(this.editedCours.id, localStorage.getItem("token"));
                    this.allcours.splice(this.editedIndexCours, 1);

                } catch (e) {

                    console.log(e);
                }
                this.closeDeleteCours();
            },
            closeDeleteCours() {
                this.dialogCoursDelete = false;
                this.$nextTick(() => {
                    this.editedCours = Object.assign({}, this.defaultItemCours);
                    this.editedIndexCours = -1;
                })
            },
            async saveSaison() {
                try {
                    let body = {
                        id: this.saisons[0].id,
                        updates: {
                            ouvert: this.saison,
                        }
                    };
                    await CoursService.updateSaison(body, localStorage.getItem("token"));
                } catch (e) {
                    console.log(e);
                }
            },
            async saveCours() {

                try {
                    if (this.editedCours.nom.trim() != '' && this.editedCours.jour.trim() != '' && this.editedCours.horaire.trim() != ''
                        && this.editedCours.nbplace != '') {

                        let body = {
                            nom: this.editedCours.nom,
                            jour: this.editedCours.jour,
                            horaire: this.editedCours.horaire,
                            nbplace: this.editedCours.nbplace,
                            tarif1: this.editedCours.tarif1,
                            tarif2: this.editedCours.tarif2,
                            tarif3: this.editedCours.tarif3,
                            tarifSpectacle: this.editedCours.tarifSpectacle,
                            professeur_id: this.editedCours.professeur,
                            saison_id: this.editedCours.saison,
                        };

                        await CoursService.addCours(body, localStorage.getItem("token"));
                        this.allcours.push(this.editedCours);
                        this.alertValue = false;
                    } else {
                        this.alertValue = true;
                    }
                } catch (e) {
                    console.log(e);
                    this.alertValue = true;
                }

                this.closeCours()
            },
            async showListMail() {
              const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
              let tab = [];
              for (let i = 0; i < tmp.data.length; i++) {
                this.eleves.push(tmp.data[i].inscription_eleve);
                const tmp2 = await UtilisateurService.getFamille(tmp.data[i].inscription_eleve.utilisateur_id, localStorage.getItem("token"));
                if (!tab.includes(tmp2.data.email)) {
                  tab.push(tmp2.data.email);
                }
              }
              this.tousLesMails = tab.join(';');
            },
            async newSeason() {
              try{
                await SeasonService.newSeason(localStorage.getItem("token"));
              }catch (e) {
                alert('echec de la création de la nouvelle année', e);
              }

            },
        },
        name: "listCours"
    }
</script>

<style scoped>

</style>
