<template>
    <v-container class="contain">
        <v-row justify="space-around">
            <v-col md="10" class="mt-10">
                <h1 class="text-center text-font-h0 mpink-text my-5">INSCRIPTION</h1>
                <h1 class="text-font-h1 mpink-text">Personne responsable de l'élève</h1>
                <h4 class="pa-5"><span class="italic">À remplir pour les élèves mineurs uniquement.</span></h4>
                <v-card
                        v-for="index in nb" :key="index"
                        class="mx-auto rounded-lg mb-5"
                        elevation="5"
                >
                    <v-card-text>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-row>
                                <v-col md="4">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="nom[index]"
                                            label="Nom de famille"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="4">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="prenom[index]"
                                            label="Prénom"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="4">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="lien[index]"
                                            label="Lien entre la personne et l'élève"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="8">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="email[index]"
                                            label="E-mail"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="4">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="telephone[index]"
                                            label="Téléphone"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="12">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="adresse[index]"
                                            label="Adresse"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="ville[index]"
                                            label="Ville"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="6">
                                    <v-text-field
                                            class="mb-n5"
                                            v-model="cp[index]"
                                            label="Code postal"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-left">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="addPersonne"
                >
                    Ajouter une personne supplémentaire
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col md="10" class="text-right">
                <v-btn
                        class="Bar-color-invert text-sb rounded-lg"
                        @click="createResponsable"
                >
                    Suivant
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            nb: 1,
            valid: true,
            nomRules: [
                v => !!v || 'Le nom ne doit pas etre vide',
            ],
            prenomRules: [
                v => !!v || 'Le prenom ne doit pas etre vide',
            ],
            adresseRules: [
                v => !!v || 'Veuillez saisir l\'adresse',
            ],
            villeRules: [
                v => !!v || 'Veuillez saisir la ville',
            ],
            cpRules: [
                v => !!v || 'Veuillez saisir le code postal',
            ],
            telephoneRules: [
                v => !!v || 'Veuillez saisir le telephone',
            ],
            droitImageRules: [
                v => !!v || 'Veuillez saisir le droit a l\'image',
            ],
            observationsRules: [
                v => !!v || 'Veuillez saisir le droit a l\'image',
            ],
            nom: [],
            prenom: [],
            adresse: [],
            ville: [],
            cp: [],
            telephone: [],
            lien: [],
            email: [],
            token: '',
        }),
        beforeMount() {
            this.token = localStorage.getItem("token");
        },
        methods: {
            async createResponsable() {
                let i = 1;
                if (this.nom.length > 0) {
                    for (i = 1; i <= this.nb; i++) {
                        let body = {
                            nom: this.nom[i],
                            prenom: this.prenom[i],
                            adresse: this.adresse[i],
                            ville: this.ville[i],
                            cp: this.cp[i],
                            telephone: this.telephone[i],
                            lien: this.lien[i],
                            mail: this.email[i],
                            eleve_id: localStorage.getItem("currentEleveId"),
                        };

                        await EleveService.addResponsable(body, this.token);
                    }
                }
                await this.$router.push('/monespace/Inscription3');
            },
            validate() {
                this.$refs.form.validate()
            },
            addPersonne() {
                this.nb += 1;
            }
        },
        name: "Inscription2"
    }
</script>

<style scoped>

</style>
