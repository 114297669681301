<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">RÉCAPITULATIF ADMINISTRATION</h1>
            <v-row class="justify-space-around">
                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-account-group
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Liste des élèves
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Nombre d'élèves : </span> {{counteleve}}
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listeleve"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-calendar-month
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Liste des cours
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Nombre de cours : </span> {{countcours}}
                        </p>
                        <p class="ml-10">
                            <span class="bold"> </span>
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listcours"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="justify-space-around">
                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-account-plus
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Inscriptions à confirmer
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Demandes à traiter : </span> {{nbeleveattente}}
                        </p>
                        <p class="ml-10">
                            <span class="bold"> </span>
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listeleveattente"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-account-clock
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Liste d'attente
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Demandes en attente : </span> {{nbelevelisteattente}}
                        </p>
                        <p class="ml-10">
                            <span class="bold"> </span>
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listelevelisteattente"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="justify-space-around">
                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-file-document-edit-outline
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Factures à valider
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Nombre de factures : </span> {{countWaitingFacture}}
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listattentefactures"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>

                <v-col
                        cols="5"
                >
                    <v-card class="my-5">
                        <v-card-title class="black--text mt-8">
                            <v-avatar size="120">
                                <v-icon
                                        alt="user"
                                        size="100"
                                        color="black"
                                >
                                    mdi-file-document-outline
                                </v-icon>
                            </v-avatar>
                            <p class="ma-lg-auto text-font-h1 mpink-text">
                                Factures validées
                            </p>
                        </v-card-title>
                        <p class="ml-10">
                            <span class="bold">Nombre de factures : </span> {{countFacture}}
                        </p>
                        <p class="ml-10">
                            <span class="bold"> </span>
                        </p>
                        <v-card-actions>
                            <v-btn
                                    to="/monespace/Admin/listfactures"
                                    color="#AD1F61"
                                    text
                                    class="ml-5"
                            >
                                Voir tout
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>

    import EleveService from "../../../Services/eleveService";
    import CoursService from "../../../Services/coursService";
    import FactureService from "@/Services/factureService";

    export default {
        data: () => ({
            currentUser: {},
            counteleve: 0,
            countcours: 0,
            nbeleveattente: 0,
            nbelevelisteattente: 0,
            countFacture: 0,
            countWaitingFacture: 0,
            role: localStorage.getItem("role"),
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllEleve();
            this.getAllCours();
            this.getAllEleveAttente();
            this.getAllEleveListeAttente();
            this.getWaitingFacture();
            this.getFacture();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllEleve() {
                const tmp = await EleveService.getAllEleveInscrit(localStorage.getItem("token"));
                let tab = [];
                for (let i = 0; i < tmp.data.length; i++) {

                    if (!tab.includes(tmp.data[i].eleve_id)) {
                        tab.push(tmp.data[i].eleve_id);
                    }
                }
                this.counteleve = tab.length;
            },
            async getFacture() {
                const tmp = await FactureService.getAllValidateFacture(localStorage.getItem("token"));
                this.countFacture = tmp.data.length;
            },
            async getWaitingFacture() {
                const tmp = await FactureService.getAllNotValidateFacture(localStorage.getItem("token"));
                this.countWaitingFacture = tmp.data.length;
            },
            async getAllCours() {
                const tmp = await CoursService.getAllCours(localStorage.getItem("token"));
                this.countcours = tmp.data.length;
            },
            async getAllEleveAttente() {
                const tmp = await EleveService.getEleveAttente(localStorage.getItem("token"));
                this.nbeleveattente = tmp.data.length;
            },
            async getAllEleveListeAttente() {
                const tmp = await EleveService.getEleveListeAttente(localStorage.getItem("token"));
                this.nbelevelisteattente = tmp.data.length;
            },
        },
        name: "indexAdmin"
    }
</script>

<style scoped>

</style>
