<template>
    <v-container class="contain">
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center">En cours de création</h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="mt-10">
                <h1 class="text-center"></h1>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "Wip"
    }
</script>

<style scoped>

</style>
