<template>
    <v-data-table
            :headers="headersUrgence"
            :items="urgences"
            class="elevation-3"
            :items-per-page="-1"
            hide-default-footer
    >
        <template v-slot:top>
            <v-alert
                    type="error"
                    :value="alertValue"
            >La personne n'a pas pu être ajoutée ou modifiée.
            </v-alert>
            <v-toolbar
                    flat
                    class="my-10"
            >
                <v-toolbar-title class="mpink-text text-font-h2">Personne(s) à contacter en cas d'urgence</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                        v-model="dialogUrgence"
                        max-width="50%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="#AD1F61"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                            Ajouter
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitleUrgence }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedUrgence.lien"
                                                label="Lien"
                                                :rules="lienRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedUrgence.nom"
                                                label="Nom"
                                                :rules="nomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedUrgence.prenom"
                                                label="Prénom"
                                                :rules="prenomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>

                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedUrgence.telephone"
                                                label="Téléphone"
                                                :rules="telephoneRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="closeUrgence"
                            >
                                Annuler
                            </v-btn>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="saveUrgence"
                            >
                                Sauvegarder
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogUrgenceDelete" max-width="50%">
                    <v-card>
                        <v-card-title class="text-h5">Etes-vous sûr de vouloir supprimer cette urgence ?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#AD1F61" text @click="closeDeleteUrgence">Annuler</v-btn>
                            <v-btn color="#AD1F61" text @click="deleteUrgenceConfirm">Supprimer</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                    @click="editUrgence(item)"
                    color="#AD1F61"
                    text
            >
                Modifier
            </v-btn>
            <v-btn
                    @click="deleteUrgence(item)"
                    color="#AD1F61"
                    text
                    v-if="urgences.length > 1"
            >
                Supprimer
            </v-btn>

        </template>
        <template v-slot:no-data>
            <h3>Pas de personne(s) renseignée(s)</h3>
        </template>
    </v-data-table>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            eleve: [],
            role: localStorage.getItem("role"),
            urgences: [],
            dialogUrgence: false,
            dialogUrgenceDelete: false,
            alertValue: false,
            headersUrgence: [
                {
                    text: 'Lien',
                    align: 'start',
                    value: 'lien',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Nom', value: 'nom', class: "text-font-h2 mpink-text"},
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: 'Telephone', value: 'telephone', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexUrgence: -1,
            editedUrgence: {
                lien: '',
                nom: '',
                prenom: '',
                telephone: '',
            },
            defaultItemUrgence: {
                lien: '',
                nom: '',
                prenom: '',
                telephone: '',
            },
            lienRules: [
                v => !!v || 'Le lien ne doit pas être vide',
            ],
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
            telephoneRules: [
                v => !!v || 'Le téléphone ne doit pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getEleve();
            this.getEleveUrgence();
        },
        computed: {
            formTitleUrgence() {
                return this.editedIndexUrgence === -1 ? 'Ajouter une urgence' : 'Modifier l\'urgence'
            },
        },
        watch: {
            dialogUrgence(val) {
                val || this.closeUrgence()
            },
            dialogUrgenceDelete(val) {
                val || this.closeDeleteUrgence()
            },
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getEleve() {

                const tmp = await EleveService.getEleve(this.$route.params.id, localStorage.getItem("token"));
                this.eleve = tmp.data;
            },
            async getEleveUrgence() {

                const tmp = await EleveService.getEleveUrgence(this.$route.params.id, localStorage.getItem("token"));
                this.urgences = tmp.data;
            },
            editUrgence(item) {
                this.editedIndexUrgence = this.urgences.indexOf(item);
                this.editedUrgence = Object.assign({}, item);
                this.dialogUrgence = true;
            },

            deleteUrgence(item) {
                this.editedIndexUrgence = this.urgences.indexOf(item);
                this.editedUrgence = Object.assign({}, item);
                this.dialogUrgenceDelete = true;
            },

            async deleteUrgenceConfirm() {

                try {
                    await EleveService.deleteUrgence(this.editedUrgence.id, localStorage.getItem("token"));
                    this.urgences.splice(this.editedIndexUrgence, 1);
                } catch (e) {
                    console.log(e);
                }
                this.closeDeleteUrgence();
            },

            closeUrgence() {
                this.dialogUrgence = false;
                this.$nextTick(() => {
                    this.editedUrgence = Object.assign({}, this.defaultItemUrgence);
                    this.editedIndexUrgence = -1;
                })
            },

            closeDeleteUrgence() {
                this.dialogUrgenceDelete = false;
                this.$nextTick(() => {
                    this.editedUrgence = Object.assign({}, this.defaultItemUrgence);
                    this.editedIndexUrgence = -1;
                })
            },

            async saveUrgence() {
                if (this.editedIndexUrgence > -1) {

                    try {
                        if (this.editedUrgence.id != '' && this.editedUrgence.nom != '' && this.editedUrgence.prenom != ''
                            && this.editedUrgence.telephone != '' && this.editedUrgence.lien != '') {
                            let body = {
                                id: this.editedUrgence.id,
                                updates: {
                                    nom: this.editedUrgence.nom,
                                    prenom: this.editedUrgence.prenom,
                                    telephone: this.editedUrgence.telephone,
                                    lien: this.editedUrgence.lien,
                                    eleve_id: this.eleve.id,
                                }
                            };
                            await EleveService.updateUrgence(body, localStorage.getItem("token"));
                            Object.assign(this.urgences[this.editedIndexUrgence], this.editedUrgence);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                } else {
                    try {
                        if (this.editedUrgence.id != '' && this.editedUrgence.nom != '' && this.editedUrgence.prenom != ''
                            && this.editedUrgence.telephone != '' && this.editedUrgence.lien != '') {
                            let body = {
                                nom: this.editedUrgence.nom,
                                prenom: this.editedUrgence.prenom,
                                telephone: this.editedUrgence.telephone,
                                lien: this.editedUrgence.lien,
                                eleve_id: this.eleve.id,
                            };
                            await EleveService.addUrgence(body, localStorage.getItem("token"));
                            this.urgences.push(this.editedUrgence);
                            this.getEleveUrgence();
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeUrgence()
            },
        },
        name: "infoEleveAdhUrgence"
    }
</script>

<style scoped>

</style>
