<template>
    <v-app>
        <v-container class="contain"
                     v-if="role === 'utilisateur' || role === 'prof' || role === 'admin' || role === 'super'">
            <v-row>
                <v-card-text class="mt-8" v-if="disabledModifMdp || disabledModifInfo">
                    <h1 class="text-center text-font-h0 mpink-text my-5">INFORMATIONS DE MON COMPTE</h1>
                </v-card-text>
                <v-col md="6" class="mt-2" v-if="disabledModifInfo">
                    <v-card-text>
                        <h1 class="text-center">Modifier mon compte</h1>
                    </v-card-text>
                    <v-card
                            class="mx-auto rounded-lg"
                            elevation="5"
                    >
                        <v-alert
                                type="error"
                                :value="alertValueInfo"
                        >Le mot de passe et la confirmation du mot de passe ne correspondent pas.
                        </v-alert>
                        <v-alert
                                type="error"
                                :value="alertValueInfo3"
                        >Il existe déjà un compte utilisateur avec cette adresse mail.
                        </v-alert>
                        <v-alert
                                type="error"
                                :value="alertValueInfo4"
                        >Mot de passe incorrect.
                        </v-alert>
                        <v-alert
                                :value="alertValueInfo2"
                                dense
                                text
                                type="success"
                        >
                            <v-row align="center">
                                <v-col class="grow">
                                    Votre compte a bien été modifié.
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-card-text class="text-center">
                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formInfo.nom"
                                            :rules="nomRules"
                                            label="Nom de famille"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formInfo.email"
                                            :rules="emailRules"
                                            label="E-mail"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>

                                <p class="font-italic">
                                    Veuillez renseigner votre mot de passe actuel pour valider les modifications.
                                </p>
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formInfo.mdp"
                                            :rules="mdpRules"
                                            :type="'password'"
                                            label="Mot de passe"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-row class="text-center" md="12">
                                    <v-col cols="1"></v-col>
                                    <v-col cols="4">
                                        <v-btn
                                                :disabled="!valid"
                                                class="Bar-color-invert text-sb rounded-lg"
                                                @click="updateAccount"
                                        >
                                            Valider les modifications
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="4">
                                        <v-btn
                                                class="Bar-color-invert text-sb rounded-lg"
                                                @click="annulerModifs"
                                        >
                                            Fermer
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1"></v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="6" class="mt-2" v-if="disabledModifInfo && !disabledModifMdp"></v-col>

                <v-col md="6" class="mt-2" v-if="disabledModifMdp && !disabledModifInfo"></v-col>
                <v-col md="6" class="mt-2" v-if="disabledModifMdp">
                    <v-card-text>
                        <h1 class="text-center">Modifier mon mot de passe</h1>
                    </v-card-text>
                    <v-card
                            class="mx-auto rounded-lg"
                            elevation="5"
                    >
                        <v-alert
                                type="error"
                                :value="alertValueMdp"
                        >Le nouveau mot de passe et la confirmation du mot de passe ne correspondent pas.
                        </v-alert>
                        <v-alert
                                type="error"
                                :value="alertValueMdp3"
                        >Mot de passe incorrect.
                        </v-alert>
                        <v-alert
                                :value="alertValueMdp2"
                                dense
                                text
                                type="success"
                        >
                            <v-row align="center">
                                <v-col class="grow">
                                    Votre mot de passe a bien été modifié.
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-card-text class="text-center">
                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <p class="font-italic">
                                    Veuillez renseigner votre mot de passe actuel.
                                </p>
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formMdp.mdp"
                                            :rules="mdpRules"
                                            label="Ancien mot de passe"
                                            :type="'password'"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <p class="font-italic">
                                    Veuillez renseigner votre nouveau mot de passe.
                                </p>
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formMdp.newMdp"
                                            :rules="newMdpRules"
                                            :type="'password'"
                                            label="Nouveau mot de passe"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <p class="font-italic">
                                    Veuillez confirmer votre nouveau mot de passe.
                                </p>
                                <v-col md="12">
                                    <v-text-field
                                            v-model="formMdp.mdpConf"
                                            :rules="mdpConfRules"
                                            :type="'password'"
                                            label="Confirmer nouveau mot de passe"
                                            required
                                            outlined
                                    ></v-text-field>
                                </v-col>
                                <v-row class="text-center" md="12">
                                    <v-col cols="1"></v-col>
                                    <v-col cols="4">
                                        <v-btn
                                                :disabled="!valid"
                                                class="Bar-color-invert text-sb rounded-lg"
                                                @click="updateMdp"
                                        >
                                            Valider les modifications
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="2"></v-col>
                                    <v-col cols="4">
                                        <v-btn
                                                class="Bar-color-invert text-sb rounded-lg"
                                                @click="annulerModifsMdp"
                                        >
                                            Fermer
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="1"></v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-card-text class="mt-8" v-if="!disabledModifMdp && !disabledModifInfo">
                    <h1 class="text-center text-font-h0 mpink-text my-5">INFORMATIONS DE MON COMPTE</h1>
                </v-card-text>
                <v-col md="6" class="mt-6">
                    <v-card
                            class="mx-auto rounded-lg"
                            elevation="5"
                    >
                        <v-card-text class="text-left">

                            <v-row class="mt-6">
                                <v-col cols="2"></v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-bold">Nom de Famille : </h3>
                                </v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-regular">{{currentUser.nom}}</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col cols="4">
                                    <h3 class="bold font-weight-bold">Email : </h3>
                                </v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-regular">{{currentUser.email}}</h3>
                                </v-col>
                            </v-row>

                            <v-col md="12" class="text-center mt-6">
                                <v-btn
                                        :disabled="disabledModifInfo"
                                        class="Bar-color-invert text-sb rounded-lg"
                                        @click="disabledModifInfo = true"
                                >
                                    Modifier mes informations
                                </v-btn>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col md="6" class="mt-6">
                    <v-card
                            class="mx-auto rounded-lg"
                            elevation="5"
                    >
                        <v-card-text class="text-left">

                            <v-row class="mt-6">
                                <v-col cols="2"></v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-bold">Mot de passe : </h3>
                                </v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-regular"> **********</h3>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col cols="4">
                                    <h3 class="bold font-weight-bold"></h3>
                                </v-col>
                                <v-col cols="4">
                                    <h3 class="font-weight-regular"></h3>
                                </v-col>
                            </v-row>

                            <v-col md="12" class="text-center mt-11">
                                <v-btn
                                        :disabled="disabledModifMdp"
                                        class="Bar-color-invert text-sb rounded-lg"
                                        @click="disabledModifMdp = true"
                                >
                                    Modifier mon mot de passe
                                </v-btn>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import UtilisateurService from "../../../Services/utilisateurService";
    import AuthService from "../../../Services/authService";

    export default {
        data: () => ({
            disabledModifInfo: false,
            disabledModifMdp: false,
            currentUser: [],
            role: localStorage.getItem("role"),
            valid: true,
            nomRules: [
                v => !!v || 'Votre nom ne doit pas etre vide',
            ],
            emailRules: [
                v => !!v || 'Votre e-mail ne doit pas etre vide',
                v => /.+@.+\..+/.test(v) || 'L \'e-mail n\'est pas valide',
            ],
            newMdpRules: [
                v => !!v || 'Veuillez saisir votre mot de passe',
            ],
            mdpRules: [
                v => !!v || 'Veuillez saisir votre mot de passe',
            ],
            mdpConfRules: [
                v => !!v || 'Veuillez confirmer votre mot de passe',
            ],
            formInfo: {
                nom: localStorage.getItem("nom"),
                email: localStorage.getItem("email"),
                mpd: '',
            },
            formMdp: {
                mdp: '',
                newMdp: '',
                mdpConf: '',
            },
            alertValueInfo: false,
            alertValueInfo2: false,
            alertValueInfo3: false,
            alertValueInfo4: false,
            alertValueMdp: false,
            alertValueMdp2: false,
            alertValueMdp3: false,
        }),
        beforeMount() {
            this.getCurrentUser();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async updateAccount() {
                this.alertValueInfo = this.alertValueInfo2 = this.alertValueInfo3 = this.alertValueInfo4 = false;

                const bodyCheck = {
                    email: localStorage.getItem("email"),
                    mdp: this.formInfo.mdp,
                };
                const check = await AuthService.checkMdp(bodyCheck, localStorage.getItem("token"));

                if (check !== null && check !== false) {

                    const body = {
                        id: localStorage.getItem("id"),
                        updates: {
                            nom: this.formInfo.nom,
                            email: this.formInfo.email,
                        }
                    };
                    try {
                        await UtilisateurService.updateUtilisateur(body, localStorage.getItem("token"));
                        this.alertValueInfo2 = true;

                    } catch (e) {
                        this.alertValueInfo3 = true;
                    }
                    const body2 = {
                        email: this.formInfo.email,
                        mdp: this.formInfo.mdp
                    };
                    try {
                        await AuthService.authentication(body2);
                        this.getCurrentUser();
                    } catch (e) {
                        this.alertValueInfo3 = true;
                    }

                } else {
                    this.alertValueInfo4 = true;
                }
            },
            async updateMdp() {
                this.alertValueMdp = this.alertValueMdp2 = this.alertValueMdp3 = false;
                const bodyCheck = {
                    email: localStorage.getItem("email"),
                    mdp: this.formMdp.mdp,
                };
                const check = await AuthService.checkMdp(bodyCheck, localStorage.getItem("token"));
                if (check !== null && check !== false) {
                    if (this.formMdp.newMdp == this.formMdp.mdpConf) {
                        const body = {
                            id: localStorage.getItem("id"),
                            updates: {
                                mdp: this.formMdp.newMdp,
                                mdpConf: this.formMdp.mdpConf,
                            }
                        };
                        try {
                            await UtilisateurService.updateUtilisateur(body, localStorage.getItem("token"));
                            this.alertValueMdp2 = true;
                        } catch (e) {
                            this.alertValueMdp3 = true;
                        }
                        const body2 = {
                            email: localStorage.getItem("email"),
                            mdp: this.formMdp.newMdp
                        };
                        await AuthService.authentication(body2);
                        this.getCurrentUser();
                    } else {
                        this.alertValueMdp = true;
                    }
                } else {
                    this.alertValueMdp3 = true;
                }
            },

            annulerModifs() {
                this.formInfo.nom = localStorage.getItem("nom");
                this.formInfo.email = localStorage.getItem("email");
                this.formInfo.mdp = '';
                this.alertValueInfo = this.alertValueInfo2 = this.alertValueInfo3 = this.alertValueInfo4 = false;
                this.disabledModifInfo = false;
            },
            annulerModifsMdp() {
                this.formMdp.mdp = '';
                this.formMdp.newMdp = '';
                this.formMdp.mdpConf = '';
                this.alertValueMdp = this.alertValueMdp2 = this.alertValueMdp3 = false;
                this.disabledModifMdp = false;
            }
        },
        name: "infoCompte"
    }
</script>

<style scoped>

</style>
