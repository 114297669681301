<template>
    <div v-if="eleveInscrit.length > 0">
        <v-data-table
                :headers="headersCours"
                :items="eleveInscrit"
                class="elevation-3"
                :items-per-page="-1"
                hide-default-footer
        >
            <template v-slot:top>
                <v-toolbar
                        flat
                        class="my-10"
                >
                    <v-toolbar-title class="mpink-text text-font-h2">Cours au(x)quel(s) l'élève est inscrit</v-toolbar-title>
                    <v-divider
                            class="mx-4"
                            inset
                            vertical
                    ></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </template>

            <template v-slot:item="props">
                <tr>
                    <td>{{ props.item.inscription_cours.nom }}</td>
                    <td>{{ props.item.inscription_cours.jour }}</td>
                    <td>{{ props.item.inscription_cours.horaire }}</td>
                    <td>{{ props.item.nbAnneePratique }}</td>
                    <td>{{ props.item.pratique }}</td>
                    <td>{{ props.item.observations }}</td>
                    <td v-if="props.item.acceptTerme == 1"> Oui</td>
                    <td v-else-if="props.item.acceptTerme == 0"> Non</td>
                    <td v-else></td>
                </tr>
            </template>

            <template v-slot:no-data>
                <h3>L'élève n'est inscrit à aucun cours</h3>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            eleveInscrit: [],

            headersCours: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Jour', value: 'jour', class: "text-font-h2 mpink-text"},
                {text: 'Horaire', value: 'horaire', class: "text-font-h2 mpink-text"},
                {text: 'Nombre(s) d\'année(s) de pratique(s)', value: 'nbAnneePratique', class: "text-font-h2 mpink-text"},
                {text: 'Pratique', value: 'pratique', class: "text-font-h2 mpink-text"},
                {text: 'Observations', value: 'observations', class: "text-font-h2 mpink-text"},
                {text: 'Acceptation des termes', value: 'acceptTerme', class: "text-font-h2 mpink-text"},
            ],

        }),
        beforeMount() {
            this.getCurrentUser();
            this.getCoursInscrit();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getCoursInscrit() {

                const tmp = await EleveService.getEleveInscritById(this.$route.params.id, localStorage.getItem("token"));
                this.eleveInscrit = tmp.data;
            },
        },
        name: "infoEleveAdhCoursInscrit"
    }
</script>

<style scoped>

</style>
