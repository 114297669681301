<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">LISTE DE TOUS LES ARTICLES</h1>
            <v-row class="justify-space-around mt-8">
                <v-col cols="8">
                    <v-btn
                            to="/monespace/Article/"
                            color="#AD1F61"
                            dark
                    >
                        Créer un nouvel article
                    </v-btn>
                </v-col>
                <v-col cols="8">
                    <v-data-table
                            :headers="headers"
                            :items="allarticle"
                            hide-default-footer
                            :items-per-page="-1"
                    >
                        <template v-slot:item="props">
                            <tr>
                                <td>{{ props.item.titre }}</td>
                                <td>{{ props.item.sousTitre }}</td>
                                <td v-if="props.item.enavant == 1"> Oui</td>
                                <td v-else-if="props.item.enavant == 0"> Non</td>
                                <td>
                                    <v-btn
                                            @click="deleteUrgence(props.item.id)"
                                            color="#AD1F61"
                                            text
                                    >
                                        Supprimer
                                    </v-btn>
                                </td>
                                <td>
                                    <v-btn
                                            :to="link + props.item.id"
                                            color="#AD1F61"
                                            text
                                    >
                                        Voir plus
                                    </v-btn>
                                </td>
                            </tr>
                        </template>
                        <template v-slot:top>
                                <v-dialog v-model="dialogArticleDelete" max-width="50%">
                                    <v-card>
                                        <v-card-title class="text-h5">Etes-vous sûr de vouloir supprimer cet article ?
                                        </v-card-title>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="#AD1F61" text @click="closeDeleteArticle">Annuler</v-btn>
                                            <v-btn color="#AD1F61" text @click="deleteArticleConfirm">Supprimer</v-btn>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import ArticleService from "../../../../Services/articleService";

    export default {
        data: () => ({
            currentUser: {},
            allarticle: [],
            role: localStorage.getItem("role"),
            dialogArticleDelete: false,
            idDelete: 0,
            headers: [
                {
                    text: 'Titre',
                    align: 'start',
                    value: 'titre',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Sous Titre', value: 'sousTitre', class: "text-font-h2 mpink-text"},
                {text: 'Publié', value: 'enavant', class: "text-font-h2 mpink-text"},
                {text: '', value: '', sortable: false},
                {text: '', value: '', sortable: false},
            ],
            link: "/monespace/voirarticle/",
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllArticle();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllArticle() {
                const tmp = await ArticleService.getAllArticle(localStorage.getItem("token"));
                this.allarticle = tmp.data;
            },
            closeDeleteArticle() {
                this.dialogArticleDelete = false;
                this.idDelete = 0;
            },
            async deleteArticleConfirm() {

                try {
                    await ArticleService.deleteArticle(this.idDelete, localStorage.getItem("token"));

                } catch (e) {
                    console.log(e);
                }
                this.getAllArticle();
                this.idDelete = 0;
                this.closeDeleteArticle();
            },
            deleteUrgence(id) {

                this.idDelete = id;
                this.dialogArticleDelete = true;
            },
        },
        name: "indexArticle"
    }
</script>

<style scoped>

</style>
