<template>
    <v-container class="contain">
        <v-row>
            <v-col md="1"></v-col>
            <v-col md="10" class="my-10">
                <h1 class="text-center text-font-h0 mpink-text mb-5">HORAIRES DES COURS {{saison}}</h1>
                <h3 class="text-center text-font-h1">Cours à la salle de Danse "Gymnase St Vincent" à Maule</h3>
            </v-col>
            <v-col md="1"></v-col>
        </v-row>
        <v-row>
            <v-col md="12" class="d-flex justify-center ml-n6">
                <v-simple-table dense>
                    <template>
                        <thead>
                        <tr>
                            <th class="text-center">
                            </th>
                            <th class="text-center">
                                Lundi
                            </th>
                            <th class="text-center">
                                Mardi
                            </th>
                            <th class="text-center">
                                Mercredi
                            </th>
                            <th class="text-center">
                                Jeudi
                            </th>
                            <th class="text-center">
                                Vendredi
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="text-center" valign="top">14h</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Jazz enfants
                               <br><small>CE2 - CM1</small></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">15h</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Jazz enfants
                              <br><small>CP - CE1</small></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">16h</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Initiation à la danse
                              <br> <small>Moyenne et Grande sections</small></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">17h</td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Initiation à la danse
                              <br> <small>Moyenne et Grande sections</small></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Jazz enfants
                              <br><small>CP - CE1 - CE2</small></td>
                            <td class="text-center bg7 white--text rounded-lg elevation-2" rowspan="2">Classique
                              <br> <small>1e & 2e année / CP -  CE1</small></td>
                            <td class="text-center grey white--text rounded-lg elevation-2" rowspan="2">A définir selon
                              demande
                            </td>
                            <td class="text-center grey white--text rounded-lg elevation-2" rowspan="2">A définir selon
                              demande
                            </td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">18h</td>
                            <td class="text-center bg3 white--text rounded-lg elevation-2" rowspan="3"> Jazz ados
                                intermédiaire 1 & 2 <br> <small>6ème - 5ème</small></td>
                            <td class="text-center bg3 white--text rounded-lg elevation-2" rowspan="3">Jazz ados
                              intermédiaire 3 <br> <small>4ème - 3ème</small></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Jazz enfants
                              <br><small>CM1</small>
                            </td>
                            <td class="text-center bg6 white--text rounded-lg elevation-2" rowspan="2">Classique inter
                              <small><br>CE2 - CM1 - CM2</small></td>
                            <td class="text-center bg4 white--text rounded-lg elevation-2" rowspan="2">Jazz enfants
                              <small><br>CM2 - 6E</small></td>

                        </tr>
                        <tr>
                            <td class="text-center"></td>

                        </tr>
                        <tr>
                            <td class="text-center" valign="top">19h</td>
                            <td class="text-center bg1 white--text rounded-lg elevation-2" rowspan="3">Jazz adultes débutants
                            </td>
                            <td class="text-center bg6 white--text rounded-lg elevation-2" rowspan="3">Classique Inter/Avancé <br>
                              </td>
                          <td class="text-center bg3 white--text rounded-lg elevation-2" rowspan="3">Jazz ados avancés <br>1 & 2
                          </td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center bg3 white--text rounded-lg elevation-2" rowspan="3"> Jazz ados avancé
                                2 <br><small>1ère - terminale</small></td>
                            <td class="text-center bg3 white--text rounded-lg elevation-2" rowspan="3">Jazz ados avancé
                                1 <br> <small>2nde</small></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">20h</td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center bg5 white--text rounded-lg elevation-2" rowspan="3">Classique adultes débutants*
                            </td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">21h</td>
                            <td class="text-center bg1 white--text rounded-lg elevation-2" rowspan="3">Jazz adultes
                                avancé
                            </td>
                            <td class="text-center bg1 white--text rounded-lg elevation-2" rowspan="3">Jazz adultes
                                inter/avancé
                            </td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center" valign="top">22h</td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        <tr>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                            <td class="text-center"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" sm="12" class="d-flex justify-center mt-5">
                <tbody>
                <tr>
                    <th class="bg4 legend rounded elevation-2"></th>
                    <th class="legend"></th>
                    <th class="bg6 legend rounded elevation-2"></th>
                    <th class="legend"></th>
                    <th class="bg7 legend rounded elevation-2"></th>
                    <th class="text-center legend-name"> cours de 1h00</th>
                    <th class="legend"></th>
                    <th class="legend"></th>

                    <th class="bg1 legend rounded elevation-2"></th>
                    <th class="legend"></th>
                    <th class="bg3 legend rounded elevation-2"></th>
                    <th class="legend"></th>
                    <th class="bg5 legend rounded elevation-2"></th>
                    <th class="text-center legend-name"> cours de 1h30</th>
                </tr>
                </tbody>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="12" sm="12" class="justify-center mt-5">
                <p class="text-center text-font-small">*Les élèves du cours " Classique adultes débutants " ne participent pas au
                    spectacle.</p>
                <p class="text-center text-font-small">Les tranches d'âge sont données à titre indicatif, nous tenons également compte du niveau des élèves.</p>
                <p class="text-center text-font-small">Nous pouvons être amenés à modifier ces horaires en fonction des
                    inscriptions.</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import SaisonService from "@/Services/SaisonService";

    export default {
        name: "planning",
        data: () => ({
          saison: '',
        }),
        beforeMount() {
          this.getSaison();
        },
        methods: {
          async getSaison() {
              const saison = await SaisonService.getSaison();
              this.saison = saison.data[0].nom;
          },
       },
    }
</script>

<style scoped>
    td {
        width: 16%;
    }

    .legend {
        width: 30px !important;
        height: 20px !important;
    }

    .legend-name {
        width: 150px !important;
    }

</style>
