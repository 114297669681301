<template>
    <v-app>
        <v-container class="contain" v-if="role === 'prof' || role === 'admin' || role === 'super'">
            <h1 class="text-center text-font-h0 mpink-text my-5">LISTE DE TOUS LES ÉLÈVES</h1>
            <v-card-title>
                <v-text-field
                        v-model="search"
                        label="Recherche"
                        append-icon="mdi-magnify"
                >

                </v-text-field>
            </v-card-title>
            <v-btn
                    color="#AD1F61"
                    text
            >
                <download-excel
                        :data="alleleveforexcel"
                        :name="nameFile + convertDate(Date.now())"
                        worksheet="Liste des élèves"
                >
                    Télécharger la liste des élèves (Excel)

                </download-excel>
            </v-btn>
            <v-btn
                color="#AD1F61"
                text
            >
              <download-excel
                  :data="allcontactforexcel"
                  :name="nameFile + convertDate(Date.now())"
                  worksheet="Liste des contacts"
              >
                Télécharger la liste des élèves + contacts

              </download-excel>
            </v-btn>
            <v-data-table
                    :headers="headers"
                    :items="alleleve"
                    :search="search"
                    hide-default-footer
                    :items-per-page="-1"

            >
                <template v-slot:item="props">
                    <tr v-if="props.item.cours !== ''">
                        <td class="text-uppercase">{{ props.item.nom }}</td>
                        <td class="text-uppercase">{{ props.item.prenom }}</td>
                        <td>{{ convertDate(props.item.dateNaissance) }}</td>
                        <td class="text-uppercase">{{ props.item.ville }}</td>
                        <td>{{ props.item.cours }}</td>
                        <td>
                            <v-btn
                                    :to="link + props.item.id"
                                    color="#AD1F61"
                                    text
                            >
                                Voir plus
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-container>
        <v-container class="contain" v-else>
            <h1>Vous n'avez pas accès a cette page.</h1>
        </v-container>
    </v-app>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            nameFile: 'export_liste_eleve_',
            search: '',
            link: "/monespace/Admin/infoeleve/",
            alleleve: [],
            alleleveforexcel: [],
            allcontactforexcel: [],
            role: localStorage.getItem("role"),
            headers: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: 'Date de naissance', value: 'dateNaissance', class: "text-font-h2 mpink-text"},
                {text: 'Ville', value: 'ville', class: "text-font-h2 mpink-text"},
                {text: 'Cours inscrit', value: 'cours', class: "text-font-h2 mpink-text"},
                {text: ' ', value: '', sortable: false, class: "text-font-h2 mpink-text"}
            ],

        }),
        beforeMount() {
            this.getCurrentUser();
            this.getAllEleve();
            this.getAllEleveForExcel();
            this.getExtendedElevesInfos();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getAllEleve() {
                const tmp = await EleveService.getAllEleve(localStorage.getItem("token"));

                var tmp2;
                var cours = '';
                for (var i = 0; i < tmp.data.length; i++) {
                    try {
                        tmp2 = await EleveService.getEleveInscritById(tmp.data[i].id, localStorage.getItem("token"));
                        cours = tmp2.data[0].inscription_cours.nom + " " + tmp2.data[0].inscription_cours.jour + " " + tmp2.data[0].inscription_cours.horaire;
                    } catch (e) {
                        console.log(e);
                        cours = '';
                    }

                    tmp.data[i].cours = cours;
                    this.alleleve.push(tmp.data[i]);
                }
            },
            async getExtendedElevesInfos(){
              const tmp = await EleveService.getAllEleve(localStorage.getItem("token"));
              var tab = [];
              var tmp2;

              for (var i = 0; i < tmp.data.length; i++) {
                try {
                  var urgences = '';
                  var autorises = '';
                  var cours = '';

                  tmp2 = await EleveService.getEleveInscritById(tmp.data[i].id, localStorage.getItem("token"));
                  let tmpUrgence = await EleveService.getEleveUrgence(tmp.data[i].id, localStorage.getItem("token"));
                  let tmpAutorise = await EleveService.getEleveAutorisee(tmp.data[i].id, localStorage.getItem("token"));

                  for(let c = 0; c < tmp2.data.length; c++){
                    if(c === 0) {
                      cours += tmp2.data[c].inscription_cours.nom + " " + tmp2.data[c].inscription_cours.jour + " " + tmp2.data[c].inscription_cours.horaire;
                    }
                    else{
                      cours += "\n" + tmp2.data[c].inscription_cours.nom + " " + tmp2.data[c].inscription_cours.jour + " " + tmp2.data[c].inscription_cours.horaire;
                    }
                  }
                  for(let u = 0; u < tmpUrgence.data.length; u++){
                    if(u === 0) {
                      urgences += tmpUrgence.data[u].lien + " - " + tmpUrgence.data[u].nom + " " + tmpUrgence.data[u].prenom + " : " + tmpUrgence.data[u].telephone;
                    }
                    else{
                      urgences += "\n" + tmpUrgence.data[u].lien + " : " + tmpUrgence.data[u].nom + " " + tmpUrgence.data[u].prenom + " : " + tmpUrgence.data[u].telephone;
                    }
                  }
                  for(let a = 0; a < tmpAutorise.data.length; a++){
                    if (a === 0){
                      autorises += tmpAutorise.data[a].nom + " " + tmpAutorise.data[a].prenom;
                    }
                    else{
                      autorises += "\n" + tmpAutorise.data[a].nom + " " + tmpAutorise.data[a].prenom;
                    }
                  }
                } catch (e) {
                  console.log(e);
                  cours = '';
                }
                if (cours !== '') {
                  tab = {
                    'Nom': tmp.data[i].nom,
                    'Prénom': tmp.data[i].prenom,
                    'Cours': cours,
                    'Contacts d\'urgence': urgences,
                    'Personnes autorisées à récupérer l\'élève': autorises
                  };
                  this.allcontactforexcel.push(tab);
                }
              }
            },
          async getAllEleveForExcel() {
                const tmp = await EleveService.getAllEleve(localStorage.getItem("token"));
                var tab = [];
                var tmp2;
                var cours = '';
                for (var i = 0; i < tmp.data.length; i++) {
                    try {
                        tmp2 = await EleveService.getEleveInscritById(tmp.data[i].id, localStorage.getItem("token"));
                        cours = tmp2.data[0].inscription_cours.nom + " " + tmp2.data[0].inscription_cours.jour + " " + tmp2.data[0].inscription_cours.horaire;
                    } catch (e) {
                        console.log(e);
                        cours = '';
                    }
                    if (cours !== '') {
                        var drIm = '';
                        if (tmp.data[i].droitImage == 1) {
                            drIm = 'Oui';
                        } else if (tmp.data[i].droitImage == 0) {
                            drIm = 'Non';
                        } else {
                            drIm = '';
                        }
                        tab = {
                            'Nom': tmp.data[i].nom,
                            'Prenom': tmp.data[i].prenom,
                            'Adresse': tmp.data[i].adresse,
                            'Ville': tmp.data[i].ville,
                            'Code Postal': tmp.data[i].cp,
                            'Telephone': '.' + tmp.data[i].telephone,
                            'Date de naissance': this.convertDate(tmp.data[i].dateNaissance),
                            'Droit à l\'image': drIm,
                            'Observations': tmp.data[i].observations,
                            'Cours inscrit': cours,
                        };
                        this.alleleveforexcel.push(tab);
                    }

                }
            },
            convertDate(date) {
                let d = new Date(date);
                return d.toLocaleDateString();
            }
        },
        name: "listEleve"
    }
</script>

<style scoped>

</style>
