<template>
    <v-data-table
            :headers="headersAutorisee"
            :items="autorisee"
            class="elevation-3"
            :items-per-page="-1"
            hide-default-footer
    >
        <template v-slot:top>
            <v-alert
                    type="error"
                    :value="alertValue"
            >La personne n'a pas pu être ajoutée ou modifiée.
            </v-alert>
            <v-toolbar
                    flat
                    class="my-10"
            >
                <v-toolbar-title class="mpink-text text-font-h2">Personne(s) autorisée(s) à venir chercher l'élève</v-toolbar-title>
                <v-divider
                        class="mx-4"
                        inset
                        vertical
                ></v-divider>
                <v-spacer></v-spacer>
                <v-dialog
                        v-model="dialogAutorisee"
                        max-width="50%"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                color="#AD1F61"
                                dark
                                class="mb-2"
                                v-bind="attrs"
                                v-on="on"
                        >
                            Ajouter
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitleAutorisee }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedAutorisee.nom"
                                                label="Nom"
                                                :rules="nomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                            md="4"
                                    >
                                        <v-text-field
                                                v-model="editedAutorisee.prenom"
                                                label="Prénom"
                                                :rules="prenomRules"
                                                required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="closeAutorisee"
                            >
                                Annuler
                            </v-btn>
                            <v-btn
                                    color="#AD1F61"
                                    text
                                    @click="saveAutorisee"
                            >
                                Sauvegarder
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogAutoriseeDelete" max-width="50%">
                    <v-card>
                        <v-card-title class="text-h5">Etes-vous sûr de vouloir supprimer cette personne
                            ?
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="#AD1F61" text @click="closeDeleteAutorisee">Annuler</v-btn>
                            <v-btn color="#AD1F61" text @click="deleteAutoriseeConfirm">Supprimer
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-btn
                    @click="editAutorisee(item)"
                    color="#AD1F61"
                    text
            >
                Modifier
            </v-btn>
            <v-btn
                    @click="deleteAutorisee(item)"
                    color="#AD1F61"
                    text
            >
                Supprimer
            </v-btn>
        </template>
        <template v-slot:no-data>
            <h3>Pas de personne(s) renseignée(s)</h3>
        </template>
    </v-data-table>
</template>

<script>
    import EleveService from "@/Services/eleveService";

    export default {
        data: () => ({
            currentUser: {},
            eleve: [],
            role: localStorage.getItem("role"),
            autorisee: [],
            dialogAutorisee: false,
            dialogAutoriseeDelete: false,
            alertValue: false,
            headersAutorisee: [
                {
                    text: 'Nom',
                    align: 'start',
                    value: 'nom',
                    class: "text-font-h2 mpink-text"
                },
                {text: 'Prénom', value: 'prenom', class: "text-font-h2 mpink-text"},
                {text: '', value: 'actions', sortable: false},
            ],
            editedIndexAutorisee: -1,
            editedAutorisee: {
                nom: '',
                prenom: '',
            },
            defaultItemAutorisee: {
                nom: '',
                prenom: '',
            },
            nomRules: [
                v => !!v || 'Le nom ne doit pas être vide',
            ],
            prenomRules: [
                v => !!v || 'Le prénom ne doit pas être vide',
            ],
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getEleve();
            this.getEleveAutorisee();
        },
        computed: {
            formTitleAutorisee() {
                return this.editedIndexAutorisee === -1 ? 'Ajouter une personne autorisée' : 'Modifier la personne autorisée'
            },
        },
        watch: {

            dialogAutorisee(val) {
                val || this.closeAutorisee()
            },
            dialogAutoriseeDelete(val) {
                val || this.closeDeleteAutorisee()
            },
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getEleve() {

                const tmp = await EleveService.getEleve(this.$route.params.id, localStorage.getItem("token"));
                this.eleve = tmp.data;
            },
            async getEleveAutorisee() {

                const tmp = await EleveService.getEleveAutorisee(this.$route.params.id, localStorage.getItem("token"));
                this.autorisee = tmp.data;
            },

            editAutorisee(item) {
                this.editedIndexAutorisee = this.autorisee.indexOf(item);
                this.editedAutorisee = Object.assign({}, item);
                this.dialogAutorisee = true;
            },

            deleteAutorisee(item) {
                this.editedIndexAutorisee = this.autorisee.indexOf(item);
                this.editedAutorisee = Object.assign({}, item);
                this.dialogAutoriseeDelete = true;
            },

            async deleteAutoriseeConfirm() {

                try {
                    await EleveService.deletePersonneAutorisee(this.editedAutorisee.id, localStorage.getItem("token"));
                    this.autorisee.splice(this.editedIndexAutorisee, 1);
                } catch (e) {
                    console.log(e);
                }
                this.closeDeleteAutorisee();
            },

            closeAutorisee() {
                this.dialogAutorisee = false;
                this.$nextTick(() => {
                    this.editedAutorisee = Object.assign({}, this.defaultItemAutorisee);
                    this.editedIndexAutorisee = -1;
                })
            },

            closeDeleteAutorisee() {
                this.dialogAutoriseeDelete = false;
                this.$nextTick(() => {
                    this.editedAutorisee = Object.assign({}, this.defaultItemAutorisee);
                    this.editedIndexAutorisee = -1;
                })
            },

            async saveAutorisee() {
                if (this.editedIndexAutorisee > -1) {
                    try {
                        if (this.editedAutorisee.nom != '' && this.editedAutorisee.prenom != '') {
                            let body = {
                                id: this.editedAutorisee.id,
                                updates: {
                                    nom: this.editedAutorisee.nom,
                                    prenom: this.editedAutorisee.prenom,
                                    eleve_id: this.eleve.id,
                                }
                            };
                            await EleveService.updatePersonneAutorisee(body, localStorage.getItem("token"));
                            Object.assign(this.autorisee[this.editedIndexAutorisee], this.editedAutorisee);
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                } else {
                    try {
                        if (this.editedAutorisee.nom != '' && this.editedAutorisee.prenom != '') {
                            let body = {
                                nom: this.editedAutorisee.nom,
                                prenom: this.editedAutorisee.prenom,
                                eleve_id: this.eleve.id,
                            };

                            await EleveService.addPersonneAutorisee(body, localStorage.getItem("token"));
                            this.autorisee.push(this.editedAutorisee);
                            this.getEleveAutorisee();
                            this.alertValue = false;
                        } else {
                            this.alertValue = true;
                        }
                    } catch (e) {
                        console.log(e);
                        this.alertValue = true;
                    }
                }
                this.closeAutorisee()
            },
        },
        name: "infoEleveAdhAutorisee"
    }
</script>

<style scoped>

</style>
