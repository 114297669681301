import ApiService from "@/Services/apiService";

export default class InscriptionService {
    static async createAttenteInscription(body, token) {
        return await ApiService.apiCall("post", "attenteinscription/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateAttenteInscription(body, token) {
        return await ApiService.apiCall("put", "attenteinscription/update", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async updateListeAttente(body, token) {
        return await ApiService.apiCall("put", "listeattente/update", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteAttenteInscription(id, token) {
        return await ApiService.apiCall("delete", "attenteinscription/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async deleteListeAttente(id, token) {
        return await ApiService.apiCall("delete", "listeattente/" + id, {}, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async createInscription(body, token) {
        return await ApiService.apiCall("post", "inscription/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }

    static async createListeAttente(body, token) {
        return await ApiService.apiCall("post", "listeattente/", body, {headers: {'Authorization': 'Bearer ' + token}});
    }
}
