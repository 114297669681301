<template>
    <v-container class="contain"
                 v-if="role === 'prof' || role === 'admin' || role === 'super'">
        <v-app>
            <v-card-text class="mt-4">
                <v-alert
                        text
                        type="success"
                        v-if="enavant === true"
                >L'article est publié
                </v-alert>
                <v-alert
                        text
                        type="warning"
                        v-if="enavant === false"
                >L'article n'est pas publié
                </v-alert>
            </v-card-text>

            <v-switch
                    v-model="enavant"
                    label="Publier l'article"
                    color="#AD1F61"
                    @change="saveEnavant"
            ></v-switch>

            <v-btn v-if="!modif"
                   class="Bar-color-invert text-sb rounded-lg mt-8"
                   @click="modif = true"

            >Modifier l'article
            </v-btn>
            <v-btn v-if="modif"
                   class="Bar-color-invert text-sb rounded-lg mt-8"
                   @click="modif = false"
            >Fermer
            </v-btn>
            <v-divider></v-divider>
            <v-row class="mt-4" v-if="modif">
                <v-col>
                    <v-card
                            class="mx-auto rounded-lg mt-5"
                            elevation="0"
                    >
                        <v-alert
                                type="error"
                                :value="alertValue2"
                        > Erreur lors de la sauvegarde, veuillez réessayer.
                        </v-alert>
                        <v-alert
                                type="error"
                                :value="alertValue3"
                        > Veuillez saisir au moins le titre et le contenu.
                        </v-alert>
                        <v-alert
                                :value="alertValue"
                                dense
                                text
                                type="success"
                        >
                            <v-row align="center">
                                <v-col class="grow">
                                    Votre article à bien été sauvegardé.
                                </v-col>
                            </v-row>
                        </v-alert>
                        <v-col
                                class="d-flex"
                                cols="12"
                                sm="6"
                        >
                            <v-select
                                    v-model="selectImage"
                                    :items="images"
                                    item-text="name"
                                    item-value="src"
                                    label="Sélectionner image titre"
                                    solo
                            ></v-select>
                        </v-col>
                        <v-col>
                            <v-card
                                    class="mx-auto"
                                    max-width="400"
                            >
                                <v-img v-if="selectImage != ''"
                                       class="white--text align-end"
                                       height="200px"
                                       :src="selectImage"
                                >
                                </v-img>
                            </v-card>
                        </v-col>
                        <v-form
                                ref="form"
                                v-model="valid"
                                lazy-validation
                        >
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.titre"
                                        label="Titre"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                            <v-col md="12">
                                <v-text-field
                                        v-model="form.sousTitre"
                                        label="Sous-titre"
                                        required
                                        outlined
                                ></v-text-field>
                            </v-col>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row class="mt-4" v-if="modif">
                <v-col>
                    <vue-editor
                            v-model="contenu"
                            :editorToolbar="customToolbar"
                    />
                </v-col>
            </v-row>
            <v-row class="text-center" v-if="modif">
                <v-col md="4"></v-col>
                <v-col md="4">
                    <v-btn
                            class="Bar-color-invert text-sb rounded-lg"
                            @click="updateArticle"
                    >Sauvegarder
                    </v-btn>
                </v-col>
                <v-col md="4"></v-col>
            </v-row>

            <v-row class="mt-10">
                <v-col md="12" v-if="modif">
                    <v-card
                            class="mx-auto rounded-lg mt-5"
                            elevation="1"
                    >
                        <v-card-text>
                            <h1 class="text-center">Aperçu de l'article</h1>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="12">
                    <v-card-text>
                        <h1 class="text-center text-font-h0 mpink-text my-5">{{form.titre}}</h1>
                    </v-card-text>

                    <v-card-text>
                        <h2 class="text-center text-font-h2 mpink-text">{{form.sousTitre}}</h2>
                    </v-card-text>
                    <p></p>
                    <div class="ql-editor" v-html="contenu"></div>
                </v-col>
            </v-row>
        </v-app>

    </v-container>
    <v-container class="contain" v-else>
        <h1>Vous n'avez pas accès a cette page.</h1>
    </v-container>
</template>

<script>
    import ArticleService from "@/Services/articleService";
    import {VueEditor} from "vue2-editor";

    export default {
        components: {VueEditor},

        data: () => ({
            currentUser: [],
            modif: false,
            role: localStorage.getItem("role"),
            valid: true,
            selectImage: '',
            enavant: 0,
            customToolbar: [
                [{'font': []}],
                [{'header': [false, 1, 2, 3, 4, 5, 6,]}],
                [{'size': ['small', false, 'large', 'huge']}],
                ['bold', 'italic', 'underline', 'strike'],
                [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                [{'header': 1}, {'header': 2}],
                ['blockquote', 'code-block'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'list': 'check'}],
                [{'script': 'sub'}, {'script': 'super'}],
                [{'indent': '-1'}, {'indent': '+1'}],
                [{'color': []}, {'background': []}],
                ['link', 'video', 'formula'],
                [{'direction': 'rtl'}],
                ['clean'],
            ],
            images: [
                {
                    name: "Cours",
                    src: require("../../../../assets/img/cours.png")
                },
                {
                    name: "Infos spectacle",
                    src: require("../../../../assets/img/Infos spectacle.png")
                },
                {
                    name: "Infos utiles",
                    src: require("../../../../assets/img/Infos utiles.png")
                },
                {
                    name: "Inscriptions",
                    src: require("../../../../assets/img/Inscriptions.png")
                },
                {
                    name: "Stages",
                    src: require("../../../../assets/img/Stages.png")
                },
                {
                    name: "Vie de l'assos",
                    src: require("../../../../assets/img/Vie de l'asso.png")
                },
            ],
            form: {
                titre: '',
                sousTitre: '',
            },
            contenu: "",
            alertValue: false,
            alertValue2: false,
            alertValue3: false,
        }),
        beforeMount() {
            this.getCurrentUser();
            this.getArticle();
        },
        methods: {
            getCurrentUser() {
                this.currentUser = {
                    id: localStorage.getItem("id"),
                    nom: localStorage.getItem("nom"),
                    role: localStorage.getItem("role"),
                    token: localStorage.getItem("token"),
                    email: localStorage.getItem("email"),
                };
            },
            async getArticle() {
                const tmp = await ArticleService.getArticle(this.$route.params.id, localStorage.getItem("token"));
                this.form.titre = tmp.data.titre;
                this.form.sousTitre = tmp.data.sousTitre;
                this.enavant = tmp.data.enavant;
                this.selectImage = tmp.data.imageTitre;
                this.contenu = tmp.data.contenu;
            },
            async saveEnavant() {

                let body = {
                    id: this.$route.params.id,
                    updates: {
                        enavant: this.enavant,
                    },
                };
                try {
                    await ArticleService.updateArticle(body, localStorage.getItem("token"));

                } catch (e) {
                    console.log(e)
                }

            },
            async updateArticle() {
                this.alertValue = this.alertValue2 = this.alertValue3 = false;
                if (this.form.titre.trim() !== "" && this.contenu.trim() !== "") {

                    let body = {
                        id: this.$route.params.id,
                        updates: {
                            titre: this.form.titre,
                            sousTitre: this.form.sousTitre,
                            contenu: this.contenu,
                            imageTitre: this.selectImage,
                            utilisateur_id: localStorage.getItem("id"),
                        },
                    };
                    try {
                        await ArticleService.updateArticle(body, localStorage.getItem("token"));

                        this.alertValue = true;
                    } catch (e) {
                        this.alertValue2 = true;
                    }
                } else {
                    this.alertValue3 = true;
                }
            },
            validate() {
                this.$refs.form.validate()
            },
        },
        name: "infoArticle"
    }
</script>

<style scoped>

</style>
